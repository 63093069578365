import * as Imm from 'immutable';
import { DataModel, DataModelParams, Entry, EntryWithoutRow } from '../types/gridDataModelTypes';
import {PowergridValueChangedParams} from "./FormGrid";

const DEFAULT_ID_FIELD = 'id';

type Row = Imm.Map<any, any>;

type Rows = Imm.List<Row>;

export class DefaultGridDataModel<Datum extends Imm.Map<any, any>> implements DataModel<Imm.List<Datum>> {

    private opts: DataModelParams<Datum>;
    private data: Imm.List<Datum>;
    private bufferedData: Imm.List<Datum>;
    private bufferedDataLookup: Map<string, Entry<Datum>>;
    private rows: Rows;

    constructor(
            opts: DataModelParams<Datum>,
            data: Imm.List<Datum>|undefined,
            bufferedData: Imm.List<Datum>|undefined) {
        this.opts = opts;
        this.data = data || Imm.List();
        this.bufferedData = bufferedData || this.data;

        const dataLookup = this._computeDataLookup();
        const [lookup, rows] = this._computeBufferedData(dataLookup);
        
        this.bufferedDataLookup = lookup;
        this.rows = rows;
    }

    private _computeDataLookup(): Map<string, Datum> {
        const lookup = new Map<string, Datum>();
        for(let i = 0, n = this.data.size; i < n; ++i) {
            const dataItem = this.data.get(i)!;
            const id = this._getId(dataItem, i);
            lookup.set(id, dataItem);
        }

        return lookup;
    }

    _computeBufferedData(data: Map<string, Datum>): [Map<string, Entry<Datum>>, Rows] {
        const lookup = new Map<string, Entry<Datum>>();
        const rowsArr = [];

        for(let i = 0, n = this.bufferedData.size; i < n; ++i) {
            const buffered = this.bufferedData.get(i)!;

            const id = this._getId(buffered, i);
            const original = data.get(id);

            const row = this._getRow(buffered, { buffered, original, index: i, id });
            const entry: Entry<Datum> = {
                buffered, original, id, index: i, row
            };

            lookup.set(id, entry);
            rowsArr.push(row);
        }

        const rows = Imm.List(rowsArr);
        return [lookup, rows];
    }


    _getId(dataItem: Datum, index: number): string {
        if(this.opts.getId) {
            return '' + this.opts.getId(dataItem, index);
        }

        return '' + index;
    }

    _getRow(dataItem: Datum, entry: EntryWithoutRow<Datum>) {
        if(this.opts.getRow) {
            return this.opts.getRow(dataItem, entry);
        }

        const idField = this.opts.rowIdField || DEFAULT_ID_FIELD;
        return dataItem.set(idField, entry.id);
    }

    _modifyDataItem(columnIdx: number, value: any, dataItem: Datum, entry: Entry<Datum>): Datum|undefined {
        if(this.opts.modifyDataItem) {
            return this.opts.modifyDataItem(columnIdx, value, dataItem, entry);
        }

        const columns = this.getColumns();
        const column = columns.get(columnIdx);
        if(!column) return;

        return dataItem
            .set(column.get('_key'), value) as any;
    }

    getRows() {
        return this.rows;
    }

    getColumns() {
        return this.opts.columns;
    }

    getItemById(id: string) {
        const entry = this.bufferedDataLookup.get(id);
        return entry ? entry.buffered : undefined;
    }

    hasItem(id: string) {
        return this.bufferedDataLookup.has(id);
    }

    getItemByIndex(index: number) {
        return this.bufferedData.get(index);
    }

    modifyFormData(update: PowergridValueChangedParams) {
        const { row, column, value } = update;

        let data = this.bufferedData;
        let dataItem = this.getItemByIndex(row);

        if(dataItem) {
            const id = this._getId(dataItem, row);
            const entry = this.bufferedDataLookup.get(id);

            if(entry) {
                const next = this._modifyDataItem(column, value, dataItem, entry);
                if(next) {
                    data = data.set(row, next);
                }
            }
        }

        return data;
    }

    getClass(record: { id: string }, column: { _key: string, key: number }) {
        if(this.opts.getClass) {
            const id = '' + record.id;
            const entry = this.bufferedDataLookup.get(id);

            if(entry) {
                return this.opts.getClass(column, entry.buffered, entry);
            }
        }
    }

    shouldApplyClasses(id: string, column: { key: number }) {
        if(this.opts.shouldApplyClasses) {
            const entry = this.bufferedDataLookup.get(id);
            return entry ? this.opts.shouldApplyClasses(column, entry.buffered, entry) : false;

        } else {
            return false;
        }
    }
}
