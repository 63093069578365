import reducers from './forecastingReducers';
import sagas from './forecastingSagas';
import component from './overview/ForecastOverview';

export const page = {
    title: 'forecasting.title',
    path: '/forecasting',
    icon: 'chart-line',
    component,
    reducers,
    sagas
}
