import React from 'react';
import PropTypes from 'prop-types';
import { initialize, reduxForm } from 'redux-form';
import FormSelectorContent from './FormSelectorContent';

class FormSelector extends React.Component {

    render() {
        const commands = this.getCommands();
        return React.createElement(this.getContentComponent(), {
            ...this.props,
            commands
        });
    }

    getContentComponent() {
        return this.props.component ? this.props.component : FormSelectorContent;
    }

    getCommands() {
        const commands = this.props.commands
            ? this.props.commands.slice(0) : [];

        if(!this.props.clearDisabled) {
            commands.push({
                label: 'common.selector.actions.clear',
                // using initialize instead of reset until this bug gets fixed https://github.com/erikras/redux-form/issues/2971
                action: () => initialize(this.props.formId, this.props.formModel.values)    
            });
        }

        return commands;
    }
}

FormSelector.propTypes = {
    component: PropTypes.any,
    fields: PropTypes.array,
    formId: PropTypes.string.isRequired,
    formModel: PropTypes.object.isRequired,
    commands: PropTypes.array.isRequired,
    clearDisabled: PropTypes.bool
};

const FormSelectorReduxForm = reduxForm({destroyOnUnmount: false})(FormSelector);

export default function FormSelectorReduxFormWrapper(props) {
    let initialValues = props.formModel.values;
    if (initialValues == null) {
        initialValues = props.formModel.params?.initialValues;
    } else if (props.formModel.params?.initialValues) {
        initialValues = Object.assign({}, initialValues, props.formModel.params);
    }
    const reduxFormConfig = {
        form: props.formId,
        initialValues,
        onSubmit: () => {}
    };

    return React.createElement(FormSelectorReduxForm, {
        ...props,
        ...reduxFormConfig
    });
}

FormSelectorReduxFormWrapper.propTypes = {
    split: PropTypes.bool.isRequired,
    formId: PropTypes.string.isRequired,
    formModel: PropTypes.object.isRequired
};
