import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestIdFetcher } from '@pearlchain/component-lib-common';
import { RequestId } from '../../utils/requests';
import { dataToOptions } from '../../utils/binder/binderHelpers';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { List } from 'immutable';

type Props = {
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps
}

export default function PlanningStatesBinder(props: Props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_PLANNING_STATES}
        render={(opts: unknown) => <SelectBinder multi options={dataToOptions(opts as List<string> | undefined)} { ...props }/>}
        />
}
