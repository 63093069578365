import { cancel, fork, race, take } from 'redux-saga/effects';
import ActionTypes from '../common/commonActions';

export function withFormId(actionType, formId) {
  return (action) => action.type === actionType && action.formId === formId;
}

export function withRequestId(actionType, requestId) {
  return (action) => action.type === actionType && action.requestId === requestId;
}

export function* takeApiResponse(requestId) {
  const x = yield race({
    error: take(withRequestId(ActionTypes.API_ERROR, requestId)),
    result: take(withRequestId(ActionTypes.API_RESULT, requestId))
  });
  
  const data = x.result && x.result.data;
  const error = x.error && x.error.error;
  return [data, error];
}

function* performTakeUntil(startActionType, stopActionType, saga) {
  while (yield take(startActionType)) {
    const watchers = yield fork(saga);
    yield take(stopActionType);
    yield cancel(watchers);
  }
}

export function takeUntil(startActionType, stopActionType, saga) {
  return fork(performTakeUntil, startActionType, stopActionType, saga);
}
