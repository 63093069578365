import i18n from '../../../i18n'
import { List, Map } from 'immutable';
/**
 * A simple function to map api data to the options object
 * that Select expects to receive
 */

interface Option {
    label: string,
    value: string
}

export function dataToOptions(data: List<string> | undefined): Array<Option> {
    return data ? data.map((d) => ({ value: d, label: d })).toArray() : [];
}

export function dataToOptionsWithSorting(data: List<string> | undefined): Array<Option> {
    return data ? data.map((d) => ({ value: d, label: i18n.t(d) })).toArray().sort((a, b) => a.label.localeCompare(b.label)) : [];
}

export function dataToOptionsByKey(data: List<Map<string,any>> | undefined, key: string): Array<Option> {
    return data ? data.map((d) => ({ value: d.get(key), label: i18n.t(d.get(key), { ns: ['prl'] }) })).toArray() : [];
}
