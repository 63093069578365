import { fromJS } from 'immutable';

export const columns = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'no', width: 150, title: 'common.binder.customer.grid.no' },
    { _key: 'name', width: 150, title: 'common.binder.customer.grid.name' },
    { _key: 'vatNo', width: 150, title: 'common.binder.customer.grid.vatNo' },
    { _key: 'address', width: 150, title: 'common.binder.customer.grid.address' },
    { _key: 'line2', width: 150, title: 'common.binder.customer.grid.line2' },
    { _key: 'postalCode', width: 150, title: 'common.binder.customer.grid.postalCode' },
    { _key: 'city', width: 150, title: 'common.binder.customer.grid.city' },
    { _key: 'country', width: 150, title: 'common.binder.customer.grid.country' },
    { _key: 'telephone', width: 150, title: 'common.binder.customer.grid.telephone' },
    { _key: 'language', width: 150, title: 'common.binder.customer.grid.language' }
]);
