import React from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface Props {
    className?: string,
    title: string,
    showing: boolean,
    children: React.ReactNode,
    onClose: () => void,
    t: (s: string) => string
};

interface State {
    transitioning: boolean
}

class ModalComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        // use internal state to track the animation state of the modal.
        this.state = { transitioning: false };
        this.toggle = this.toggle.bind(this);
    }

    render() {
        if(!this.props.showing && !this.state.transitioning)
            return null;

        return <Modal isOpen={this.props.showing}
            size="lg"
            className={this.props.className}
            onClosed={this.toggle}
            onExit={() => this.setState({ transitioning: false })}>
            <ModalHeader toggle={this.toggle}>
                { this.props.t(this.props.title) }
            </ModalHeader>
            <ModalBody>
                { React.Children.only(this.props.children) }
            </ModalBody>
        </Modal>
    }

    toggle() {
        if (this.props.showing) {
            this.setState({ transitioning: true }, () => this.props.onClose());
        }
    }
}

export default withTranslation(['prl'])(ModalComponent);
