import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

interface Props {
    saveAllChanges: () => void,
    resetAllChanges: () => void
}

export default function(props: Props) {
    return <div className="forecast-details-footer">
        <ButtonGroup>
            <Button color="primary" onClick={props.saveAllChanges}>Save Changes</Button>
            <Button onClick={props.resetAllChanges}>Reset All</Button>
        </ButtonGroup>
    </div>
}
