import { Map } from 'immutable';
import ActionTypes from './simulationTemplateActions';
import { Path } from './simulationTemplateSelectors';

export default function(state=Map(), action) {
    switch(action.type) {
        case ActionTypes.RECEIVE_FORM_FIELDS:
            return handleReceiveFormFields(state, action);
        case ActionTypes.RECEIVE_INITIAL_VALUES:
            return handleReceiveInitialValues(state, action);
        case ActionTypes.CLEAR_FORM_FIELDS:
            return handleClearFormFields(state);
        case ActionTypes.CLEAR_INITIAL_VALUES:
            return handleClearInitialValues(state);
    }
    return state;
}

function handleReceiveFormFields(state, action) {
    return state.setIn(Path.SIMULATION_TEMPLATE_FORMFIELDS, action.result);
}

function handleClearFormFields(state) {
    return state.delete(Path.SIMULATION_TEMPLATE_FORMFIELDS[0]);
}

function handleReceiveInitialValues(state, action) {
    return state.setIn(Path.SIMULATION_TEMPLATE_INITIALVALUES, action.result);
}

function handleClearInitialValues(state) {
    return state.delete(Path.SIMULATION_TEMPLATE_INITIALVALUES[0]);
}


