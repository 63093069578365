import { createSelector } from 'reselect';
import { DefaultGridDataModel } from './DefaultGridDataModel';

export const createGridDataModelSelector = (dataModel) => createSelector(
    (formModel) => formModel.data,
    (formModel) => formModel.bufferedData,
    (data, bufferedData) => {
        if(typeof dataModel === 'function') {
            return dataModel(data, bufferedData);
        } else {
            return new DefaultGridDataModel(dataModel, data, bufferedData);
        }
    });
