import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { error, success } from 'react-notification-system-redux';

import { dataToRequestLines, generateLines } from './manualEntryHelpers';
import ActionTypes, { ForecastROPSchemeTypes } from '../forecastingActions';
import CommonActionTypes, { showGeneralRemark } from '../../common/commonActions';
import { getDataDiff, modifiedLinesMissingRemarks, withGeneralRemark } from '../forecastingHelpers';
import { getCurrentSimulationInstance, isGeneralRemarkVisible } from '../../common/commonSelectors';
import { hideForm, refreshForm, setFormBufferedData, setFormData } from '../../common/masterform/formActions';
import { checkSalesForecastGroupDuplicates, createForecastLinesManual } from '../../utils/remoting/api';
import { withFormId } from '../../utils/sagaHelpers';
import { createRequestBuilderFromState } from '../../utils/remoting/vcoRequestBuilder';
import { SimulationInstanceRecord } from '../../utils/remoting/types/simulationInstanceRequestTypes';
import { FormModel } from '../../common/masterform/types/formConfigTypes';
import { Values } from './formConfig';
import { CheckSalesForecastGroupDuplicatesParams } from '../../utils/remoting/requestTypes';

export default function() {
    return all([
        takeEvery(ActionTypes.MANUAL_ENTRY_GENERATE_ROWS, generateRows),
        takeEvery(ActionTypes.MANUAL_ENTRY_SAVE_LINES, saveLinesCheckMissingRemarks)
    ]);
}

type GenerateRowsType = {
    type: string, formModel: FormModel
}

function* generateRows({formModel}: GenerateRowsType) {
    const values = formModel.bufferedValues;
    const formId = formModel.formId;
    const periodFrom = values.periodFrom;
    const uom = values.stockUom;
    if (periodFrom == null) return;

    const lines = generateLines(uom, periodFrom);
    yield put(setFormData(formId, lines));
}

function* saveLinesCheckMissingRemarks({formModel}: GenerateRowsType) {
    const { formId, data, bufferedData } = formModel;

    const generalRemarkVisible = yield select((state) => isGeneralRemarkVisible(state, formId));
    if(generalRemarkVisible) return;

    let dataDiff = getDataDiff(data, bufferedData);
    if(modifiedLinesMissingRemarks(dataDiff)) {
        yield* handleGeneralRemark(dataDiff, formModel);

    } else {
        yield* handleCreateForecastLinesManual(formModel);
    }
}

function *handleGeneralRemark(dataDiff: any, formModel: FormModel) {
    const formId = formModel.formId;
    
    yield put(showGeneralRemark(formId));
    
    // wait for the general remark to be confirmed
    const { ignored, confirmed } = yield race({
        ignored: take(withFormId(CommonActionTypes.IGNORE_GENERAL_REMARK, formId)),
        confirmed: take(withFormId(CommonActionTypes.CONFIRM_GENERAL_REMARK, formId)),
        cancelled: take(withFormId(CommonActionTypes.CANCEL_GENERAL_REMARK, formId))
    });
    
    if(ignored) {
        yield* handleCreateForecastLinesManual(formModel);
        
    } else if(confirmed) {
        const { remark } = confirmed;
        const data = formModel.bufferedData;
        const version = formModel.version;

        const dataDiffUpdated = withGeneralRemark(dataDiff, remark);
        const dataWithRemarks = dataDiffUpdated.reduce((data: any, diff: any) => data.set(diff.get('index'), diff.get('next')), data);
        yield put(setFormBufferedData(formId, dataWithRemarks, version));
    }
}

function* checkForecastGroupExist({productNo, customerNo, plant, company}: Values) {
    let result = false;
    try {
        const requestBuilder = yield select(createRequestBuilderFromState);
        const schemeType = resolveSchemeType(yield select(getCurrentSimulationInstance));
        if (!plant || !company) {
            console.error('Plant (' + plant + ') or company (' + company + ') is missed, therefore it isn\'t possible to check duplicates.');
            return true;
        }
        const params: CheckSalesForecastGroupDuplicatesParams = {
            schemeType,
            plants: [plant],
            companyCodes: [company],
            productNo,
            customerNo: customerNo || ''
        };
        result = yield call(checkSalesForecastGroupDuplicates, requestBuilder, params);
        if (result) {
            const customerMessagePart = customerNo ? `customer: \'` + customerNo + `\'` : `no customer`;
            const message = `Forecast for product: #` + productNo + `, plant: \'` + plant + `\' and ` + customerMessagePart + ` already exists.`;
            yield put(error({
                    title: 'Such forecast already exist',
                    message,
                    autoDismiss: 10
                })
            );
        }
    } catch (error) {
        console.error(error);
    }
    return result;
}

function resolveSchemeType(currentSimulationInstance: Map<string, any>) {
    if (!currentSimulationInstance) {
        return null;
    }
    const currentSimulationInstanceRec = SimulationInstanceRecord(currentSimulationInstance);
    if (currentSimulationInstanceRec.calcReorderPoint) {
        return ForecastROPSchemeTypes.MANUAL_ENTRY_ROP;
    } else if (currentSimulationInstanceRec.calcForecast) {
        return ForecastROPSchemeTypes.MANUAL_ENTRY;
    } else {
        return null;
    }
}

function *handleCreateForecastLinesManual(formModel: FormModel) {
    const values = formModel.bufferedValues;
    const alreadyExist = yield* checkForecastGroupExist(values);
    if (alreadyExist) {
        return;
    }

    const data = formModel.bufferedData;
    const parentFormId = formModel.parent!.formId;
    const formId = formModel.formId;

    const productNo = values.productNo;
    const customerNo = values.customerNo;
    const company = values.company;
    const plant = values.plant;
    const lines = dataToRequestLines(data);

    try {
        // create the requestBuilder
        const requestBuilder = yield select(createRequestBuilderFromState);
        
        yield call(createForecastLinesManual, requestBuilder, productNo, customerNo, company, plant, lines);
        yield put(refreshForm(parentFormId));
        yield put(hideForm(formId));
        yield put(success({ title: 'Manual forecast line entry(s) created' }));

    } catch(error) {
        console.error(error);
    }
}
