import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import moment from 'moment';
import { pushLocation } from '@pearlchain/component-lib-common';
import { SelectBinder, TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import Form from '../common/masterform/Form';
import { CommandConfig, FormConfig, FormModel } from '../common/masterform/types/formConfigTypes';
import ProductBinder from '../common/binder/product/ProductBinder';
import DateBinder from '../common/binder/DateBinder';
import { apiRequest } from '../common/commonActions';
import { RequestId } from '../utils/requests';
import { refreshForm, setFormValues } from '../common/masterform/formActions';
import { getApiData, isRequestFetching } from '../common/commonSelectors';
import { ForecastAccuracyOverviewGroupResponse, ForecastAccuracyOverviewRequest } from '../utils/remoting/types/forecastAccuracyRequestTypes';
import { createDataModel } from './dataModel';
import { EXTS } from '../utils/powergrid/powergridHelpers';
import productBinderFormConfig from '../common/binder/product/productLookupFormConfig';
import ProductGroupBinder from '../common/binder/ProductGroupBinder';
import InventoryPlannerBinder from '../common/binder/InventoryPlannerBinder';
import { createTranslateDecorator } from '../utils/datamodel/TranslateGridDataDecorator';
import PlantBinder from '../common/binder/PlantBinder';
import { getForm } from '../common/masterform/formSelectors';
import { StoreState } from '../../types/storeTypes';


type FM = FormModel<ForecastAccuracyOverviewGroupResponse[], Values, {}>;

const PRODUCT_BINDER_FORM_ID = 'forecast-acc-overview-product-binder';

interface Values {
    company: string[],
    productNo: string,
    productDesc: string,
    productGroup: string,
    inventoryPlanner: string,
    customerNo: string,
    customerName: string,
    periodFrom: string,
    periodTo: string,
    maxResults: number,
    plants: string[]
}

const viewDetailsCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.viewDetails',
    disabled: (formModel) => !formModel.selection,
    action: (formModel) => {
        return setFormValues('forecast-accuracy-details',{
            productNo: formModel.selection!.get('productNo'),
            productDescription: formModel.selection!.get('productDescription'),
            plant: formModel.selection && formModel.selection.get('plant'),
            company: formModel.selection && formModel.selection.get('plant'),
            periodFrom: formModel.bufferedValues ? moment(formModel.bufferedValues.periodFrom) : undefined,
            periodTo: formModel.bufferedValues ? moment(formModel.bufferedValues.periodTo) : undefined
        })
    }
};

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            data: (state) => getApiData(state, RequestId.FIND_FORECAST_ACCURACY_OVERVIEW),
        },
        eventHandlers: {
            ['action-show-forecast-details']: (event: string, formModel: FM) => {
                return setFormValues('forecast-details',{
                    fetchOnMount: true,
                    initialValues: {
                        productNo: formModel.selection.get('productNo'),
                        plant: [formModel.selection.get('plant')],
                        startDate: moment().subtract(12, 'months'),
                        endDate: moment().startOf('week'),
                        pastPeriods: true,
                        historicalForecasts: true,
                        activeForecast: true
                    }
                })
            }
        },
        busy: (state) => isRequestFetching(state, RequestId.FIND_FORECAST_ACCURACY_OVERVIEW),
        fetchData: (formModel) => apiRequest(RequestId.FIND_FORECAST_ACCURACY_OVERVIEW, (formModel.bufferedValues as ForecastAccuracyOverviewRequest))
    },
    selector: {
        icon: { name: 'bullseye', showBusy: false  },
        layout: {
            component: 'grid'
        },
        fields: {
            company: { label: 'common.selector.company', component: CompanyBinder },
            plants: { label: 'common.selector.plant', component: PlantBinder },
            productNo: {
                label: 'common.selector.productNo', binderFormId: PRODUCT_BINDER_FORM_ID, component: ProductBinder, disabled: true,
                resultMapping: {description: 'productDesc'}
            },
            productDesc: { label: 'common.selector.productDesc', component: TextBinder, disabled: true },
            productGroup: { label: 'common.selector.productGroup', component: ProductGroupBinder },
            inventoryPlanner: { label: 'common.selector.inventoryPlanner', component: InventoryPlannerBinder },
            customerNo: { label: 'common.selector.customerNo', component: TextBinder },
            customerName: { label: 'common.selector.customerName', component: TextBinder },
            periodFrom: { label: 'common.selector.periodFrom', component: DateBinder },
            periodTo: { label: 'common.selector.periodTo', component: DateBinder },
            maxResults: { label: 'common.binder.selector.limit', component: SelectBinder, options: [
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
                { label: '1000', value: 1000 }
            ]}
        },
        commands: [
            { label: 'common.selector.actions.search', type: 'primary', group: -1, action: (formModel) => refreshForm(formModel.formId), datacy: 'search-forecastaccuracy-btn' },
            viewDetailsCommand
        ]
    },
    grid: {
        dataModel: createDataModel,
        decorators: [
            createTranslateDecorator({ allHeaders: true })
        ],
        extensions: {
            [EXTS.NESTED_HEADERS]: true,
            [EXTS.RESIZING]: true,
            [EXTS.STYLING]: true,
            [EXTS.SELECTION]: true,
            [EXTS.ACTION_BUTTONS]: true,
            [EXTS.CONTEXT_MENU]: {
                commands: [
                    viewDetailsCommand
                ]
            }
        },
        frozenColumnsLeft: 5,
        events: ['action-show-forecast-details']
    },
    children: {
        [PRODUCT_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.product.title',
            form: {
                config: productBinderFormConfig
            }
        }
    }
};

function ForecastAccuracyPage(props: any) {
    const forecastFormInfo = useSelector((state: StoreState) => getForm(state,'forecast-accuracy-details'));
    const forecastDetailsFormInfo = useSelector((state: StoreState) => getForm(state,'forecast-details'));

    //To populate values in a different route. pushLocation not sending values to state
    useEffect(() => {
        if(forecastFormInfo?.get('values')){
            props.router.push('/forecast-accuracy-details', 'Forecast Accuracy Details')
        }
    }, [forecastFormInfo])

    useEffect(() => {
        if(forecastDetailsFormInfo?.get('values')){
            props.router.push('/graph', 'Forecast Analysis Graph')
        }
    }, [forecastDetailsFormInfo])

    return <Form
        formId="forecast-accuracy"
        config={formConfig as FormConfig<FormModel>}/>
};

export default ForecastAccuracyPage;
