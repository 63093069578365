import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import XHR from 'i18next-xhr-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { RequestBuilder } from '@pearlchain/component-lib-common';

const i18n = i18next
    .use(Backend);

i18n.options = {
    fallbackLng: 'en',
    keySeparator: '|',
    debug: false,
    ns: ['prl'],
    defaultNS: ['prl'],
    backend: {
        backends: [
            LocalStorageBackend, // primary
            XHR                  // fallback
        ],
        backendOptions: [
            {
                /* Local storage backend */
                prefix: 'i18next_res_'
            },
            {
                /* XHR Backend */
                loadPath: 'sec/api/translation/locales/{{lng}}/translations.json',
                crossDomain: true,
                ajax: fetchTranslations
            }
        ]
    },

    // react i18next special options (optional)
    react: {
        wait: true
    }
};

i18n.languages = ['en'];

export function fetchTranslations(url, body, callback) {
    return new RequestBuilder()
        .post(url)
        .fetch()
        .then((response) =>  {
            if (response.ok) {
                return response.text();

            } else {
                throw {
                    status: response.status,
                    message: response.statusText
                };
            }
        })
        .then((response) => callback(response, { status: response.status }))
        .catch(error => callback(undefined, error));
}

export default i18n;
