import React from 'react';
import { ButtonGroup } from 'reactstrap';

import { FormModel, CommandConfig } from "../types/formConfigTypes";
import CommandButton from './CommandButton';

interface Props {
    commands: CommandConfig[],
    formModel: FormModel,
    handleSubmit: () => void,
    valid?: boolean,
}

export default function CommandGroups(props: Props) {
    const { groupsBefore, ungrouped, groupsAfter } = toCommandGroups(props.commands);

    function renderCommandGroup(group: CommandConfig[], index: number) {
        return (
            <ButtonGroup key={index} className="form-command-group">
                { group.map((cmd, index) => renderCommand(cmd, index, true)) }
            </ButtonGroup>);
    }

    function renderUngrouped(ungrouped: CommandConfig[]) {
        return ungrouped.map((cmd, index) => renderCommand(cmd, index, false));
    }
    
    function renderCommand(command: CommandConfig, index: number, insideGroup: boolean) {
        return <CommandButton
                insideGroup={insideGroup}
                key={index}
                formModel={props.formModel}
                isFormValid={props.valid}
                handleSubmit={props.handleSubmit }
                {...command}/>
    }

    return (
        <div className="form-command-group-container">
            { groupsBefore.map(renderCommandGroup) }
            { renderUngrouped(ungrouped) }
            { groupsAfter.map(renderCommandGroup) }
        </div>
    );
}

function toCommandGroups(commands: CommandConfig[]) {
    const groups = new Map<number, CommandConfig[]>();
    const ungrouped: CommandConfig[] = [];

    for (const command of commands) {
        if (command.group == null) {
            ungrouped.push(command);
            
        } else {
            const groupId = command.group;
            let group: CommandConfig[]|undefined = groups.get(groupId);
            if (group == null) groups.set(groupId, group = []);
            group.push(command);
        }   
    }

    const groupsBefore: CommandConfig[][] = [];
    const groupsAfter: CommandConfig[][] = [];

    const groupIds = Array.from(groups.keys())
    groupIds.sort(compareNumeric);

    for (let groupId of groupIds) {
        const group = groups.get(groupId)!;
        if (groupId < 0) {
            groupsBefore.push(group);
        } else {
            groupsAfter.push(group);
        }
    }

    return {
        groupsBefore,
        ungrouped,
        groupsAfter
    };
}

const compareNumeric = (a: number, b: number) => a - b;
