import { createSelector } from "reselect";

import { Column } from "../../../types/columnTypes";
import { FormModel } from "../../common/masterform/types/formConfigTypes";
import { StoreState } from "../../../types/storeTypes";

import { isWorkingInLive } from "../../common/commonSelectors";
import { GridDataDecorator, GridDataDecoratorCreator } from "./GridDataDecorator";

/**
 * Grid data decorator that makes columns un-editable in live
 */

export class ReadonlyInLiveDataDecorator implements GridDataDecorator {
    private readonly live: boolean;
    
    constructor(live: boolean) {
        this.live = live;
    }

    includeColumn(column: Column) {
        // hide columns in live marked "hideInLive"
        if(this.live && column.get('hideInLive')) {
            return false;
        }
        return true;
    }

    modifyColumn(column: Column): Column {
        // if working in live, remove "editable" from this column
        if(this.live && column.get('editable')) {   
            return column.delete('editable');
            
        } else {
            return column;
        }
    }
}

export const createReadonlyDataDecorator = (): GridDataDecoratorCreator => createSelector(
        (formModel: FormModel, state: StoreState) => isWorkingInLive(state),
        (live) => new ReadonlyInLiveDataDecorator(live));

