import React from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { List, Map } from 'immutable';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestId } from '../../utils/requests';
import { dataToOptionsByKey } from '../../utils/binder/binderHelpers';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    dependsOn?: {};
    disabled: boolean;
}

export default function ForecastROPSchemeTypeBinder(props: Props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_FORECASTROP_SCHEME_TYPES}
        render={(opts: any) => {
            let aux = opts;
            if(opts){
                const map = Map().set('externalReference','')
                aux = opts.insert(0,map)
            }
            
        return (
            <SelectBinder options={dataToOptionsByKey(aux as List<Map<string,any>>|undefined, 'externalReference')} { ...props }/>
            )
        }}
        />
}
