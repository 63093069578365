import React, {useEffect, useState} from 'react';

import Form from '../../common/masterform/Form';
import formConfig from './formConfig';
import {useDispatch, useSelector} from 'react-redux';
import {StoreState} from '../../../types/storeTypes';
import {getRequestData} from '../../common/commonSelectors';
import {RequestId} from '../../utils/requests';
import {info} from 'react-notification-system-redux';
import {useTranslation} from 'react-i18next';
import {getForm} from "../../common/masterform/formSelectors";
import GraphModal from '../../forecast-details/graph/GraphModal';
import { setFormValues } from '../../common/masterform/formActions';

export const icon = 'thermometer-three-quarters'
export const FormId = 'rop-overview';
export const AuditLogFormId = 'rop-audit-log';

export default function ReorderPointOverview(props: any) {
    const requestData = useSelector((state: StoreState) => getRequestData(state, RequestId.FIND_REORDER_POINTS));
    const forecastDetailsFormInfo = useSelector((state: StoreState) => getForm(state,'forecast-details'));
    
    const [show, setShow] = useState(false);
    const [fetchOnMount, setFetchOnMount] = useState(false);
    const [initialValues, setInitialValues] = useState();

    const handleClose = () => {
        dispatch(setFormValues("forecast-details", undefined))
        setShow(false)
    };
    
    const handleShow = () => setShow(true);
    
    const dispatch = useDispatch();
    const {t} = useTranslation();
    
    useEffect(() => {
        if(forecastDetailsFormInfo?.get('values')){
            if(initialValues != forecastDetailsFormInfo.get('values').initialValues){
                setFetchOnMount(forecastDetailsFormInfo?.get('values').fetchOnMount)
                setInitialValues(forecastDetailsFormInfo?.get('values').initialValues)
                handleShow()
            }    
        }
        
        if (requestData && requestData.get('data')) {
            let data = requestData.get('data')
            if (data.get('status') === 'IN_PROCESS') {
                dispatch(info({
                    message: t(data.get('message'))
                }));
            }
        }
    }, [requestData, forecastDetailsFormInfo])

    return (
        <>
            <Form formId={FormId} config={formConfig}/>

            <GraphModal show={show} 
                handleClose={handleClose} 
                fetchOnMount={fetchOnMount} 
                initialValues={initialValues} 
            />
        </>
    )
}
