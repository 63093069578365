import { all, put, takeEvery, select } from 'redux-saga/effects';

import { apiRequestAndRefreshForm } from '../common/commonActions';
import manualEntrySagas from './manualentry/manualEntrySagas';
import detailsSagas from './details/detailsSagas';
import ActionTypes from './forecastingActions';
import { setFormBufferedData } from '../common/masterform/formActions';
import { RequestId } from '../utils/requests';

export default function() {
    return all([
        takeEvery(ActionTypes.CALCULATE_PARTIAL, handleCalculatePartial),
        takeEvery(ActionTypes.SELECT_DESELECT_ALL_FORECAST_GROUPS, handleSelectDeselectAllForecastGroups),
        detailsSagas(),
        manualEntrySagas()
    ]);
}

function *handleCalculatePartial({ selectorFormId }) {
    let groupSelector = yield select((state) => state.form[selectorFormId].values);
    if (groupSelector == null) groupSelector = {};
    const selector = {
        productDescription: groupSelector.productDescription,
        productGroup: groupSelector.productGroup,
        inventoryPlanner: groupSelector.productInventoryPlanner, 
        productStatus: groupSelector.productStatus,
        scheme: groupSelector.scheme,
        algo: groupSelector.algo, 
        plants: groupSelector.plants,
        productNo: groupSelector.productNo
    }
    yield put(apiRequestAndRefreshForm(
        RequestId.CALCULATE_REORDERPOINT_OR_FORECAST,
        selectorFormId,
        'Calculation has successfully finished',
        { calcForecast: true, selector }
    ))
}

function *handleSelectDeselectAllForecastGroups({ formId, data, version }) {
    if (data.size > 0) {
        const allToBePublished = data.every((entry) => {
            return entry.get('toBePublished');
        });

        const newData = data.map((entry) => {
            return entry.set('toBePublished', !allToBePublished);
        });

        yield put(setFormBufferedData(formId, newData, version));
    }
}
