import { Map } from 'immutable';
import { Path, KEY_FETCHING, KEY_DATA } from './commonSelectors';
import ActionTypes from './commonActions';

export default function(state = Map(), action) {
    switch(action.type) {
        case ActionTypes.SET_PROVIDED_PATH:
            return setProvidedPath(state, action);
        case ActionTypes.BEGIN_API_REQUEST:
            return beginApiRequest(state, action);
        case ActionTypes.API_RESULT:
            return apiResult(state, action);
        case ActionTypes.API_ERROR:
            return apiError(state, action);
        case ActionTypes.CLEAR_API_DATA:
            return clearApiData(state, action);
        case ActionTypes.COPY_LOCAL_STORAGE_PARAMS_TO_STORE:
            return copyLocalStorageParamsToStore(state, action);
        case ActionTypes.SET_PERSISTENT_PARAM:
            return setPersistentParam(state, action);
        case ActionTypes.SHOW_GENERAL_REMARK:
            return showGeneralRemark(state, action);
        case ActionTypes.CONFIRM_GENERAL_REMARK:
        case ActionTypes.CANCEL_GENERAL_REMARK:
        case ActionTypes.IGNORE_GENERAL_REMARK:
            return hideGeneralRemark(state, action);
    }
    return state;
}

function setProvidedPath(state, action) {
    return state.setIn(action.path, action.value);
}

function beginApiRequest(state, action) {
    return state.setIn(Path.apiFetching(action.requestId), true);
}

function apiResult(state, action) {
    return state.updateIn(Path.request(action.requestId), Map(), (request) => request
        .set(KEY_FETCHING, false)
        .set(KEY_DATA, action.data)
    );
}

function apiError(state, action) {
    return state.setIn(Path.request(action.requestId), Map({
        [KEY_FETCHING]: false,
        error: action.error
    }));
}

function clearApiData(state, action) {
    return state.deleteIn(Path.request(action.requestId));
}

function copyLocalStorageParamsToStore(state, action) {
    return state.updateIn(Path.PERSISTENT_PARAMS, Map(), (params) => action.persistentParams.reduce(
            (state, param) => state.set(param.key, param.value), params));
}

function setPersistentParam(state, action) {
    return state.setIn(Path.persistentParam(action.key), action.value);
}

function showGeneralRemark(state, { formId }) {
    return state.setIn(Path.generalRemark(formId), Map({ visible: true }));
}

function hideGeneralRemark(state, { formId }) {
    return state.deleteIn(Path.generalRemark(formId));
}
