import { TextBinder } from '@pearlchain/component-lib-ui';

import { setPersistentParam } from '../../common/commonActions';
import { RequestId } from '../../utils/requests';
import { getCommonApiData, getSimulationInstances, isRequestFetching, KEY_SIM_CONTEXT_UID } from '../../common/commonSelectors';
import { refreshForm, showForm } from '../../common/masterform/formActions';
import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { findSimulationById, isActiveSimInstance } from '../../utils/simulationHelpers';
import TemplateBinder from '../../common/binder/TemplateBinder';
import SimTypeBinder from '../../common/binder/SimTypeBinder';
import { invalidateSimulationInstance, publishSimulationInstance } from '../simulationInstanceActions';
import detailsFormConfig from '../details/formConfig';
import newInstanceFormConfig from '../new/formConfig';
import { COLUMNS_META, ROW_ID_KEY, SIMULATION_INSTANCE_OVERVIEW_COLUMNS } from './columns';
import { formatters } from '../../utils/powergrid/formatters';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import PlanningStatesBinder from '../../common/binder/PlanningStatesBinder';
import { fetchSimulationInstances } from '../simulationInstanceActions';

export const FormId = 'simulationInstance-overview';
export const icon = 'globe';

const INSTANCE_PUBLISHING_REQUEST_SENT_FLAG_NAME = 'instancePublishingRequestSent';
const VIEW_DETAILS_FORM_ID = 'view-details';
const NEW_SIMULATION_INSTANCE_FORM_ID = 'new-sim-instance';

const newSimulationInstanceCommand = {
    label: 'common.selector.actions.new',
    action: () => showForm(NEW_SIMULATION_INSTANCE_FORM_ID, {})
};

const switchToSimulationInstanceCommand = {
    label: 'simulationInstance.selector.actions.switchTo',
    disabled: (formModel, state) => !isActionableSimulationInstanceSelected(formModel, state),
    action: (formModel) => setPersistentParam(KEY_SIM_CONTEXT_UID, formModel.selection.get('uniqueIdentifier'))
};

const invalidateSimulationInstanceCommand = {
    label: 'simulationInstance.selector.actions.invalidate',
    disabled: (formModel, state) => !isActionableSimulationInstanceSelected(formModel, state),
    action: (formModel) => invalidateSimulationInstance(formModel.selection.get('uniqueIdentifier'), formModel.formId)
};

const publishCommand = {
    label: 'simulationInstance.selector.actions.publish',
    disabled: (formModel, state) => !isActionableSimulationInstanceSelected(formModel, state),
    action: (formModel) => publishSimulationInstance(formModel.selection.get('uniqueIdentifier'), formModel.formId)
};

const viewDetailsCommand = {
    label: 'common.selector.actions.view',
    disabled: (formModel, state) => !isActionableSimulationInstanceSelected(formModel, state),
    action: () => showForm(VIEW_DETAILS_FORM_ID, {})
};

const formConfig = {
    form: {
        model: {
            data: (state) => getCommonApiData(state, RequestId.FIND_SIMULATION_INSTANCES_FILTERED)
        },
        busy: (state) => isRequestFetching(state, RequestId.FIND_SIMULATION_INSTANCES_FILTERED),
        fetchData: (formModel) => fetchSimulationInstances(formModel.bufferedValues)
    },
    selector: {
        icon: { name: icon },
        layout: {
            component: 'grid',
            numCols: { xs: 1, sm: 2, md: 3, lg: 4 },
            fields: [
                { name: 'simulationName', component: TextBinder, label: 'common.selector.name' },
                { name: 'simulationTemplate', component: TemplateBinder, label: 'common.selector.template', clearable: true },
                { name: 'creationUser', component: TextBinder, label: 'common.selector.creationUser' },
                { name: 'planningStates', component: PlanningStatesBinder, label: 'common.selector.planningStates', multi: true },
                { name: 'simulationType', component: SimTypeBinder, label: 'common.selector.type', clearable: true }
            ]
        },
        commands: [
            { label: 'common.selector.actions.search', type: 'primary', group: -1, action: (formModel) => refreshForm(formModel.formId) },
            viewDetailsCommand,
            invalidateSimulationInstanceCommand,
            publishCommand,
            switchToSimulationInstanceCommand,
            newSimulationInstanceCommand
        ]
    },
    grid: {
        dataModel: {
            columns: SIMULATION_INSTANCE_OVERVIEW_COLUMNS,
            rowIdField: ROW_ID_KEY
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META })
        ],
        extensions: {
            [EXTS.RESIZING]: true,
            [EXTS.SELECTION]: true,
            [EXTS.EDITING]: true,
            [EXTS.FORMATTING]: {
                formatters
            },
            [EXTS.CONTEXT_MENU]: {
                commands: [
                    newSimulationInstanceCommand,
                    switchToSimulationInstanceCommand
                ]
            }
        }
    },
    children: {
        [VIEW_DETAILS_FORM_ID]: {
            component: 'modal',
            title: 'simulationInstance.details.title',
            form: {
                config: detailsFormConfig
            }
        },
        [NEW_SIMULATION_INSTANCE_FORM_ID]: {
            component: 'modal',
            title: 'simulationinstance.new',
            form: {
                config: newInstanceFormConfig
            }
        }
    }
};

function isActionableSimulationInstanceSelected(formModel, state) {
    const selection = formModel.selection;
    if (!selection) {
        return false;
    }

    const simulations = getSimulationInstances(state);
    if (!simulations) {
        return false;
    }

    let selectedSimulationId = selection.get('id');
    const simulation = findSimulationById(simulations, selectedSimulationId);
    if (!simulation) {
        return false;
    }

    let instancePublishingRequestSent = selection.get(INSTANCE_PUBLISHING_REQUEST_SENT_FLAG_NAME, false);
    if (instancePublishingRequestSent) {
        return false;
    }

    let instanceIsInactive = !isActiveSimInstance(simulation);
    //noinspection RedundantIfStatementJS
    if (instanceIsInactive) {
        return false;
    }

    return true;
}

export default formConfig;
