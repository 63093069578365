import React from 'react';
import moment from 'moment';
import { formatPeriodWeek } from '../utils/powergrid/formatters';
import { NavItem, NavLink } from 'reactstrap';

export default function CurrentWeek() {
    return <NavItem>
        <NavLink href="#">{formatPeriodWeek(moment())}</NavLink>
    </NavItem>;
}
