import { List } from 'immutable';
import { contextMenuExtension } from './extensions/contextMenuExtension';
require('jsrender')($);

export const EXTS = {
    STYLING: 'styling',
    RESIZING: 'resizing',
    EDITING: 'editing',
    SORTING: 'sorting',
    SELECTION: 'selection',
    COLUMN_SIZING: 'column-sizing',
    DIRECT_INPUT: 'direct-input',
    FORMATTING: 'formatting',
    ACTION_BUTTONS: 'action-buttons',
    NESTED_HEADERS: 'nested-headers',
    CONTEXT_MENU: 'context-menu'
}

/**
 * Simple data transformer for the case where the data matches perfectly with the grid row.
 * Uses the index of each item as the id.
 */

 export function createbyIndexAdapter(columns) {
     return {
        getRows(formModel) {
            const data = formModel.data;
            if(data == null) return List();

            return data.map((dataRow, idx) => {
                return dataRow.set('id', idx);
            });
        },

        getColumns() {
            return columns;
        },

        getSelectionByRowId(rowId, formModel) {
            return formModel.data.get(rowId);
        }
    };
}

const extensionBuilders = {
    [EXTS.STYLING](extensions, callbacks) {
        extensions.styling = {
            applyClasses: callbacks.onApplyClasses
        };
    },

    [EXTS.RESIZING](extensions) {
        extensions.columnsizing = true
    },

    [EXTS.EDITING](extensions) {
        extensions.editing = {
            commitOnBlur: true
        };
    },

    [EXTS.SORTING](extensions, callbacks) {
        extensions.sorting = {
            multiSort: true,
            onSortColumnsChanged: callbacks.onSortColumnsChanged
        };
    },

    [EXTS.SELECTION](extensions, callbacks) {
        extensions.selection = {
            onrowselected: callbacks.onRowSelected
        };
    },

    [EXTS.COLUMN_SIZING](extensions) {
        extensions.columnsizing = true;
    },

    [EXTS.DIRECT_INPUT](extensions) {
        extensions.directinput = true;
    },

    [EXTS.FORMATTING](extensions, _callbacks, ext) {
        extensions.formatting = ext.formatters;
    },

    [EXTS.ACTION_BUTTONS](extensions) {
        extensions.actionbuttons = true;
    },
    
    [EXTS.CONTEXT_MENU](extensions, _callbacks, ext, formId) {
        addCustomExtensions(extensions, [contextMenuExtension('ctx-' + formId)]);
    },

    [EXTS.NESTED_HEADERS](extensions) {
        extensions.templating = true;
        extensions.nestedheaders = true;
    }
}

/**
 * Allows Providing custom plugins to powergrid, by using the dummy extension hook.
 */
function addCustomExtensions(extensions, customExtensions) {
    extensions.dummy = function() {
        for(let custom of customExtensions) {
            custom.apply(this, arguments);
        }
    }
}

/**
 * Initializes the extensions object to pass to powergrid
 */
export function createExtensions(exts, callbacks, formId) {
    const extensions = {};
    if(exts) {
        for(let key in exts) {
            extensionBuilders[key](extensions, callbacks, exts[key], formId);
        }
    }
    return extensions;
}
