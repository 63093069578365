enum ActionTypes {
    SELECT_DESELECT_ALL_REORDER_POINTS = 'vco.reorderpoint.SELECT_DESELECT_ALL_REORDER_POINTS',
    CALCULATE_PARTIAL_REORDER_POINTS = 'vco.reorderpoint.CALCULATE_PARTIAL_REORDER_POINTS',
    RECEIVE_SELECT_STATE = 'vco.reorderpoint.RECEIVE_SELECT_STATE'
}

export type SelectDeselectAllReorderPointsActionType = {
    type: ActionTypes.SELECT_DESELECT_ALL_REORDER_POINTS, formId: string, data: any, version: number
}

export type CalculatePartialReorderPointsActionType = {
    type: ActionTypes.CALCULATE_PARTIAL_REORDER_POINTS, formId: string
}

export function calculatePartialReorderPoints(formModel: any) {
    return {type: ActionTypes.CALCULATE_PARTIAL_REORDER_POINTS, formModel}
}

export function selectDeselectAllReorderPoints(formId: string, data: any, version: number) {
    return {type: ActionTypes.SELECT_DESELECT_ALL_REORDER_POINTS, formId, data, version}
}

export function receiveSelectState(result: any) {
    return {type: ActionTypes.RECEIVE_SELECT_STATE, result}
}

export default ActionTypes;

