import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SplitPane from 'react-split-pane';
import ForecastDetailsInspector from '../../forecast-details/ForecastDetailsInspector';
import ForecastDetailsGraph from '../../forecast-details/ForecastDetailsGraph';

type Props = {
    show: boolean
    handleClose: () => void
    fetchOnMount: boolean
    initialValues: any
}

export default function GraphModal({show, handleClose, fetchOnMount, initialValues}:Props) {
    const [reloadGraph, setReloadGraph] = useState(false)

    useEffect(() => {
      if(initialValues && initialValues.plant){
        setReloadGraph(true)
      }
    }, [initialValues]);
    
    return (
        <Modal show={show} onHide={handleClose} className="grph" onExiting={() => setReloadGraph(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Graph</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="forecast-details-page-graph">
                <div className='d-flex h-100'>
                    <ForecastDetailsInspector fetchOnMount={fetchOnMount} initialValues={initialValues}/>
                    {reloadGraph ? 
                        <div className="graph-modal">
                            <ForecastDetailsGraph saving={false}/>
                        </div>:
                        null
                    }
                    
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}
