import { Record } from 'immutable';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { error, success } from 'react-notification-system-redux';
import ActionTypes, {
    FetchFormFieldsAction,
    FindSimulationInstanceAction,
    InvalidateSimulationInstanceAction,
    PublishSimulationInstanceAction,
    receiveFormFields,
    receiveInitialValues
} from './simulationInstanceActions';
import { getCommonApiData } from '../common/commonSelectors';
import { refreshForm } from '../common/masterform/formActions';
import { RequestId } from '../utils/requests';
import { convertToListOfInitialValues } from '../simulation/formFieldGenerator';
import { performApiRequest } from '../common/commonSagas';
import i18n from '../../i18n';
import { SimulationTypeResponse } from '../utils/remoting/types/simulationTypeRequestTypes';
import { SimulationTemplateOverviewResponse } from '../utils/remoting/types/simulationTemplateRequestTypes';
import { callRequestHandler, invokeRequestHandler, receiveCachedData } from '@pearlchain/component-lib-common';

export default function () {
    return all([
        takeEvery(ActionTypes.INVALIDATE_SIMULATION_INSTANCE, invalidateSimulationInstance),
        takeEvery(ActionTypes.PUBLISH_SIMULATION_INSTANCE, publishSimulationInstance),
        takeEvery(ActionTypes.GET_FORM_FIELDS, handleGetFormFields),
        takeEvery(ActionTypes.FIND_SIMULATION_INSTANCE, handleFindSimulationInstance),
        takeEvery(ActionTypes.FETCH_SIMULATION_INSTANCES, fetchSimulationInstances)
    ]);
}

function *invalidateSimulationInstance({ simulationInstanceUid, formId }: InvalidateSimulationInstanceAction) {
    try {
        const [ result ] = yield* performApiRequest(RequestId.INVALIDATE_SIMULATION_INSTANCE, [{uniqueIdentifier: simulationInstanceUid}]);

        if(!result) return;
        yield put(refreshForm(formId));
        yield put(success({ title: 'Simulation instance invalidated' }));

    } catch(error) {
        console.error(error);
    }
}

const TRANSLATION_KEY_PUBLISH_ERROR_NOTIFICATION_MESSAGE = 'simulationInstance.publish.error.notificationMessage';
const TRANSLATION_KEY_PUBLISH_ERROR_FAILED_PRODUCTS = 'simulationInstance.publish.error.failedProducts.notificationMessage';
const TRANSLATION_KEY_PUBLISH_SUCCESS_NOTIFICATION_MESSAGE = 'simulationInstance.publish.success.notificationMessage';

function* publishSimulationInstance({simulationInstanceUid, formId}: PublishSimulationInstanceAction) {
    try {
        const [response] = yield* performApiRequest(RequestId.PUBLISH_SIMULATION_INSTANCE, [{uid: simulationInstanceUid}]);

        if (!response) {
            return;
        }

        yield put(refreshForm(formId));

        let idsOfProductsFailedToUpdate = response.get('idsOfProductsFailedToUpdate');
        if (idsOfProductsFailedToUpdate !== null && !idsOfProductsFailedToUpdate.isEmpty()) {
            let errorMessage = i18n.t(TRANSLATION_KEY_PUBLISH_ERROR_NOTIFICATION_MESSAGE);
            let joinedIdsString = idsOfProductsFailedToUpdate.join(',');
            errorMessage += i18n.t(TRANSLATION_KEY_PUBLISH_ERROR_FAILED_PRODUCTS, {ids: joinedIdsString});
            yield put(error({title: errorMessage}));
        } else {
            let successMessage = i18n.t(TRANSLATION_KEY_PUBLISH_SUCCESS_NOTIFICATION_MESSAGE);
            yield put(success({title: successMessage}));
        }
    } catch (error) {
        console.error(error);
    }
}

function *handleGetFormFields({ templateUid }: FetchFormFieldsAction) {
    const selectedTemplate = yield call(getSimulationTemplateFromStore, templateUid);
    if (selectedTemplate) {
        const simulationType = yield call(getSimulationTypeFromStore, selectedTemplate[1]);
        if (simulationType) {
            let initialValues: any = convertToListOfInitialValues(simulationType[1], selectedTemplate[1]);

            const plants = selectedTemplate[1].get('plants');
            initialValues.plants = plants;

            const companyCodes = selectedTemplate[1].get('companyCodes');
            initialValues.companyCodes = companyCodes;

            yield put(receiveFormFields(simulationType[1]));
            yield put(receiveInitialValues(initialValues));
        }        
    }
}

function *getSimulationTemplateFromStore(templateUid: string) {
    const simulationTemplates = yield select(getCommonApiData, RequestId.LIST_SIMULATION_TEMPLATES);
    return simulationTemplates.findEntry((value: Record<SimulationTemplateOverviewResponse>) => { return value.get('uniqueIdentifier') === templateUid});
}

function *getSimulationTypeFromStore(selectedTemplate: Record<SimulationTemplateOverviewResponse>) {
    const simulationTypes = yield select(getCommonApiData, RequestId.LIST_SIMULATION_TYPES);
    return simulationTypes.findEntry((value: Record<SimulationTypeResponse>) => { return value.get('externalReference') === selectedTemplate.get('externalReference') });
}

function *handleFindSimulationInstance({ instanceUid }: FindSimulationInstanceAction) {
    const simulationInstance = yield call(getSimulation, instanceUid);
    if (simulationInstance) {
         const simulationType = yield call(getSimulationType, simulationInstance.get('simulationType'));
         if (simulationType) {
            const initialValues = convertToListOfInitialValues(simulationType[1], simulationInstance);
            yield put(receiveFormFields(simulationType[1]));
            yield put(receiveInitialValues(initialValues as any));
         }
    }
}

function *getSimulation(instanceUid: string) {
    const params = [instanceUid];
    const reqId = [RequestId.FIND_SIMULATION_INSTANCE.toString()].concat(params);

    const storedSimulation = yield select(getCommonApiData, reqId);
    if (storedSimulation) {
        return storedSimulation;
    }

    const [simulationInstance] = yield* callRequestHandler(RequestId.FIND_SIMULATION_INSTANCE, params);
    if (simulationInstance) {
        yield put(receiveCachedData(reqId, simulationInstance));
    }
    return simulationInstance;
}

function *getSimulationType(simulationType: string) {
    const storedTypes = yield select(getCommonApiData, RequestId.LIST_SIMULATION_TYPES);
    if (storedTypes) {
        return storedTypes.findEntry((value: Record<SimulationTypeResponse>) => { return value.get('externalReference') === simulationType});
    }

    const [simulationTypes] = yield* callRequestHandler(RequestId.LIST_SIMULATION_TYPES);
    if (simulationTypes) {
        const reqId = [RequestId.LIST_SIMULATION_TYPES.toString()];
        yield put(receiveCachedData(reqId, simulationTypes));
    }
    return simulationTypes.findEntry((value: Record<SimulationTypeResponse>) => { return value.get('externalReference') === simulationType});
}

function *fetchSimulationInstances({params}: any) {
    yield put(invokeRequestHandler(RequestId.FIND_SIMULATION_INSTANCES_FILTERED, params));
    yield put(invokeRequestHandler(RequestId.FIND_SIMULATION_INSTANCES, {}));
}
