import { SelectBinder, TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import { apiRequest } from '../../commonActions';
import { RequestId } from '../../../utils/requests';
import { getApiData } from '../../commonSelectors';
import ProductGroupBinder from '../ProductGroupBinder';
import ProductStatusBinder from '../ProductStatusBinder';
import CheckboxBinder from '../CheckboxBinder';
import { refreshForm, completeFormProvideResult } from '../../masterform/formActions';
import { columns } from './productLookupColumns';
import { EXTS } from '../../../utils/powergrid/powergridHelpers';
import { createTranslateDecorator } from '../../../utils/datamodel/TranslateGridDataDecorator';
import { FormConfig, FormModel } from '../../masterform/types/formConfigTypes';
import { resolveResultFieldValueMap } from '../BinderHelper';

export const FORM_ID = 'product-binder';

interface OwnFormModelValues { }

export interface Params {
    productNo?: string,
    company?: string,
    plant?: string,
    forecastROPScheme?: string,
    resultFormId: string,
    resultField: string,
    resultMapping: {[key: string]: string}
}

type FM = FormModel<any, OwnFormModelValues, Params>

function getInitialValues(params: Params) {
    return {
        productNo: params.productNo,
        companyCodes: params.company ? [params.company] : undefined,
        plant: params.plant,
        forecastROPScheme: params.forecastROPScheme
    };
}

const formConfig: FormConfig<FM> = {
    form: {
        fetchOnMount: false,
        model: {
            values: (state, formId, params, select) => select(params)(getInitialValues),
            data: (state) => getApiData(state, RequestId.FIND_PRODUCTS)
        },
        fetchData: (formModel) => apiRequest(RequestId.FIND_PRODUCTS, formModel.bufferedValues)
    },
    selector: {
        layout: {
            component: 'grid',
            numCols: 2,
        },
        fields: {
            companyCodes: { label: 'common.selector.company', component: CompanyBinder, multi: true },
            productNo: { label: 'common.binder.product.selector.no', component: TextBinder },
            productGroup: { label: 'common.binder.product.selector.productGroup', component: ProductGroupBinder },
            status: { label: 'common.binder.product.selector.status', component: ProductStatusBinder },
            remark: { label: 'common.binder.product.selector.remark', component: TextBinder },
            isActive: { label: 'common.binder.product.selector.isActive', component: CheckboxBinder },
            description: { label: 'common.binder.product.selector.description', component: TextBinder },
            limit: { label: 'common.binder.selector.limit', component: SelectBinder, options: [
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
                { label: '100', value: 100 }
            ]}
        },
        commands: [
            {
                label: 'common.binder.product.command.search',
                action: (formModel) => refreshForm(formModel.formId)
            },
            {
                label: 'common.binder.product.command.select',
                disabled: (formModel) => !formModel.selection,
                action: (formModel) => {
                    const resultMapping = resolveResultFieldValueMap('no', formModel);
                    return completeFormProvideResult(
                        formModel.formId,
                        formModel.params.resultFormId,
                        resultMapping)
                }
            }
        ]
    },
    grid: {
        dataModel: {
            columns,
            getId: (dataItem) => dataItem.get('no'),
            rowIdField: 'id'
        },
        extensions: {
            [EXTS.RESIZING]: true,
            [EXTS.SELECTION]: true
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true })
        ]
    }
};

export default formConfig;
