import { fromJS } from 'immutable';

export const columns = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'date', width: 130, title: 'Date', formatter: 'date' },
    { _key: 'user', width: 130, title: 'User' },
    { _key: 'type', width: 130, title: 'Type' },
    { _key: 'context', width: 130, title: 'Context' },
    { _key: 'field', width: 130, title: 'Field' },
    { _key: 'action', width: 130, title: 'Action' },
    { _key: 'oldValue', width: 130, title: 'Old Value' },
    { _key: 'newValue', width: 130, title: 'New Value' },
]);
