import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestIdFetcher } from '@pearlchain/component-lib-common';
import { RequestId } from '../../utils/requests';
import { dataToOptions } from '../../utils/binder/binderHelpers';

export default function InventoryPlannerBinder(props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_INVENTORY_PLANNERS}
        render={(opts) => {
            let aux = opts;
            if(opts){
                aux = opts.insert(0,"")
           }
            return (
                <SelectBinder options={dataToOptions(aux)} { ...props }/>
            )
        }}
        />
}
