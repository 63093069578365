import { Map, Record, RecordOf } from "immutable";

type PropertiesStateProps = {
    properties: Map<string, unknown> | undefined;
}

export type PropertiesState = RecordOf<PropertiesStateProps>;

export const createPropertiesState = Record<PropertiesStateProps>({
    properties: undefined
});
