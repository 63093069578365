import { Moment } from 'moment';
import { Record, Map } from 'immutable';
import { TextBinder, SelectBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import ProductBinder from '../common/binder/product/ProductBinder';
import CustomerBinder from '../common/binder/customer/CustomerBinder';
import DateBinder from '../common/binder/DateBinder';
import { refreshForm } from '../common/masterform/formActions';
import { RequestId } from '../utils/requests';
import { EXTS } from '../utils/powergrid/powergridHelpers';
import { getApiData, isRequestFetching } from '../common/commonSelectors';
import { apiRequest, apiSaveData } from '../common/commonActions';
import columns, { COLUMN_IDX_FORECAST_DELIVERY_DATE, COLUMN_IDX_FORECAST_QUANTITY, COLUMNS_META } from './columns';
import { compareFields, getDiffs, dateStringToRequest, compareRecords, stringToDateString } from '../utils/helpers';
import { formatters } from '../utils/powergrid/formatters';
import productLookupFormConfig from '../common/binder/product/productLookupFormConfig';
import customerLookupFormConfig from '../common/binder/customer/customerLookupFormConfig';
import { createTranslateDecorator } from '../utils/datamodel/TranslateGridDataDecorator';
import { FormConfig, FormModel } from '../common/masterform/types/formConfigTypes';
import { SalesOrderOverviewResponse } from '../utils/remoting/types/salesOrdersRequestTypes';
import PlantBinder from '../common/binder/PlantBinder';

type SalesOrderOverviewRespRecord = Record<SalesOrderOverviewResponse>;

type FM = FormModel<SalesOrderOverviewRespRecord[], Values, {}>;

const PRODUCT_BINDER_FORM_ID = 'salesorders-product-binder';
const CUSTOMER_BINDER_FORM_ID = 'salesorders-customer-binder';

interface Values {
    productNo: string,
    customerNo: string,
    companyCodes: string[],
    from: Moment,
    to: Moment,
    maxResults: number,
    plants: string[],
    no: string
}

function getId(dataItem: SalesOrderOverviewRespRecord): string {
    const uid = dataItem.get('uniqueIdentifier');
    if (uid == undefined) {
        return '';
    }
    return uid;
}

function buildUpdateRequest(formModel: FM) {
    const { data, bufferedData } = formModel;
    const { updated } = getDiffs(data!, bufferedData!, getId, (a, b) => compareRecords(['forecastingQuantity', 'forecastingDeliveryDate'], a, b));

    return updated.map(([_prev, data]) => ({
        id: data.get('id'),
        version: data.get('version'),
        forecastingQuantity: data.get('forecastingQuantity'),
        forecastingDeliveryDate: dateStringToRequest(data.get('forecastingDeliveryDate')),
    }));
}

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            data: (state) => getApiData(state, RequestId.FIND_SALES_ORDERS),
        },
        busy: (state) => isRequestFetching(state, RequestId.FIND_SALES_ORDERS),
        fetchData: (formModel) => apiRequest(RequestId.FIND_SALES_ORDERS, formModel.bufferedValues)
    },
    selector: {
        icon: { name: 'receipt', showBusy: false },
        layout: {
            component: 'grid',
            numCols: { xs: 1, sm: 2, md: 3, lg: 4 },
        },
        fields: {
            companyCodes: { label: 'common.selector.company', component: CompanyBinder, multi: true },
            plants: { label: 'common.selector.plant', component: PlantBinder },
            productNo: { label: 'common.selector.productNo', binderFormId: PRODUCT_BINDER_FORM_ID, component: ProductBinder },
            customerNo: { label: 'common.selector.customerNo', binderFormId: CUSTOMER_BINDER_FORM_ID, component: CustomerBinder },
            from: { label: 'common.selector.periodFrom', component: DateBinder, colDim: { xs: 6, sm: 3, md: 2, lg: 2 } },
            to: { label: 'common.selector.periodTo', component: DateBinder, colDim: { xs: 6, sm: 3, md: 2, lg: 1 }},
            maxResults: { colDim: { xs: 12, sm: 3, md: 2, lg: 2 }, label: 'salesorders.selector.maxResults',
                component: SelectBinder,
                options: [
                    { value: 10, label: '10' },
                    { value: 50, label: '50' },
                    { value: 100, label: '100' },
                    { value: 500, label: '300' }
                ]
            },
            no: { label: 'salesorders.selector.no', component: TextBinder },
        },
        commands: [
            {
                label: 'Search',
                type: 'primary',
                group: -1,
                action: (formModel) => refreshForm(formModel.formId)
            },
            {
                label: 'Save Changes',
                type: 'success',
                group: 1,
                disabled: (formModel) => !formModel.bufferedData,
                action: (formModel) => apiSaveData(RequestId.UPDATE_SALES_ORDERS, formModel.formId, 'Salesorders Saved', buildUpdateRequest(formModel))
            }
        ]
    },
    grid: {
        dataModel: {
            columns,
            getId: (dataItem) => dataItem.get('uniqueIdentifier'),
            getRow(data: SalesOrderOverviewRespRecord) {
                const deliveryDate = data.get('forecastingDeliveryDate');
                const deliveryDateString = deliveryDate && stringToDateString(deliveryDate);
                return Map().merge(data).set('forecastingDeliveryDate', deliveryDateString);
            },
            shouldApplyClasses: (column) => column.key === COLUMN_IDX_FORECAST_QUANTITY || column.key === COLUMN_IDX_FORECAST_DELIVERY_DATE,
            getClass: (column: { key: number }, dataItem, { original }) => {
                return (column.key === COLUMN_IDX_FORECAST_QUANTITY && !compareFields(['forecastingQuantity'], dataItem, original))
                    || (column.key === COLUMN_IDX_FORECAST_DELIVERY_DATE && !compareFields(['forecastingDeliveryDate'], dataItem, original))
                    ? 'pg-cell-modified' : undefined;
            }
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META })
        ],
        extensions: {
            [EXTS.EDITING]: true,
            [EXTS.RESIZING]: true,
            [EXTS.STYLING]: true,
            [EXTS.FORMATTING]: {
                formatters 
            }
        }
    },
    children: {
        [PRODUCT_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.product.title',
            form: {
                config: productLookupFormConfig
            }
        },
        [CUSTOMER_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.customer.title',
            form: {
                config: customerLookupFormConfig
            }
        }
    }
};

export default formConfig;
