import { createStore, compose, applyMiddleware } from 'redux';
import { hashHistory } from 'react-router';
import createSagaMiddleware from 'redux-saga';
import { createLocationMiddleware } from '@pearlchain/component-lib-common';
import createSyncLocalstorageMiddleware, { INIT_ACTION } from './syncLocalStorageMiddleware'

import reducers from './reducers';
import sagas from './sagas';
import initialState from './initialState';
import { getAllPersistentParams, KEY_SIM_CONTEXT_UID } from '../components/common/commonSelectors';

const sagaMiddleware = createSagaMiddleware();

const localStorageMiddleware = createSyncLocalstorageMiddleware({
  cache: localStorage,
  selector: getAllPersistentParams,
  params: [{ key: KEY_SIM_CONTEXT_UID }]
});

const store = createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(
      sagaMiddleware,
      createLocationMiddleware(hashHistory),
      localStorageMiddleware
    ),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);

store.dispatch({ type: INIT_ACTION });
sagaMiddleware.run(sagas);

if(module.hot) {
  module.hot.accept('./reducers.js', () => {
    const nextRootReducer = require('./reducers.js');
    store.replaceReducer(nextRootReducer.default);
  });
}

export default store;
