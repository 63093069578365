import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { TextBinder } from '@pearlchain/component-lib-ui';

import DateBinder from '../../common/binder/DateBinder';

import GridLayout from '../../common/layout/GridLayout';
import Text from '../../common/layout/Text';
import Binder from '../../common/binder/Binder';
import { FM } from "./formConfig";
import { formatters } from '../../utils/powergrid/formatters';


interface Props {
    formId: string,
    formModel: FM,
    readOnly: boolean
}

export default function FieldsLayout(props: Props) {
    return (
        <Fragment>
            <GridLayout numCols={3}>
                <Field name="companyName" disabled component={Binder} binder={TextBinder} label="common.selector.company"/>
                <Field name="plant" disabled component={Binder} binder={TextBinder} label="common.selector.plant"/>
                <Field name="productNo" disabled component={Binder} binder={TextBinder} label="common.selector.productNo"/>
                <Field name="productDescription" disabled component={Binder} binder={TextBinder} label="common.selector.productDesc"/>
                <Field name="productGroup" disabled component={Binder} binder={TextBinder} label="common.selector.productGroup"/>
                <Field name="uom" disabled component={Binder} binder={TextBinder} label="common.selector.uom" translate/>
                <Field name="forecastROPScheme" disabled component={Binder} binder={TextBinder} label="common.selector.forecastropscheme" translate/>
                <Field name="runDate" disabled component={Binder} binder={DateBinder} label="reorderpoint.selector.lastrundate"/>
                <Field name="publishedDate" disabled component={Binder} binder={DateBinder} label="reorderpoint.selector.lastpublisheddate"/>
            </GridLayout>

            <GridLayout numCols={6} data-cy="algo-params">
                <Field name="algoQuantity" disabled component={Binder} binder={TextBinder} label="reorderpoint.selector.algorop" formatter={props.formModel.values.formulaFieldsFormatter}/>
                <Text label={'='} colDim={0.5}/>
                <Field name="movingAverage" disabled component={Binder} binder={TextBinder} label="reorderpoint.selector.averagedailydemand" formatter={props.formModel.values.formulaFieldsFormatter}/>
                <Text label={'X'} colDim={0.5}/>
                <Field name="leadTime" disabled component={Binder} binder={TextBinder} label="reorderpoint.selector.leadTime" formatter={props.formModel.values.formulaFieldsFormatter}/>
                <Text label={'+'} colDim={0.5}/>
                <Field name="safetyStock" disabled component={Binder} binder={TextBinder} label="reorderpoint.selector.safetyStock" formatter={props.formModel.values.formulaFieldsFormatter}/>
                <Field name="quantity" disabled component={Binder} binder={TextBinder} label="reorderpoint.selector.quantity" formatter={formatters.round}/>
            </GridLayout>
        </Fragment>
    );
}
