import { List } from 'immutable';
import { createSelector } from 'reselect';
import { RequestId } from '../utils/requests';
import { getCachedRequest as getCommonRequest } from '@pearlchain/component-lib-common';

export const KEY_FORM = 'form';
export const KEY_UPDATE_FORECAST_DETAILS = 'update-forecast-details';
export const KEY_PARAMS = 'params';
export const KEY_REQUESTS = 'requests';
export const KEY_DATA = 'data';
export const KEY_FETCHING = 'fetching';
export const KEY_FETCHERS = 'fetchers';
export const KEY_GENERAL_REMARK = 'general-remark';
export const KEY_VISIBLE = 'visible';
export const KEY_SIM_INSTANCES = 'simulation-instances';
export const KEY_SIM_CONTEXT_UID = 'simulation-context-id';

export const Path = {
    REQUESTS: List([KEY_REQUESTS]),
    PERSISTENT_PARAMS: List([KEY_PARAMS]),
    SIMULATION_INSTANCES: List([KEY_SIM_INSTANCES]),
    SIMULATION_CONTEXT_UID: List([KEY_PARAMS, KEY_SIM_CONTEXT_UID]),
    request(requestId) {
        return this.REQUESTS.concat([requestId]);
    },
    apiData(requestId) {
        return this.request(requestId).push(KEY_DATA);
    },
    apiFetching(requestId) {
        return this.request(requestId).push(KEY_FETCHING);
    },
    persistentParam(key) {
        return this.PERSISTENT_PARAMS.push(key);
    },
    generalRemark(formId) {
        return List([KEY_GENERAL_REMARK, formId]);
    },
    isGeneralRemarkVisible(formId) {
        return this.generalRemark(formId).push(KEY_VISIBLE);
    }
}

export function getRequest(state, requestId) {
    return state.common.getIn(Path.request(requestId));
}

export function getApiData(state, requestId) {
    return state.common.getIn(Path.apiData(requestId));
}

export function getCommonApiData(state, requestId) {
    const request = getCommonRequest(state, requestId);
    return request ? request.getIn(List([KEY_DATA])) : undefined;
}

export function getUpdateForecastDetailsMasterFormData(state) {
    return state.masterForm.getIn([KEY_FORM, KEY_UPDATE_FORECAST_DETAILS, KEY_DATA]);
}

export function getRequests(state) {
    return state.common.getIn(Path.REQUESTS);
}

export const isAnyRequestFetching = createSelector(getRequests, (requests) => requests ? !!requests.find((x) => x.get(KEY_FETCHING)) : false);

export function isRequestFetching(state, requestId) {
    return state.common.getIn(Path.apiFetching(requestId), false);
}

export function getAllPersistentParams(state) {
    return state.common.getIn(Path.PERSISTENT_PARAMS);
}

export function getPersistentParam(state, key) {
    return state.common.getIn(Path.persistentParam(key));
}

export function isGeneralRemarkVisible(state, formId) {
    return state.common.getIn(Path.isGeneralRemarkVisible(formId));
}

export function getSimulationInstances(state) {
    return getCommonApiData(state, RequestId.FIND_SIMULATION_INSTANCES);
}

export function getSimulationContextUid(state) {
    return getPersistentParam(state, KEY_SIM_CONTEXT_UID);
}

export function isWorkingInLive(state) {
    return !getSimulationContextUid(state);
}

export const getCurrentSimulationInstance = createSelector(getSimulationInstances, getSimulationContextUid,
    (instances, contextId) => {
        if(!instances || contextId == null) return undefined;
        return instances.find((instance) => instance.get('uniqueIdentifier') === contextId);
    });

export function getRequestData(state, requestId) {
    return state.common.getIn(Path.request(requestId));
}
