import React from 'react';
import PropTypes from 'prop-types';
import GridLayout from '../common/layout/GridLayout';
import { Nav, NavItem, NavLink, TabContent, TabPane, Tab } from 'reactstrap';
import { generateFields } from './formFieldGenerator';

export default class SimulationParameters extends React.Component {

    constructor(props) {
        super(props);
        this.state = { activeTab: '1' };
    }

    toggle(activeTab) {
        if (this.state.activeTab !== activeTab) {
            this.setState({ activeTab });
        }
    }

    render() {
        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === '1' ? 'active' : ''}
                            onClick={() => { this.toggle('1'); }}>
                            Loading Parameters
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === '2' ? 'active' : ''}
                            onClick={() => { this.toggle('2'); }}>
                            Calculations
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === '3' ? 'active' : ''}
                            onClick={() => { this.toggle('3'); }}>
                            Publishing parameters
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <GridLayout numCols={1}>
                            {this.props.formFields && generateFields(this.props.formFields, 'Loading', this.props.disabled)}
                        </GridLayout>
                    </TabPane>
                    <TabPane tabId="2">
                        <GridLayout numCols={1}>
                            {this.props.formFields && generateFields(this.props.formFields, 'Calculations', this.props.disabled)}
                        </GridLayout>
                    </TabPane>
                    <TabPane tabId="3">
                        <GridLayout numCols={1}>
                            {this.props.formFields && generateFields(this.props.formFields, 'Publishing', this.props.disabled)}
                        </GridLayout>
                    </TabPane>
                </TabContent>
            </div>);
    }
}

SimulationParameters.propTypes = {
    formFields: PropTypes.object,
    disabled: PropTypes.bool
}
