import { all, put, select, takeEvery } from 'redux-saga/effects';
import { success } from 'react-notification-system-redux';
import { submit } from 'redux-form';

import { StoreState } from '../../types/storeTypes';
import { GraphData } from './types';

import { RequestId } from '../utils/requests';
import ActionTypes, { clearData, fetchData, setData } from './actions';
import { takeApiResponse } from '../utils/sagaHelpers';
import { getData, isItemModified } from './selectors';
import { apiRequest } from '../common/commonActions';
import { performApiRequest } from '../common/commonSagas';
import { FORM_ID } from './helpers';
import _ from 'lodash';

export default function() {
    return all([
        takeEvery(ActionTypes.FETCH_DATA, handleFetchData),
        takeEvery(ActionTypes.SAVE_ALL_CHANGES, handleSaveAllChanges)
    ]);
}

function *handleFetchData(action: ReturnType<typeof fetchData>) {
    const [data, err] = yield* performApiRequest(RequestId.FORECAST_TIME_SERIES, [action.data]);
    if(err) return;
    yield put(setData(data));
}

function *handleSaveAllChanges() {
    const data: GraphData|undefined = yield select((state: StoreState) => getData(state.forecastDetails));
    if (data == null) return;

    const lines = [];
    for (let run of data.get('series')) {
        for (let d of run.get('data')) {
            if (isItemModified(d)) {
                lines.push({
                    uniqueIdentifier: d.get('uuid'),
                    remark: d.get('remark'),
                    quantity: d.get('quantity'),
                    version: d.get('version'),
                    groupUid: d.get('forecastGroupUniqueIdentifier')
                });
            }
        }
    }

    if (lines.length === 0) {
        console.error('Items were not changed');
        return;
    }

    const forecastGroupLinesArray = _.chain(lines)
        .groupBy('groupUid')
        .toPairs()
        .map(item => _.fromPairs(_.zip(['uniqueIdentifier', 'lines'], item)))
        .value();

    // perform api request
    yield put(apiRequest(RequestId.SAVE_FORECAST_LINES, forecastGroupLinesArray));
    yield put(clearData());

    // wait for request to complete
    const [_result, error] = yield* takeApiResponse(RequestId.SAVE_FORECAST_LINES);
    if(!error) { 
        yield put(success({ title: `[${lines.length}] Forecast lines updated` }));
    }

    // Re-submit the form, so the graph is loaded with the new data including updated versions
    yield put(submit(FORM_ID));
}

