import { Action } from 'redux';
import { Map } from 'immutable';

const ActionTypes = {
    UNMOUNT_FORM: 'vco.common.form.UNMOUNT_FORM',
    REFRESH_FORM: 'vco.common.form.REFRESH_FORM',
    SUBMIT_FORM: 'vco.common.form.SUBMIT_FORM',
    SET_FORM_DATA: 'vco.common.form.SET_FORM_DATA',
    SET_FORM_VALUES: 'vco.common.form.SET_FORM_VALUES',
    SET_FORM_SELECTION: 'vco.common.form.SET_FORM_SELECTION',
    MOUNT_FORM_GRID: 'vco.common.form.MOUNT_FORM_GRID',
    UNMOUNT_FORM_GRID: 'vco.common.form.UNMOUNT_FORM_GRID',
    SET_FORM_BUFFERED_DATA: 'vco.common.form.SET_FORM_BUFFERED_DATA',
    SET_FORM_GRID_SORTING: 'vco.common.form.SET_FORM_GRID_SORTING',
    FORM_API_REQUEST: 'vco.common.form.FORM_API_REQUEST',
    TRIGGER_FORM_EVENT: 'vco.common.form.PUSH_FORM_EVENT',
    MOUNT_EVENT_OBSERVER: 'vco.common.form.MOUNT_EVENT_OBSERVER',
    UNMOUNT_EVENT_OBSERVER: 'vco.common.form.UNMOUNT_EVENT_OBSERVER',
    CONSUME_EVENTS: 'vco.common.form.CONSUME_EVENTS',
    SHOW_FORM: 'vco.common.form.SHOW_FORM',
    HIDE_FORM: 'vco.common.form.HIDE_FORM',
    COMPLETE_FORM_PROVIDE_RESULT: 'vco.common.form.COMPLETE_FORM_PROVIDE_RESULT',
    SET_FORM_PROVIDED_PROPERTIES: 'vco.common.form.SET_FORM_PROVIDED_PROPERTIES',
    CLEAR_FORM_ACTIVE_PAGE: 'vco.common.form.CLEAR_FORM_ACTIVE_PAGE',
    SET_FORM_ACTIVE_PAGE: 'vco.common.form.SET_FORM_ACTIVE_PAGE',
    SET_FORM_SYNC_KEY: 'vco.common.form.SET_FORM_SYNC_KEY'
};

export default ActionTypes;

export function unmountForm(formId: string): UnmountFormAction {
    return { type: ActionTypes.UNMOUNT_FORM, formId };
}

export function setFormData(formId: string, data: any): SetFormDataAction {
    return { type: ActionTypes.SET_FORM_DATA, formId, data };
}

export function setFormValues(formId: string, values: any): SetFormValuesAction {
    return { type: ActionTypes.SET_FORM_VALUES, formId, values };
}

export function setFormSelection(formId: string, selection: any): SetFormSelectionAction {
    return { type: ActionTypes.SET_FORM_SELECTION, formId, selection }
}

export function refreshForm(formId: string): RefreshFormAction {
    return { type: ActionTypes.REFRESH_FORM, formId };
}

export function mountFormGrid(formId: string, gridId: string) {
    return { type: ActionTypes.MOUNT_FORM_GRID, formId, gridId };
}

export function unmountFormGrid(formId: string) {
    return { type: ActionTypes.UNMOUNT_FORM_GRID, formId };
}

export function setFormBufferedData(formId: string, newData: any, version: number|undefined): SetFormBufferedDataAction {
    return { type: ActionTypes.SET_FORM_BUFFERED_DATA, formId, newData, version };
}

export function setFormGridSorting(formId: string, sortColumns: any): SetFormGridSortingAction {
    return { type: ActionTypes.SET_FORM_GRID_SORTING, formId, sortColumns };
}

export function triggerFormEvent(formId: string, eventType: string, params: any): TriggerFormEventAction {
    return { type: ActionTypes.TRIGGER_FORM_EVENT, formId, eventType, params };
}

export function mountEventObserver(formId: string): MountEventObserverAction {
    return { type: ActionTypes.MOUNT_EVENT_OBSERVER, formId };
}

export function unmountEventObserver(formId: string): UnmountEventObserverAction {
    return { type: ActionTypes.UNMOUNT_EVENT_OBSERVER, formId };
}

export function consumeEvents(formId: string, offset: number): ConsumeEventsAction {
    return { type: ActionTypes.CONSUME_EVENTS, formId, offset };
}

export function showForm(formId: string, params?: any): ShowFormAction {
    return { type: ActionTypes.SHOW_FORM, formId, params };
}

export function hideForm(formId: string): HideFormAction {
    return { type: ActionTypes.HIDE_FORM, formId };
}

export function clearFormActivePage(formId: string): ClearActivePageAction {
    return { type: ActionTypes.CLEAR_FORM_ACTIVE_PAGE, formId };
}

export function setFormActivePage(formId: string, page: number, params: any): SetActivePageAction {
    return { type: ActionTypes.SET_FORM_ACTIVE_PAGE, formId, page, params };
}

export function completeFormProvideResult(formId: string, binderFormId: string, binderFormFieldValueMap: Map<any, any>) {
    return { type: ActionTypes.COMPLETE_FORM_PROVIDE_RESULT, formId, binderFormId, binderFormFieldValueMap };
}

export function setFormSyncKey(formId: string, syncKey: string|undefined): SetFormSyncKeyAction {
    return { type: ActionTypes.SET_FORM_SYNC_KEY, formId, syncKey };
}

export interface UnmountFormAction extends Action {
    formId: string
}

export interface RefreshFormAction extends Action {
    formId: string
}

export interface SetFormDataAction extends Action {
    formId: string,
    data: any
}

export interface SetFormValuesAction extends Action {
    formId: string,
    values: any
}

export interface SetFormSelectionAction extends Action {
    formId: string,
    selection: any
}

export interface SetFormSelectionAction extends Action {
    formId: string,
    selection: any
}

export interface SetFormBufferedDataAction extends Action {
    formId: string,
    newData: any,
    version: number|undefined
}

export interface SetFormGridSortingAction extends Action {
    formId: string,
    sortColumns: any
}

export interface ShowFormAction extends Action {
    formId: string,
    params: any
}

export interface HideFormAction extends Action {
    formId: string,
}

export interface TriggerFormEventAction extends Action {
    formId: string,
    eventType: string,
    params: any
}

export interface MountEventObserverAction extends Action {
    formId: string,
}

export interface UnmountEventObserverAction extends Action {
    formId: string,
}

export interface ConsumeEventsAction extends Action {
    formId: string,
    offset: number
}

export interface ClearActivePageAction extends Action {
    formId: string,
}

export interface SetActivePageAction extends Action {
    formId: string,
    page: number,
    params: any
}

export interface SetFormSyncKeyAction extends Action {
    formId: string,
    syncKey: string|undefined
}
