import { TextBinder } from '@pearlchain/component-lib-ui';

import { apiRequest } from '../../../common/commonActions';
import { RequestId } from '../../../utils/requests';
import { getApiData, isRequestFetching } from '../../../common/commonSelectors';
import { refreshForm, showForm } from '../../../common/masterform/formActions';
import { EXTS } from '../../../utils/powergrid/powergridHelpers';
import SimTypeBinder from '../../../common/binder/SimTypeBinder';
import newSimulationFormConfig from '../new/formConfig';
import detailsFormConfig from '../details/detailsFormConfig';
import { formatters } from '../../../utils/powergrid/formatters';
import { columns, COLUMNS_META } from './columns';
import { createTranslateDecorator } from '../../../utils/datamodel/TranslateGridDataDecorator';
import { CommandConfig, FormConfig, FormModel } from '../../../common/masterform/types/formConfigTypes';
import { SimulationTemplateOverviewResponse } from '../../../utils/remoting/types/simulationTemplateRequestTypes';
import { deleteTemplateByUID } from '../simulationTemplateActions';

type FM = FormModel<SimulationTemplateOverviewResponse, Values, {}>;

const NEW_SIMULATION_TEMPLATE_FORM_ID = 'new-sim-template';
const UPDATE_SIMULATION_TEMPLATE_FORM_ID = 'update-sim-template';

interface Values {
    name: string,
    simulationType: string
}

function nothingSelected(formModel: FM) {
    return !formModel.selection;
}

const newSimulationTemplateCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.new',
    action: () => showForm(NEW_SIMULATION_TEMPLATE_FORM_ID)
};

const updateSimulationTemplateCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.update',
    disabled: nothingSelected,
    action: () => showForm(UPDATE_SIMULATION_TEMPLATE_FORM_ID)
};

const deleteSimulationTemplateCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.delete',
    disabled: nothingSelected,
    action: formModel => deleteTemplateByUID(formModel.selection.get('uniqueIdentifier'), formModel.formId)
};

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            data: (state) => getApiData(state, RequestId.FIND_SIMULATION_TEMPLATES)
        },
        busy: (state) => isRequestFetching(state, RequestId.FIND_SIMULATION_TEMPLATES),
        fetchData: (formModel) => apiRequest(RequestId.FIND_SIMULATION_TEMPLATES, formModel.bufferedValues)
    },
    selector: {
        icon: { name: 'copy', showBusy: false },
        layout: {
            component: 'grid',
            numCols: { xs: 1, sm: 2, md: 2, lg: 2 },
        },            
        fields: {
            name: { component: TextBinder, label: 'simulationTemplates.selector.name' },
            simulationType: { component: SimTypeBinder, label: 'common.selector.type' }
        },
        commands: [
            { label: 'common.selector.actions.search',
            type: 'primary',
            group: -1,
            action: (formModel) => refreshForm(formModel.formId),
            datacy: 'search-simulationtemplate-btn' },
            updateSimulationTemplateCommand,
            newSimulationTemplateCommand,
            deleteSimulationTemplateCommand
        ]
    },
    grid: {
        dataModel: {
            columns,
            rowIdField: 'id'
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META })
        ],
        extensions: {
            [EXTS.SELECTION]: true,
            [EXTS.FORMATTING]: {
                formatters
            },
            [EXTS.RESIZING]: true
        }
    },
    children: {
        [NEW_SIMULATION_TEMPLATE_FORM_ID]: {
            component: 'modal',
            title: 'simulationTemplates.new',
            form: { config: newSimulationFormConfig }
        },
        [UPDATE_SIMULATION_TEMPLATE_FORM_ID]: {
            component: 'modal',
            title: 'simulationTemplates.update',
            form: { config: detailsFormConfig }
        }
    }
}

export default formConfig;
