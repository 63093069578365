import { all, call, put, race, select, take, takeEvery } from 'redux-saga/effects';
import { error, success } from 'react-notification-system-redux';

import ActionTypes from '../forecastingActions';
import { saveForecastGroupLines } from '../../utils/remoting/api';
import { hideForm, refreshForm, setFormBufferedData, setFormData } from '../../common/masterform/formActions';
import CommonActionTypes, { showGeneralRemark } from '../../common/commonActions';
import { modifiedLinesMissingRemarks, withGeneralRemark } from '../forecastingHelpers';
import { withFormId } from '../../utils/sagaHelpers';
import { isGeneralRemarkVisible } from '../../common/commonSelectors';
import { createRequestBuilderFromState } from '../../utils/remoting/vcoRequestBuilder';
import { generateForecastLines, getDataDiffForGeneralRemark } from './detailsEntryHelpers';

export default function() {
    return all([
        takeEvery(ActionTypes.DETAILS_SAVE_FORECAST_LINES, handleSaveLines),
        takeEvery(ActionTypes.DETAILS_GENERATE_FORECAST_LINES, handleGenerateForecastLines)
    ]);
}

function *handleGenerateForecastLines({formModel}) {
    yield put(setFormData(formModel.formId, generateForecastLines(formModel)));
}

function *handleSaveLines({ formModel }) {
    const { formId, data, bufferedData } = formModel;

    const generalRemarkVisible = yield select((state) => isGeneralRemarkVisible(state, formId));
    if(generalRemarkVisible) return;
     
    // calculate which lines were modified
    let dataDiff = getDataDiffForGeneralRemark(data, bufferedData);
    if(modifiedLinesMissingRemarks(dataDiff)) {
        // some modified lines were missing remarks
        yield* handleGeneralRemark(dataDiff, formModel);
 
    } else {
        // save forecast lines
        yield* saveForecastLines(dataDiff, formModel);
    }
}

function *handleGeneralRemark(dataDiff, formModel) {
    const formId = formModel.formId;
    yield put(showGeneralRemark(formId));

    // wait for the general remark to be confirmed
    const { ignored, confirmed } = yield race({
        ignored: take(withFormId(CommonActionTypes.IGNORE_GENERAL_REMARK, formId)),
        confirmed: take(withFormId(CommonActionTypes.CONFIRM_GENERAL_REMARK, formId)),
        cancelled: take(withFormId(CommonActionTypes.CANCEL_GENERAL_REMARK, formId))
    });

    if(ignored) {
        yield* saveForecastLines(dataDiff, formModel);

    } else if(confirmed) {
        const { remark } = confirmed;
        const data = formModel.bufferedData;
        const version = formModel.version;

        const dataDiffUpdated = withGeneralRemark(dataDiff, remark);
        const dataWithRemarks = dataDiffUpdated.reduce((data, diff) => data.set(diff.get('index'), diff.get('next')), data);
        yield put(setFormBufferedData(formId, dataWithRemarks, version));
    }
}

function *saveForecastLines(dataDiff, formModel) {
    try {
        const requestBuilder = yield select(createRequestBuilderFromState);
        // build the back-end request

        const values = formModel.values;

        const parameters = {
            uniqueIdentifier: values.uniqueIdentifier,
            productNo: values.productNo,
            customerNo: values.customerNo,
            company: values.company,
            plant: values.plant,
            lines: dataDiffToRequest(formModel.bufferedData)
        };

        yield call(saveForecastGroupLines, requestBuilder, [parameters]);

        // refresh the master-form and hide the detail dialog
        yield put(success({ title: 'Forecast lines updated' }));
        yield put(refreshForm(formModel.parent.formId));

        // hiding the detail form has to be the last action we perform,
        // this saga will be cancelled immediately after
        yield put(hideForm(formModel.formId));

    } catch(err) {
        console.error(err);
        yield put(error(err.message));
    }
}

function dataDiffToRequest(dataDiff) {
    return dataDiff
        .map((data) => {
            return {
                uniqueIdentifier: data.get('uniqueIdentifier'),
                remark: data.get('remark'),
                quantity: data.get('quantity'),
                fixed: data.get('fixed'),
                period: data.get('period'),
                periodType: data.get('periodType'),
                year: data.get('year'),
                version: data.get('version')
            }
        }).toArray();
}
