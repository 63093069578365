import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import { List, Record } from 'immutable';
import moment from 'moment';
import { Moment } from 'moment';

import { GraphData, GraphRunData, GraphDataPoint, GraphRunType } from '../types';
import { getLineColor, translate } from './graphHelpers';
import { SelectionState } from '../../../types/storeTypes';

const PADDING_LEFT = 15;
const PADDING_BOTTOM = 30;
const PADDING_TOP = 15;
const PADDING_RIGHT = 15;
const POINT_RADIUS = 7;
const POINT_DRAG_RADIUS = 12;

const LEGEND_ITEM_WIDTH = 35;
const LEGEND_ITEM_HEIGHT = 17;
const LEGEND_ITEM_GAP = 3;
const LEGEND_TEXT_SIZE = '15px';

const TT_EDGE_DISTANCE_H = 100;
const TT_EDGE_DISTANCE_V = 100;

export interface Callbacks {
    setItemQuantity(runIdx: number, pointIdx: number, quantity: number): void;
    setSelectedItem(runIdx: number, pointIdx: number): void;
    clearSelectedItem(): void;
};

interface DragState {
    startY: number,
    dy: number
}

export interface Datum {
    run: GraphRunData,
    point: GraphDataPoint,
    i: number,
    j: number
}

/** Helpers */

function isMultiplant(runs: List<GraphRunData>) {
    return {
        [GraphRunType.SalesOrders]: isMultiplantByType(runs, GraphRunType.SalesOrders),
        [GraphRunType.Forecast]: isMultiplantByType(runs, GraphRunType.Forecast),
        [GraphRunType.ForecastActive]: isMultiplantByType(runs, GraphRunType.ForecastActive),
    }
}

function isMultiplantByType(runs: List<GraphRunData>, type: GraphRunType) {
    let plant;
    for(let run of runs) {
        if(plant != null && run.get('plant') !== plant) return true;
        else plant = run.get('plant');
    }
    return false;
}

function maxQuantity(runs: List<GraphRunData>): number {
    const maxQuantity = compareDataPointsAcrossRuns<number>(runs, (d) => d.get('quantity'), (q1, q2) => q2 > q1);
    return maxQuantity == null ? 100 : maxQuantity;
}

function minQuantity(runs: List<GraphRunData>): number {
    const minQuantity = compareDataPointsAcrossRuns<number>(runs, (d) => d.get('quantity'), (q1, q2) => q2 < q1);
    return minQuantity == null ? 0 : minQuantity;
}

function minStartDate(runs: List<GraphRunData>): Date {
    const minStart = compareDataPointsAcrossRuns<Moment>(runs, (d) => moment(d.get('startDate')), (q1, q2) => q2.isBefore(q1));
    return minStart == null ? new Date() : minStart.toDate();
}

function maxStartDate(runs: List<GraphRunData>): Date {
    const maxStart = compareDataPointsAcrossRuns<Moment>(runs, (d) => moment(d.get('startDate')), (q1, q2) => q2.isAfter(q1));
    return maxStart == null ? new Date() : maxStart.toDate();
}

function compareDataPointsAcrossRuns<T>(runs: List<GraphRunData>, selector: (pt: GraphDataPoint) => T, comparator: (a: T, b: T) => boolean): T|undefined {
    let value: T|undefined;
    for(let run of runs) {
        const data = run.get('data');
        for(let d of data) {
            const quantity = selector(d);
            if(value == null || comparator(value, quantity)) 
                value = quantity;
        }
    }

    return value!;
}

export function createGraph(el: HTMLElement, callbacks: Callbacks) {
    const container = d3.select(el);
    const vis = container.select('.graph-svg')
        .on('click', handleCanvasSelected);

    let hoveredRun: string|undefined,
        visibleRuns: Set<string> = new Set(),
        dataRuns: List<GraphRunData>,
        dataForecastDate!: Moment,
        selection: Record<SelectionState>|undefined,
        canvasWidth!: number,
        canvasHeight!: number, 
        scaleX!: d3.ScaleTime<number, number>,
        scaleY!: d3.ScaleLinear<number, number>,
        multiPlant!: { [T in GraphRunType]: boolean },
        offsetX!: number;

    const dragState = new Map<string, DragState>();

    const tip = (d3Tip as any)().attr('class', 'd3-tip').html(selectTooltipHtmlContent).direction(selectTooltipDirection);
    vis.call(tip);


    /** Selectors */

    function selectTooltipDirection(this: SVGGraphicsElement) {
        const canvasBounds = el.getBoundingClientRect();
        const bounds = this.getBoundingClientRect();
        let dirH = '', dirV = 'n';

        if (bounds.left - canvasBounds.left < TT_EDGE_DISTANCE_H) {
            dirH = 'e';
        } else if(canvasBounds.right - bounds.right < TT_EDGE_DISTANCE_H) {
            dirH = 'w';
        }

        if (bounds.top - canvasBounds.top < TT_EDGE_DISTANCE_V) {
            dirV = 's';
        }

        return dirV + dirH;
    }

    function selectDataPointKey(d: Datum|GraphDataPoint): string {
        return selectRunKey((d as Datum).run) + '-' + (d as Datum).j;
    }    

    function selectPointX(d: Datum): number {
        return scaleX(moment(d.point.get('startDate')).toDate());
    }

    function selectPointY(d: Datum): number {
        const ds = dragState.get(selectDataPointKey(d));
        return scaleY(d.point.get('quantity')) + (ds == null ? 0 : ds.dy);
    }
    
    function selectRunKey(d: GraphRunData): string {
        const type = d.get('type'),
              productId = d.get('productId'),
              plant = d.get('plant'),
              runId = d.get('runId');
    
        const parts = [type, productId, plant];
        if(runId != null)
            parts.push(runId);
    
        return parts.join('-');
    }

    function selectForecastRunType(d: GraphRunData) {
        return d.get('type') === GraphRunType.Forecast || d.get('type') === GraphRunType.ForecastActive;
    }

    function selectLineId(d: GraphRunData): string {
        return 'line-' + selectRunKey(d);
    }

    function selectPointSelected(d: Datum): boolean {
        return selection != null && selection.get('runIdx') === d.i && selection.get('pointIdx') === d.j;
    }

    function selectPointTransform(this: SVGGElement, d: Datum): string {
        const x = selectPointX(d);
        const y = selectPointY(d);
        return translate(x, y);
    }

    function selectLinePathAttr(run: GraphRunData, i: number): string {
        const pathNodes = [];

        const data = run.get('data');
        for(let j = 0, n = data.size; j < n; ++j) {
            const point = data.get(j)!;
            const datum: Datum = { i, j, point, run };

            const x = selectPointX(datum);
            const y = selectPointY(datum);

            if(j === 0) {
                pathNodes.push(['M', x, y]);
            } else {
                pathNodes.push(['L', x, y]);
            }   
        }

        return pathNodes.map(x => x.join(' ')).join(' ')
    }

    function selectVisibilityState(key: string) {
        if(!visibleRuns.size || visibleRuns.has(key)) {
            return hoveredRun == null || hoveredRun === key ? 'visible' : 'preview';
        } else {
            return hoveredRun === key ? 'visible' : 'hidden';
        }
    }

    function selectLineOpacity(d: GraphRunData) {
        return selectVisibilityState(selectRunKey(d)) === 'preview' ? 0.25 : null;
    }
    
    function selectLineVisibility(d: GraphRunData) {
        return selectVisibilityState(selectRunKey(d)) === 'hidden' ? 'hidden' : null;
    }

    function selectLegendOpacity(d: GraphRunData) {
        const key = selectRunKey(d);
        return (hoveredRun == null && !visibleRuns.size) || visibleRuns.has(key) || hoveredRun === key ? null : 0.25;
    }
    
    function selectLegendTransform(d: GraphRunData, i: number): string {
        const x = canvasWidth - PADDING_RIGHT;
        const y = PADDING_TOP + i * (LEGEND_ITEM_HEIGHT + LEGEND_ITEM_GAP);
        return translate(x, y);
    }

    function selectLegendText(d: GraphRunData): string {
        const type = d.get('type');
        const mp = multiPlant[type];
        const multiplantPrefix = mp ? '(' + d.get('plant') + ') ' : '';

        if(type === GraphRunType.SalesOrders) {
            return multiplantPrefix + 'Sales Orders';

        } else if(type === GraphRunType.ForecastActive) {
            return multiplantPrefix + 'Active Forecast'; 

        } else {
            const runDate = moment(d.get('runDate'));
            return multiplantPrefix + runDate.format('DD-MM-YYYY');
        }
    }

    function selectDraggable(d: Datum): boolean {
        return d.run.get('type') === GraphRunType.ForecastActive && moment(d.point.get('startDate')).isSameOrAfter(dataForecastDate);
    }

    function selectTooltipHtmlContent(d: Datum) {
        const plant = d.run.get('plant');
        let quantity = d.point.get('quantity');

        const ds = dragState.get(selectDataPointKey(d));
        if(ds != null)
            quantity = Math.round(scaleY.invert(scaleY(quantity) + ds.dy));

        const date = moment(d.point.get('startDate')).format('DD-MM-YYYY');

        let historicalPeriod;
        if (d.point.get('amountOfHistoricalPeriods') !== null && d.point.get('historicalElementPeriod') !== null) {
            historicalPeriod = d.point.get('historicalElementPeriod') + '/' + d.point.get('amountOfHistoricalPeriods');
        }
        else{
            historicalPeriod = '---'
        }

        let plannedPeriod;
        if (d.point.get('amountOfPlannedPeriods') !== null && d.point.get('plannedElementPeriod') !== null) {
            plannedPeriod = d.point.get('plannedElementPeriod') + '/' + d.point.get('amountOfPlannedPeriods');
        }
        else{
            plannedPeriod = '---'
        }
        return `<div>Plant: ${plant}</div><div>Quantity: ${quantity}</div><div>Date: ${date}</div><div>Historical Period: ${historicalPeriod}</div><div>Planned Period: ${plannedPeriod}</div>`;
    }
    
    function calculateScaleX(width: number, offsetX: number, runs: List<GraphRunData>): d3.ScaleTime<number, number> {
        const minX = minStartDate(runs);
        const maxX = maxStartDate(runs);

        return d3.scaleTime()
            .domain([minX, maxX])
            .range([offsetX + PADDING_LEFT, width - PADDING_RIGHT]);
    }


    /** Renderers */

    function calculateScaleY(height: number, runs: List<GraphRunData>): d3.ScaleLinear<number, number> {
        const minY = Math.min(0, minQuantity(runs));
        const maxY = maxQuantity(runs);

        return d3.scaleLinear()
            .domain([minY, maxY])
            .range([height - PADDING_BOTTOM, PADDING_TOP]);
    }

    function drawAxisLeft() {
        scaleY = calculateScaleY(canvasHeight, dataRuns);
        let axisLeft = vis.select<SVGGElement>('#axis-left');
        if (!axisLeft.size()) {
            axisLeft = vis
                .append('g')
                    .attr('id', 'axis-left')
                    .call(updateAxisLeft());

        } else {
            axisLeft.transition().call(updateAxisLeft());
        }
        
        offsetX = axisLeft.node()!.getBoundingClientRect().width;
        axisLeft.attr('transform', translate(offsetX + PADDING_LEFT, 0));
    }

    function updateAxisLeft() {
        return d3.axisLeft(scaleY);
    }

    function drawAxisBottom() {
        scaleX = calculateScaleX(canvasWidth, offsetX!, dataRuns);
        let axisBottom = vis.select<SVGGElement>('#axis-bottom');
        if (!axisBottom.size()) {
            axisBottom = vis
                    .append('g')
                        .attr('id', 'axis-bottom')
                        .attr('transform', translate(0, canvasHeight - PADDING_BOTTOM))
                        .call(updateAxisBottom());

        } else {
            axisBottom.transition().call(updateAxisBottom());
        }
    }

    function updateAxisBottom() {
        return d3.axisBottom(scaleX);
    }

    function drawGridLinesH() {
        const gridH = vis.select<SVGGElement>('#grid-h');
        if(!gridH.size()) {
            vis.append('g')
                .attr('id', 'grid-h')
                .attr('transform', translate(PADDING_LEFT + offsetX, 0))
                .call(updateGridLinesH());

        } else {
            gridH.transition().call(updateGridLinesH())
        }
    }

    function updateGridLinesH() {
        return d3.axisLeft(scaleY)
            .tickSize(PADDING_LEFT + PADDING_RIGHT + offsetX - canvasWidth).tickFormat('' as any)
    }

    function drawGridLinesV() {
        const gridV = vis.select<SVGGElement>('#grid-v');
        if(!gridV.size()) {
            vis.append('g')
                .attr('id', 'grid-v')
                .attr('transform', translate(0, canvasHeight - PADDING_BOTTOM))
                .call(updateGridLinesV());

        } else {
            gridV.transition().call(updateGridLinesV());
        }
    }

    function updateGridLinesV() {
        return d3.axisBottom(scaleX)
            .tickSize(PADDING_BOTTOM + PADDING_TOP - canvasHeight).tickFormat('' as any)
    }

    function drawSeries() {
        const series = vis.selectAll<SVGGElement, GraphRunData>('.graph-series')
            .data(dataRuns.toArray());

        series.exit().remove();

        series
            .call(updateLines)
            .enter()
            .append('g')
                .attr('class', (d) => 'graph-series graph-series-' + d.get('type'))
                .call(createLines)
            .merge(series)
                .attr('visibility', selectLineVisibility)
                .attr('opacity', selectLineOpacity)
                .call(updateGraphPoints);
    }

    function createLines(sel: d3.Selection<SVGGElement, GraphRunData, any, any>) {
        sel.append('path')
            .attr('id', selectLineId)
            .attr('class', 'graph-line')
            .classed('forecast', selectForecastRunType)
            .attr('d', selectLinePathAttr)
            .style('stroke', (d, i) => getLineColor(i));
    }

    function updateLines(sel: d3.Selection<SVGGElement, GraphRunData, any, any>) {
        sel.select('.graph-line')
            .transition().attr('d', selectLinePathAttr);
    }

    function updateGraphPoints(sel: d3.Selection<SVGGElement, GraphRunData, any, any>) {
        const points = sel.selectAll<SVGGElement, GraphDataPoint>('.graph-point')
            .data((run, i) => run.get('data').toArray().map((point, j) => ({ point, run, i, j })), selectDataPointKey);

        points.exit().remove();

        points
            .call(pts => pts.transition().attr('transform', selectPointTransform))
            .enter()
            .append('g')
                .attr('class', 'graph-point')
                .attr('transform', selectPointTransform)
                .call(sel => sel
                    .append('circle')
                    .attr('class', 'graph-point-marker')
                    .attr('r', POINT_RADIUS)
                    .style('fill', (d) => getLineColor(d.i)))
                .on('mouseover', handleOverPt)
                .on('mouseout', handleOutPt)
                .on('click', handleSelectPt)
                .call(sel => sel
                    .filter(selectDraggable)
                    .call(d3.drag<SVGGElement, Datum>()
                        .on('start', handleDragStart)
                        .on('drag', handleDrag)
                        .on('end', handleDragEnd)))
            .merge(points)
                .classed('selected', selectPointSelected);
    }

    function handleOverPt(this: SVGGElement, d: Datum) {
        d3.select(this).datum(d)
            .each(tip.show)
            .append('circle')
                .attr('class', 'graph-point-overlay')
                .attr('r', POINT_DRAG_RADIUS);
    }

    function handleOutPt(this: SVGGElement, d: Datum) {
        d3.select(this).datum(d)
            .each(tip.hide)
            .select('.graph-point-overlay')
                .remove();
    }

    function handleSelectPt(d: Datum) {
        d3.event.stopPropagation();
        callbacks.setSelectedItem(d.i, d.j);
    }

    function handleCanvasSelected() {
        callbacks.clearSelectedItem();
    }

    function handleDragStart(this: SVGGElement, d: Datum) {
        const key = selectDataPointKey(d);
        dragState.set(key, { startY: d3.event.y, dy: 0 });
    }

    function handleDrag(this: SVGGElement, d: Datum) {
        const key = selectDataPointKey(d);
        const ds = dragState.get(key);

        if (ds != null) {
            ds.dy = d3.event.y - ds.startY;
            d3.select(this).datum(d).attr('transform', selectPointTransform);
            d3.select('#' + selectLineId(d.run)).datum(d.run).attr('d', selectLinePathAttr);
        }
    }

    function handleDragEnd(this: SVGGElement, d: Datum) {
        const key = selectDataPointKey(d);
        d3.select(this).attr('r', POINT_RADIUS).classed('dragged', false);
        const ds = dragState.get(key);

        if (ds != null) {
            dragState.delete(key);
            const quantity = Math.round(scaleY.invert(scaleY(d.point.get('quantity')) + ds.dy));
            
            if(quantity !== 0) {
                callbacks.setItemQuantity(d.i, d.j, quantity);
            }
        }
    }

    function drawLegend() {
        const legend = vis.selectAll<SVGGElement, GraphRunData>('.legend-item')
            .data(dataRuns.toArray());

        legend.exit().remove();

        legend
            .enter()
            .append('g')
                .attr('class', 'legend-item')
                .call(sel => sel.append('rect')
                    .style('fill', (d, i) => getLineColor(i))
                    .attr('x', -LEGEND_ITEM_WIDTH)
                    .attr('width', LEGEND_ITEM_WIDTH)
                    .attr('height', LEGEND_ITEM_HEIGHT)
                )
                .call(sel => sel.append('text')
                    .attr('x', -(LEGEND_ITEM_WIDTH + 10))
                    .attr('y', 13)
                    .attr('font-size', LEGEND_TEXT_SIZE)
                    .attr('fill', 'white')
                    .attr('text-anchor', 'end')
                )
            .merge(legend)
                .attr('opacity', selectLegendOpacity)
                .attr('transform', selectLegendTransform)
                .on('mouseover', handleLegendMouseOver)
                .on('mouseout', handleLegendMouseOut)
                .on('click', handleLegendClick)
                .select('text')
                    .text(selectLegendText)
    }

    function handleLegendMouseOver(d: GraphRunData) {
        hoveredRun = selectRunKey(d);
        redraw();
    }

    function handleLegendMouseOut() {
        hoveredRun = undefined;
        redraw();
    }

    function handleLegendClick(d: GraphRunData) {
        const key = selectRunKey(d);
        hoveredRun = undefined;
        if(visibleRuns.has(key)) {
            visibleRuns.delete(key);
        } else {
            visibleRuns.add(key);
        }

        redraw();
    }

    function redraw() {
        drawSeries();
        drawLegend();
    }

    return function(data: GraphData, sel: Record<SelectionState>|undefined) {
        dataRuns = data.get('series');
        dataForecastDate = moment(data.get('forecastDate'));
        selection = sel;

        hoveredRun = undefined;
        visibleRuns.clear();
        ({ width: canvasWidth, height: canvasHeight } = el.getBoundingClientRect());
        multiPlant = isMultiplant(dataRuns);

        drawAxisLeft();
        drawGridLinesH();

        drawAxisBottom();
        drawGridLinesV();

        redraw();
    }
}
