import SelectorForm from './SimulationTemplateFormSelector';
import { requestInDetailRefreshMaster } from '../../../common/commonActions';
import { RequestId } from '../../../utils/requests';
import { refreshForm } from '../../../common/masterform/formActions';
import { FormConfig, CommandConfig, FormModel } from '../../../common/masterform/types/formConfigTypes';
import { SimulationTemplateOverviewResponse } from '../../../utils/remoting/types/simulationTemplateRequestTypes';
import { Record } from 'immutable';
import { StoreState, SimulationTemplateState } from '../../../../types/storeTypes';

type FM = FormModel<SimulationTemplateOverviewResponse, any, {}>

const saveCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.save',
    disabled: false,
    action: (formModel) => refreshForm(formModel.formId)
}
            
const detailsFormConfig: FormConfig<FM> = {
        form: {
            model: {
                values: (state: StoreState, formId, params, select) => select(state.simulationTemplate, params.parent)(getDefaultValues)
            },
            fetchOnMount: false,
            fetchData: (formModel) => requestInDetailRefreshMaster(formModel, RequestId.UPDATE_SIMULATION_TEMPLATE, 'Simulation template updated')
        },
        selector: {
            layout: {
                component: SelectorForm
            },
            enableReinitialize: true,
            commands : [
                saveCommand
            ],
            readOnly: true
        }    
};

function getDefaultValues(simulationTemplate: Record<SimulationTemplateState>, parent: FM) {
    const selectedTemplate = parent.selection.toJS();
    const initialValues = simulationTemplate && simulationTemplate.get('initialValues');
    if (initialValues) {
        let fields = Object.keys(selectedTemplate).reduce((obj: SimulationTemplateOverviewResponse, key) => {
            if (initialValues.hasOwnProperty(key)) {
                obj[key] = initialValues[key];
                return obj; 
            } else {
                obj[key] = selectedTemplate[key];
                return obj;
            }                
        }, <SimulationTemplateOverviewResponse>{});
        return fields;
    } else {
        return parent.selection.toJS();
    }
    return {}
}    

export default detailsFormConfig;

