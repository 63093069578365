import * as Imm from 'immutable';
import { formatPeriodMoment } from '../../utils/powergrid/formatters';
import { copyColumnKeys } from '../../utils/powergrid/columnHelpers';
import { constructMomentFromDate } from '../../utils/formattingHelpers';
import { COLUMN_TO_BE_PUBLISHED, columns as baseColumns } from './columns';
import { DefaultGridDataModel } from '../../common/masterform/grid/DefaultGridDataModel';

export function createDataModel(data, bufferedData) {
    const {columns, rowsByUuid} = getGridData(bufferedData || data);

    return new DefaultGridDataModel({
        columns,
        rowsByUuid,
        getId: (dataItem) => dataItem.get('uniqueIdentifier'),
        getRow(dataItem) {
            return this.rowsByUuid.get(dataItem.get('uniqueIdentifier'))
        },
        shouldApplyClasses(id, column) {
            return column.key > baseColumns.size;
        },
        getClass(record, column) {
            if (column.key < baseColumns.size) return;

            const value = record[column.key];
            if (value != null && value.algoQuantity != null && value.quantity != value.algoQuantity) {
                return 'pg-cell-modified';
            }
        },
        modifyDataItem(columnIdx, value, dataItem) {
            const column = this.columns.get(columnIdx);
            if (column && column.get('_key') === 'toBePublished') {
                return dataItem.set('toBePublished', value);
            }
        }
    }, data, bufferedData);
}

/**
 * Returns the rows and columns for the forecast overview grid.
 */
export function getGridData(forecastGroups) {
    // no forecast-groups. Return defaults
    if(forecastGroups == null)
        return { rowsByUuid: Imm.Map(), columns: baseColumns };

    const [columns, columnsLookup] = getColumns(forecastGroups);

    const rowsByUuid = Imm.Map().withMutations(rowsByUuid => {
        for(let group of forecastGroups) {

            const uid = group.get('uniqueIdentifier');
            let row = copyColumnKeys(Imm.Map()
                    .set('id', uid)
                    .set('uniqueIdentifier', uid)
                    .set('productId', group.get('productId'))
                    .set('runDate', group.get('runDate'))
                    .set('forecastSchemeType', group.get('forecastSchemeType')),
                group, baseColumns);

            const periods = group.get('periods');
            for(let p of periods) {

                // lookup the corresponding column for this period
                const startDate = p.get('startDate');
                const period = constructMomentFromDate(startDate).week();

                const key = columnsLookup
                    .get(p.get('periodType'))
                    .get(p.get('year'))
                    .get(period);
                
                const quantity = p.get('quantity');
                const algoQuantity = p.get('algoQuantity');
                row = row.set(key, { quantity, algoQuantity });
            }

            rowsByUuid.set('' + uid, row);
        }
    });

    return {
        columns,
        rowsByUuid
    };
}


/**
 * Returns the grid columns shown on the overview screen.
 * Appends period columns, grouped by type, year and period and transfers 'Latest' columns to the end of the result column list.
 */
function getColumns(overviewGroups) {
    const lookup = new Map();
    const {firstColumns, lastColumns} = splitBaseColumnsFirstLast();

    const columns = firstColumns.concat(
        Imm.List()
            .withMutations(periodColumns => {
                let i = 0;
                for (let group of overviewGroups) {
                    const periods = group.get('periods');
                    for (let p of periods) {
                        const periodType = p.get('periodType');
                        const year = p.get('year');

                        let groupPeriodType = lookup.get(periodType);
                        groupPeriodType == null && (lookup.set(periodType, groupPeriodType = new Map()));

                        let groupYear = groupPeriodType.get(year);
                        groupYear == null && (groupPeriodType.set(year, groupYear = new Map()));

                        const startDate = p.get('startDate');
                        const periodMoment = constructMomentFromDate(startDate);
                        const groupWeek = periodMoment.week();

                        if (!groupYear.has(groupWeek)) {
                            const columnKey = 'period-' + (i++);
                            periodColumns.push({columnKey, periodType, periodMoment});
                            groupYear.set(groupWeek, columnKey);
                        }
                    }
                }
            })
            .sort(sortPeriodColumns)
            .map(toPeriodColumn))
        .concat(lastColumns);

    return [columns, lookup];
}

function splitBaseColumnsFirstLast() {
    const firstColumns = baseColumns.slice(0, COLUMN_TO_BE_PUBLISHED);
    const lastColumns = baseColumns.slice(COLUMN_TO_BE_PUBLISHED);
    return {firstColumns, lastColumns};
}

function sortPeriodColumns(a, b) {
    const periodA = a.periodMoment;
    const periodB = b.periodMoment;
    return periodA.isAfter(periodB) ? 1 : -1;
}

function toPeriodColumn(spec) {
    const { columnKey } = spec;
    const title = formatPeriodMoment(spec);
    return Imm.Map({ _key: columnKey, width: 130, title, formatter: 'periodQuantity' })
}
