import { List, fromJS } from 'immutable';
import { Column } from '../../../types/columnTypes';

export const columns: List<Column> = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'period', width: 130, title: 'common.grid.period', formatter: 'period' },
    { _key: 'algoQuantity', width: 130, title: 'reorderpoint.grid.algoQuantity' },
    { _key: 'quantity', width: 130, title: 'reorderpoint.grid.quantity' },
    { _key: 'startDate', width: 130, title: 'common.grid.startDate', formatter: 'date' },
    { _key: 'endDate', width: 130, title: 'common.grid.endDate', formatter: 'endDate' }
]);
