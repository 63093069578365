import moment from 'moment';
import _ from 'lodash';

import { formatMoment, stringToDateString } from '../helpers';

export const formatters = {
    period: formatPeriodMoment,
    periodQuantity: formatPeriodQuantity,
    round: roundValue,
    date: formatDate,
    endDate: formatEndDate,
    roundNumber: roundNumberAndFormat,
    number: formatNumber
};

export function formatPeriodMoment({ periodType, periodMoment }) {
    if (periodType === undefined) {
        throw 'Period type should be a non-null value.';
    }

    let periodTypeInUpperCase = periodType.toUpperCase();
    if (periodTypeInUpperCase === 'FORECASTROPSCHEME.PERIODTYPE.MONTH') {
        return 'M ' + periodMoment.format('MM-YYYY');
        
    } else if (periodTypeInUpperCase === 'FORECASTROPSCHEME.PERIODTYPE.WEEK') {
        return formatPeriodWeek(periodMoment);
    }

    throw 'Unrecognized period-type: ' + periodType;
}

export function formatPeriodWeek(m) {
    return 'W ' + m.format('WW-YYYY');
}

export function roundNumberAndFormat(v) {
    return v == null ? '' : Number(Math.round(v)).toLocaleString('nl-BE');
}

export function formatNumber(v) {
    return v == null ? '' : Number(v).toLocaleString('nl-BE');
}

export function formatPeriodQuantity(v) {
    return v == null ? '' : v.quantity;
}

export function roundValue(v) {
    return (v == null || v == '') ? '0' : _.round(v, 2).toLocaleString('nl-BE');
}

export function formatDate(v) {
    return stringToDateString(v);
}

export function formatEndDate(v) {
    return v == null ? '' : formatMoment(moment(v).add(-1,'days'));
}
