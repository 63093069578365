import { formValueSelector } from 'redux-form';
import { hideForm, refreshForm } from '../../common/masterform/formActions';
import CheckboxBinder from '../../common/binder/CheckboxBinder';
import { RequestId } from '../../utils/requests';
import { isRequestFetching } from '../../common/commonSelectors';
import { requestInDetailRefreshMaster } from '../../common/commonActions';
import { CommandConfig, FormConfig, FormModel } from '../../common/masterform/types/formConfigTypes';
import { StoreState } from '../../../types/storeTypes';
import { calculatePartialReorderPoints } from '../reorderPointActions'

type FM = FormModel<{}, any, {}>;

function okDisabled(formModel: FM, state: StoreState): boolean {
    const selector = formValueSelector(formModel.formId);
    const calcROP = selector(state, 'calcROP');
    const calcForecast = selector(state, 'calcForecast');
    return (calcROP != true && calcForecast != true);
}

export const recalculatePartialCommand: CommandConfig<FM> = {
    label: 'reorderpoint.selector.actions.recalculate.partial',
    datacy: 'recalculate-rop-partial',
    disabled: okDisabled,
    action: (formModel) => calculatePartialReorderPoints(formModel)
};

const formConfig: FormConfig<FM> = {
    form: {
        model: { },
        busy: (state: StoreState) => isRequestFetching(state, RequestId.CALCULATE_REORDERPOINT_OR_FORECAST),
        fetchData: (formModel: FM) => requestInDetailRefreshMaster(formModel, RequestId.CALCULATE_REORDERPOINT_OR_FORECAST, 'Reorderpoints Calculated')
    },
    selector: {
        clearDisabled: true,
        layout: {
            component: 'grid',
            numCols: 1,
        },
        fields: {
            calcForecast: { datacy: 'forecast-run', component: CheckboxBinder, label: 'simulation.recalculation.forecasts'},
            calcROP: { component: CheckboxBinder, label: 'simulation.recalculation.reorderPointAndSafetyStocks'}
        },
        commands: [
            {
                label: 'common.selector.ok',
                disabled: okDisabled,
                datacy: 'confirm-recalculate-rop',
                action: (formModel) => refreshForm(formModel.formId)
            },
            recalculatePartialCommand,
            {
                label: 'common.selector.cancel',
                action: (formModel) => hideForm(formModel.formId)
            }
        ]
    }
}

export default formConfig;
