import { RequestId } from "../utils/requests";
import { RequestParams } from "../utils/remoting/requestTypes";

enum ActionTypes {
    SET_PROVIDED_PATH = 'vco.common.data.SET_PROVIDED_PATH',
    API_REQUEST = 'vco.common.api.API_REQUEST',
    BEGIN_API_REQUEST = 'vco.common.api.BEGIN_API_REQUEST',
    API_SAVE_DATA = 'vco.common.api.API_SAVE_DATA',
    API_RESULT = 'vco.common.api.API_RESULT',
    API_ERROR = 'vco.common.api.API_ERROR',
    CLEAR_API_DATA = 'vco.common.api.CLEAR_API_DATA',
    MASTERFORM_QUERY = 'vco.common.api.MASTERFORM_QUERY',
    COPY_LOCAL_STORAGE_PARAMS_TO_STORE = 'vco.common.COPY_LOCAL_STORAGE_PARAMS_TO_STORE',
    SET_PERSISTENT_PARAM = 'vco.common.SET_PERSISTENT_PARAM',
    REQUEST_IN_DETAIL_REFRESH_MASTER = 'vco.common.REQUEST_IN_DETAIL_REFRESH_MASTER',
    SHOW_GENERAL_REMARK = 'vco.common.SHOW_GENERAL_REMARK',
    IGNORE_GENERAL_REMARK = 'vco.common.HIDE_GENERAL_REMARK',
    CONFIRM_GENERAL_REMARK = 'vco.common.CONFIRM_GENERAL_REMARK',
    CANCEL_GENERAL_REMARK = 'vco.common.CANCEL_GENERAL_REMARK',
    REQUEST_REFRESH_FORM = 'vco.common.REQUEST_REFRESH_FORM'
}

export default ActionTypes;

export function setProvidedPath(path: any, value: any) {
    return { type: ActionTypes.SET_PROVIDED_PATH, path, value }
}

export type ApiRequestType<T extends RequestId> = {
    type: ActionTypes.API_REQUEST, requestId: T, args: RequestParams<T>
}

export function apiRequest<T extends RequestId>(requestId: T, ...args: RequestParams<T>): ApiRequestType<T> {
    return { type: ActionTypes.API_REQUEST, requestId, args };
}

export function beginApiRequest<T extends RequestId>(requestId: T) {
    return { type: ActionTypes.BEGIN_API_REQUEST, requestId };
}

export type ApiSaveDataType<T extends RequestId> = {
    type: ActionTypes.API_SAVE_DATA, requestId: T, formId: string, successCaption: string, args: RequestParams<T>
}

export function apiSaveData<T extends RequestId>(requestId: T, formId: string, successCaption: string, ...args: RequestParams<T>): ApiSaveDataType<T> {
    return { type: ActionTypes.API_SAVE_DATA, requestId, formId, successCaption, args };
}

export function apiResult(requestId: any, data: any) {
    return { type: ActionTypes.API_RESULT, requestId, data };
}

export function apiError(requestId: any, error: any) {
    return { type: ActionTypes.API_ERROR, requestId, error };
}

export function clearApiData(requestId: any) {
    return { type: ActionTypes.CLEAR_API_DATA, requestId };
}

export function copyLocalStorageParamsToStore(persistentParams: any) {
    return { type: ActionTypes.COPY_LOCAL_STORAGE_PARAMS_TO_STORE, persistentParams }
}

export function setPersistentParam(key: any, value: any) {
    return { type: ActionTypes.SET_PERSISTENT_PARAM, key, value }
}

export type RequestInDetailRefreshMasterType = {
    type: ActionTypes.REQUEST_IN_DETAIL_REFRESH_MASTER, formModel: any, requestId: any, successMessage: any
}

export function requestInDetailRefreshMaster(formModel: any, requestId: any, successMessage: any): RequestInDetailRefreshMasterType {
    return { type: ActionTypes.REQUEST_IN_DETAIL_REFRESH_MASTER, formModel, requestId, successMessage };
}

export type ApiRequestAndRefreshFormType = {
    type: ActionTypes.REQUEST_REFRESH_FORM, requestId: any, formId: string, successMessage: string, args:any[]
}

export function apiRequestAndRefreshForm(requestId: any, formId: string, successMessage: string, ...args:any[]): ApiRequestAndRefreshFormType {
    return { type: ActionTypes.REQUEST_REFRESH_FORM, requestId, formId, successMessage, args };
}

export function showGeneralRemark(formId: any) {
    return { type: ActionTypes.SHOW_GENERAL_REMARK, formId };
}

export function ignoreGeneralRemark(formId: any, remark: any) {
    return { type: ActionTypes.IGNORE_GENERAL_REMARK, formId, remark };
}

export function confirmGeneralRemark(formId: any, remark: any) {
    return { type: ActionTypes.CONFIRM_GENERAL_REMARK, formId, remark };
}

export function cancelGeneralRemark(formId: any) {
    return { type: ActionTypes.CANCEL_GENERAL_REMARK, formId };
}
