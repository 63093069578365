import * as Imm from 'immutable';
import { createSelector } from 'reselect';

import { Column } from "../../../types/columnTypes";
import { FormModel } from '../../common/masterform/types/formConfigTypes';
import { StoreState } from '../../../types/storeTypes';


type Rows = Imm.List<Imm.Map<string, any>>;
type Columns = Imm.List<Column>;

/**
 * Data decorators allow to provide additional transformations that will be applied to 
 * all rows and columns in the data-model.
 */

export interface GridDataDecorator {

    // modifies a row from the data-model
    modifyRow?(row: Imm.Map<string, any>): Imm.Map<string, any>,

    // should this column be included
    includeColumn?(column: Column): boolean

    // modifies a column from the data-model
    modifyColumn?(column: Column): Column
}

// a function that creates a new data-decorator
export type GridDataDecoratorCreator<T extends FormModel = FormModel> = (formModel: T, state: StoreState) => GridDataDecorator;

export function applyDecoratorsOverRows(decorators: GridDataDecorator[], rows: Rows): Rows {
    for(let modifier of decorators) {
        if(modifier.modifyRow) {
            rows = rows.map(modifier.modifyRow.bind(modifier));
        }
    }
    return rows;
}

export function applyDecoratorsOverColumns(decorators: GridDataDecorator[], columns: Columns): Columns {
    for(let modifier of decorators) {
        if(modifier.modifyColumn) {
            columns = columns
                .filter((column) => modifier.includeColumn ? modifier.includeColumn(column) : true)
                .map(modifier.modifyColumn.bind(modifier));
        }
    }
    return columns;
}
