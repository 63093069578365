export enum AUDIT_LOG_TYPE {
    SALES_FORECAST_CALCULATION = 'SALES_FORECAST_CALCULATION',
    SNA_SALES_FORECAST_CALCULATION = 'SNA_SALES_FORECAST_CALCULATION',
    REORDER_POINT_CALCULATION = 'REORDER_POINT_CALCULATION',
    SNA_REORDER_POINT_CALCULATION = 'SNA_REORDER_POINT_CALCULATION',
    PRODUCT = 'PRODUCT'
}

export enum SHAPEIT_PROPERTY_ID {
    REORDER_POINT_QUANTITY = "Product.REORDER_POINT_QUANTITY",
    SAFETY_STOCK = "Product.SAFETY_STOCK"
}
