import { fromJS } from 'immutable';

export const columns = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'period', width: 130, title: 'common.grid.period', formatter: 'period' },
    { _key: 'quantity', width: 130, title: 'common.grid.quantity', editable: true, formatter: 'roundNumber' },
    { _key: 'fixed', width: 50, title: 'common.grid.fixed', editable: true, type: 'checkbox'},
    { _key: 'uom', width: 50, title: 'common.grid.uom' },
    { _key: 'remark', width: 130, title: 'common.grid.remark', editable: true },
    { _key: 'startDate', width: 130, title: 'common.grid.startDate' },
    { _key: 'endDate', width: 130, title: 'common.grid.endDate' }
]);

export const COLUMN_QUANTITY = 2;
export const COLUMN_REMARK = 4;

export const COLUMNS_META = new Map([
    ['uom', {
        translateValue: true
    }]
])
