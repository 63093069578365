import { TextBinder } from '@pearlchain/component-lib-ui';

import { StoreState } from '../../../types/storeTypes';
import { ForecastUpdateResponse } from '../../utils/remoting/types/forecastOverviewResponseTypes';
import { FormConfig, FormModel } from '../../common/masterform/types/formConfigTypes';

import { showForm } from '../../common/masterform/formActions';
import { apiRequest, clearApiData } from '../../common/commonActions';
import { detailsGenerateForecastLines, detailsSaveForecastGroupLines } from '../forecastingActions';
import { getDataDiff } from '../forecastingHelpers';
import { formatters } from '../../utils/powergrid/formatters';
import { RequestId } from '../../utils/requests';
import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { getApiData, getUpdateForecastDetailsMasterFormData, isWorkingInLive } from '../../common/commonSelectors';
import FieldsLayoutWithGeneralRemark from '../../common/layout/FieldsLayoutWithGeneralRemark';
import auditLogFormConfig from '../../auditlog/formConfig';
import { AUDIT_LOG_TYPE } from '../../utils/auditLogHelpers';
import { dataModelConfig } from './dataModel';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import { COLUMNS_META } from './columns';
import { createReadonlyDataDecorator } from '../../utils/datamodel/ReadonlyInLiveDataDecorator';
import PlantBinder from '../../common/binder/PlantBinder';
import { createAlgoQuantityDecorator } from './AlgoQuantityDecorator';

type FM = FormModel<ForecastUpdateResponse, Values, {}>;

const AUDIT_LOG_FORM_ID = 'audit-log';

interface Values {
    company: string,
    plant: string[],
    productNo: string,
    productDescription: string,
    forecastROPScheme: string,
    forecastAlgo: string,
    fixed: boolean,
    stockUnitOfMeasurement: string,
    customerName: string
}

function fetchData(formModel: FM) {
    const forecastGroupUuid = formModel.parent!.selection.get('uniqueIdentifier');
    const params = { forecastGroupUuid, includeOld: false };
    return apiRequest(RequestId.FIND_FORECAST_LINES, params);
}

function initialValues(parent: FormModel) {
    const selection = parent.selection;
    return selection ? selection.toJS() : {};
}

function isSaveCommandDisabled(formModel: FM) {
    if(formModel.live) return true;

    const bufferedData = formModel.bufferedData;
    const data = formModel.data;
    if(!data || !bufferedData) return true;

    const diffLines = getDataDiff(data, bufferedData);
    return diffLines.size === 0;
}


// form config passed to MasterForm component
const formConfig: FormConfig<FM> = {
    form: {
        fetchOnMount: true,
        model: {
            values: (state: StoreState, formId, params, select) => select(params.parent)(initialValues),
            data: (state: StoreState) => getUpdateForecastDetailsMasterFormData(state) || getApiData(state, RequestId.FIND_FORECAST_LINES),
            live: (state: StoreState) => isWorkingInLive(state)
        },
        clearData: () => clearApiData(RequestId.FIND_FORECAST_LINES),
        fetchData
    },
    selector: {
        layout: {
            component: FieldsLayoutWithGeneralRemark,
            generalRemarkFieldName: 'generalRemark',
            numCols: 2,
        },
        fields: {
            company: { component: TextBinder, label: 'common.selector.company' },
            plant: { component: PlantBinder, label: 'common.selector.plant', multi: false },
            productNo: { component: TextBinder, label: 'common.selector.productNo' },
            productDescription: { component: TextBinder, label: 'common.selector.productDesc' },
            forecastROPScheme: { component: TextBinder, label: 'common.selector.forecastropscheme' },
            forecastAlgo: { component: TextBinder, label: 'common.selector.algo' },
            stockUnitOfMeasurement: { component: TextBinder, label: 'common.selector.stockUom', translate: true },
            customerName: { component: TextBinder, label: 'common.selector.customerName' }
        },
        commands: [
            { label: 'forecasting.manentry.command.generate', disabled: false, action: (formModel) => detailsGenerateForecastLines(formModel) },
            {
                label: 'common.selector.actions.save',
                type: 'primary',
                group: -1,
                disabled: isSaveCommandDisabled,
                action: (formModel: FM) => detailsSaveForecastGroupLines(formModel)
            },
            {
                label: 'common.selector.actions.auditLog',
                disabled: (formModel: FM) => !formModel.selection,
                action: (formModel: FM) => showForm(AUDIT_LOG_FORM_ID, {
                    type: AUDIT_LOG_TYPE.SALES_FORECAST_CALCULATION,
                    snaType: AUDIT_LOG_TYPE.SNA_SALES_FORECAST_CALCULATION,
                    parentId: formModel.selection.get('id')
                })
            }
        ]
    },
    grid: {
        dataModel: dataModelConfig,
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META }),
            createReadonlyDataDecorator(),
            createAlgoQuantityDecorator()
        ],
        extensions: {
            [EXTS.RESIZING]: true,
            [EXTS.EDITING]: true,
            [EXTS.DIRECT_INPUT]: true,
            [EXTS.STYLING]: true,
            [EXTS.FORMATTING]: {
                formatters
            },
            [EXTS.SELECTION]: true
        }
    },
    children: {
        [AUDIT_LOG_FORM_ID]: {
            component: 'modal',
            title: 'Audit Log',
            className: 'modal-level-2',
            form: {
                config: auditLogFormConfig
            }
        }
    }
};

export default formConfig;
