import React from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { showForm } from '../../masterform/formActions';
import { getCurrentSimulationInstance } from '../../commonSelectors';
import { SimulationInstanceRecord } from '../../../utils/remoting/types/simulationInstanceRequestTypes';
import { WrappedFieldInputProps } from 'redux-form';
import { Params } from './productLookupFormConfig';

interface Props {
    input: WrappedFieldInputProps;
    formId: string,
    binderFormId: string;
    dependsOn?: {},
    disabled: boolean,
    useForecastSchemeBasedOnCurrentSimulation?: boolean,
    currentSimulationInstance?: any,
    datacy: string,
    showForm: (binderFormId:string, params: Params) => void,
    resultMapping: {description: string}
}

function ProductBinder(props: Props) {
    const name = props.input.name;
    const binderFormId = props.binderFormId;
    const forecastROPScheme = resolveForecastScheme(props);
    const resultMapping = props.resultMapping;

    function buildFormParams(): Params {
        return {
            productNo: props.input.value,
            resultField: name,
            resultFormId: props.formId,
            resultMapping,
            forecastROPScheme,
            ...props.dependsOn
        };
    }

    const dependsOnDisable = props.dependsOn && Object.values(props.dependsOn).some(value => !value);
    return (
        <InputGroup>
            <Input
                disabled={!!props.disabled || !!props.dependsOn}
                {...props.input} data-cy={props.datacy}/>
                
            <InputGroupAddon addonType="append">
                <Button disabled={dependsOnDisable} onClick={() => props.showForm(binderFormId, buildFormParams())} data-cy={props.datacy}>
                    <FontAwesome name="search"/>
                </Button>
            </InputGroupAddon>
        </InputGroup>);
}

function resolveForecastScheme(props: Props) {
    const currentSimulationInstance = props.currentSimulationInstance;
    if (!currentSimulationInstance) {
        return undefined;
    }
    if (currentSimulationInstance.calcReorderPoint) {
        return 'StandardForecastROPScheme.ManualEntryROP';
    } else if (currentSimulationInstance.calcForecast) {
        return 'StandardForecastROPScheme.ManualEntry';
    } else {
        return undefined;
    }
}

export default connect(
    (state, { useForecastSchemeBasedOnCurrentSimulation }: Props) => {
        const currentSimulationInstance = getCurrentSimulationInstance(state);
        if (useForecastSchemeBasedOnCurrentSimulation && currentSimulationInstance) {
            return {currentSimulationInstance: SimulationInstanceRecord(currentSimulationInstance)};
        }
        return {currentSimulationInstance: undefined};
    },
    {showForm}
)(ProductBinder);
