import { getApiData } from '../../../common/commonSelectors';
import { RequestId } from '../../../utils/requests';
import { createSimulationTemplate } from '../simulationTemplateActions';
import SimulationTemplateFormSelector from '../details/SimulationTemplateFormSelector';

function getValues(simTemplate) {
    return simTemplate ? simTemplate.get('initialValues', {}) : {};
}

const formConfig = {
    form: {
        model: {
            data: (state) => getApiData(state, RequestId.CREATE_SIMULATION_TEMPLATE),
            values: (state, formId, parent, select) => select(state.simulationTemplate)(getValues)
        }
    },
    selector: {
        layout: {
            component: SimulationTemplateFormSelector
        },
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        commands: [
            {
                label: 'common.selector.actions.create',
                disabled: false,
                action: (formModel) => createSimulationTemplate(formModel.formId, formModel.parent.formId, formModel.bufferedValues),
                requiresValid: true
            }
        ]
    }
};

export default formConfig;
