import React from 'react';
import { Col } from 'reactstrap';

const DEFAULT_DIM = 'xs';

/**
 * Uses the Bootstrap grid system to layout the children.
 * Supports different number of columns per screen media.
 */

type Dim = 'xs'|'sm'|'md'|'lg';
export type ReactiveDims = { [D in Dim]?: number }

const DEFAULT_COL_DIMS: ReactiveDims = { xs: 1, sm: 2, md: 3, lg: 4 }

// the properties of the child element
export interface ItemProps {
    colDim: number|ReactiveDims   
}

type ChildElement = React.ReactElement<ItemProps>;

interface Props {
    'data-cy'?: string,
    horizontal?: boolean,
    children: ChildElement|ChildElement[],
    numCols?: number|ReactiveDims
}

export default function GridLayout(props: Props) {
    const children = childrenAsIterable(props.children);
    const numCols = props.numCols || DEFAULT_COL_DIMS;

    return (
        <div className="form-row p-3" data-cy={props["data-cy"]}>
            {renderRows(numCols, children)}
        </div>
    );
}

function childrenAsIterable(children: ChildElement|ChildElement[]): ChildElement[] {
    if(Array.isArray(children)) return children;
    return [children];
}

function renderRows(numCols: number|ReactiveDims, children: ChildElement[]) {
    let items: ChildElement[] = [];
    for (let child of children) {
        if(child) {
            if (Array.isArray(child)) {
                for(let c of child) items.push(c);
            } else {
                items.push(child);
            }
        }
    }

    const baseDims = getNumCols(numCols);
    const totals: ReactiveDims = {};
    const elements = [];

    let i = 0;
    for(let item of items) {
        const itemDims = item.props && item.props.colDim;
        const dims = itemDims ? getDims(itemDims, baseDims) : baseDims;
        const key = i++;

        // create a new Bootstrap column element
        elements.push(React.createElement(Col, { key, ...dims }, item));

        let rowsCleared = new Set<Dim>();
        for(let dim of Object.keys(dims) as Dim[]) {
            const t = (totals[dim] || 0) + dims[dim]!;
            totals[dim] = t;
            if(t >= 12) {
                totals[dim] = 0;
                rowsCleared.add(dim);
            }
        }
    }

    return elements;
}

function getNumCols(numCols: number|ReactiveDims): ReactiveDims {
    if(typeof numCols === 'object') {
        const result: ReactiveDims = {};

        // divide num-cols by 12, to get the dimension in grid units
        for(let dim in numCols) result[dim as Dim] = 12 / numCols[dim as Dim]!;
        return result;

    } else {
        return { [DEFAULT_DIM]: 12 / numCols };
    }
}

function getDims(dims: number|ReactiveDims, baseDims: ReactiveDims) {
    if(!dims) {
        return baseDims;
    } else if(typeof dims === 'object') {
        return dims;
    } else {
        return scaleBaseDims(dims, baseDims);
    }
}

function scaleBaseDims(scale: number, baseDims: ReactiveDims) {
    const result: ReactiveDims = {};
    for(let dim in baseDims) result[dim as Dim] = Math.round(baseDims[dim as Dim]! * scale);
    return result;
}
