import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestId } from '../../utils/requests';
import { dataToOptionsWithSorting } from '../../utils/binder/binderHelpers';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

export default function ProductGroupBinder(props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_PRODUCT_GROUPS}
        render={(opts) => <SelectBinder multi options={dataToOptionsWithSorting(opts)} { ...props }/>}
        />;
}
