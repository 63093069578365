import { Map } from 'immutable';
import { FormModel } from '../masterform/types/formConfigTypes';

type FM = FormModel<any, any>;

export function resolveResultFieldValueMap(primaryField: string, formModel: FM) {
    return Map().withMutations(mapping => {
        mapping.set(formModel.params.resultField, formModel.selection.get(primaryField));

        if (formModel.params.resultMapping) {
            Object.entries(formModel.params.resultMapping).forEach(entry => {
                let key = entry[0];
                let value = entry[1];
                mapping.set(value, formModel.selection.get(key));
            });
        }
    });
}