import React from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import SplitPane from 'react-split-pane';
import { getLocationParams } from '@pearlchain/component-lib-common';

import ForecastDetailsGraph from './ForecastDetailsGraph';
import ProductLookupModal from '../common/binder/product/ProductLookupModal';
import ForecastDetailsInspector from './ForecastDetailsInspector';
import { FORM_ID } from './helpers';
import { getFormValues } from '../common/masterform/formSelectors';
import { StoreState } from '../../types/storeTypes';
import { setFormValues } from '../common/masterform/formActions';

interface OwnProps {
    fetchOnMount?: boolean,
    initialValues?: { [key: string]: any },
    destroy: (formId: string) => void
}

interface DispatchProps {
    setFormValues: typeof setFormValues,
    router: any,
    route: any
}

type Props = OwnProps & DispatchProps;

class ForecastDetailsComponent extends React.Component<Props> {
    
    componentWillUnmount() {
        // delay clearing of the form state until the whole graph is unmounted
        this.props.destroy(FORM_ID);
    }

    componentDidMount() {
        this.props.router.setRouteLeaveHook(this.props.route, () => {
            this.props.setFormValues('forecast-details', undefined)
        })
      }

    render() {
        return (
            <div className="forecast-details-page">
                <SplitPane split="vertical" defaultSize={250}>
                    <ForecastDetailsInspector fetchOnMount={this.props.fetchOnMount} initialValues={this.props.initialValues}/>
                    <ForecastDetailsGraph saving={false}/>
                </SplitPane>
                <ProductLookupModal/>
            </div>);
    }
}

export default connect((state) => {
    const values = getFormValues(state as StoreState,'forecast-details');
    return {
        fetchOnMount: values ? values.fetchOnMount as boolean : false,
        initialValues: values ? values.initialValues as { [key: string]: any } : { }
    };
}, { destroy, setFormValues })(ForecastDetailsComponent);
