import moment, { Moment } from "moment";
import * as Imm from 'immutable';

export interface DiffResult<TDatum> {
    updated: TDatum[][],
    removed: TDatum[],
    created: TDatum[]
}

export function formatMoment(period: Moment): string {
    return period.format('DD-MM-YYYY');
}

export function stringToDateString(v: string): string {
    let m;
    if(v == null) {
        return '';
    } else if (v.includes('T')) {
        m = moment(v);
    } else {
        m = moment(v, 'DD-MM-YYYY');
    }

    return formatMoment(m);
}

export function dateStringToRequest(date: string|undefined): string|undefined {
    return date && moment.utc(date, 'DD-MM-YYYY').format();
}

export function formatPeriod(year: number, period: number): string {
    return `W ${period}-${year}`;
}

export function without<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, Exclude<keyof T, K>> {
    const copy = Object.assign({}, obj);
    for(let key of keys) {
        delete copy[key];
    }
    return copy as any;
}

export function arrayEquals(a: any[]|undefined, b: any[]|undefined): boolean {
    if(a == null || b == null) {
        return a == null && b == null;
    }

    if(a.length !== b.length) {
        return false;
    }

    for(let i = 0, n = a.length; i < n; ++i) {
        if(a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

export function getDiffs<TDataItem>(prevData: Iterable<TDataItem>, data: Iterable<TDataItem>, idFn: (datum: TDataItem) => string | number, compareFn: (left: TDataItem, right: TDataItem) => boolean): DiffResult<TDataItem> {
    const remaining = new Map<string | number, TDataItem>();
    for(let d of prevData) {
        remaining.set(idFn(d), d);
    }

    const updated = [], removed = [], created = [];
    for(let d of data) {
        const id = idFn(d);
        const prev = remaining.get(id);
        
        if(prev) {
            remaining.delete(id);
            if(!compareFn(prev, d)) {
                updated.push([prev, d]);
            }

        } else {
            created.push(d);
        }
    }

    for(let d of remaining.values()) {
        removed.push(d);
    }
    
    return { updated, removed, created };
}

export function compareFields(fields: string[], a: Imm.Map<string, any>, b: Imm.Map<string, any>) {
    for(let field of fields) {
        if(a.get(field) !== b.get(field)) {
            return false;
        }
    }
    return true;
}

export function compareRecords<T>(fields: (keyof T)[], a: Imm.Record<T>, b: Imm.Record<T>) {
    return !fields.some(field => a.get(field) !== b.get(field));
}
