import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { isAnyRequestFetching } from '../../commonSelectors';

function SelectorIcon(props) {
    const fetching = props.fetching;
    return <div className="selector-icon">
        { fetching ? renderSpinner() : renderIcon(props.name) }
    </div>
}

function renderSpinner() {
    return <FontAwesome spin name="spinner"/>
}

function renderIcon(name) {
    return name ? <FontAwesome name={name}/> : undefined;
}

SelectorIcon.propTypes = {
    name: PropTypes.string,
    showBusy: PropTypes.bool,
    fetching: PropTypes.bool.isRequired
}

export default connect(
    (state, { showBusy }) => ({
        fetching: (showBusy === undefined || showBusy) ? isAnyRequestFetching(state) : false
    })
)(SelectorIcon);
