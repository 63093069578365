import { requestInDetailRefreshMaster } from '../../common/commonActions';
import { RequestId } from '../../utils/requests';
import NewSimulationInstanceFieldsLayout, { getDefaultValues } from './NewSimulationInstanceFormSelector';
import { isRequestFetching } from '../../common/commonSelectors';

function isSaveCommandBusy(formModel, state) {
    return isRequestFetching(state, RequestId.CREATE_SIMULATION_INSTANCE);
}

const formConfig = {
    form: {
        model: {
            values: (state, formId, params, select) => select(state.simulationInstance)(getDefaultValues)
        }
    },
    selector: {
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        layout: {
            component: NewSimulationInstanceFieldsLayout
        },
        commands: [
            {
                label: 'simulationinstance.actions.create',
                type: 'primary',
                group: -1,
                busy: isSaveCommandBusy,
                disabled: false,
                action: (formModel) => requestInDetailRefreshMaster(formModel, RequestId.CREATE_SIMULATION_INSTANCE, 'Simulation Instance Created'),
                requiresValid: true
            }
        ]
    }
}

export default formConfig;
