import React from 'react';
import { connect } from 'react-redux';

import { GraphData } from './types';
import { StoreState, SelectionState } from '../../types/storeTypes';

import ForecastDetailsLoadingOverlay from './ForecastDetailsLoadingOverlay';
import { isRequestFetching } from '../common/commonSelectors';
import { RequestId } from '../utils/requests';
import { getData, getSplitSize, getSelection } from './selectors';
import { createGraph } from './graph/graph';
import { setItemQuantity, setSelectedItem, clearSelectedItem } from './actions';
import { Record } from 'immutable';

interface Props {
    loading: boolean,
    saving: boolean,
    data: GraphData|undefined,
    size: number|undefined,
    selection: Record<SelectionState>|undefined,
    setItemQuantity: typeof setItemQuantity,
    setSelectedItem: typeof setSelectedItem,
    clearSelectedItem: typeof clearSelectedItem
}

class ForecastDetailsGraph extends React.Component<Props> {
    private inputRef!: React.RefObject<any>;
    private updateGraph!: (d: GraphData, selection: Record<SelectionState>|undefined) => void;
    private resizeHandle: any;

    constructor(props: Props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.updateGraph = createGraph(this.inputRef.current, {
            setSelectedItem: this.handleSetSelectedItem.bind(this),
            clearSelectedItem: this.handleClearSelectedItem.bind(this),
            setItemQuantity: this.handleSetItemQuantity.bind(this)
        });

        if(this.props.data) {
            this.updateGraph(this.props.data, this.props.selection);
        }
    }

    handleSetSelectedItem(runIdx: number, pointIdx: number) {
        this.props.setSelectedItem(runIdx, pointIdx);
    }

    handleClearSelectedItem() {
        this.props.clearSelectedItem();
    }

    handleSetItemQuantity(runIdx: number, pointIdx: number, quantity: number) {
        this.props.setItemQuantity(runIdx, pointIdx, quantity);
    }

    componentDidUpdate(prevProps: Props) {
        if(this.props.data && (this.props.data !== prevProps.data || this.props.selection !== prevProps.selection)) {
            // redraw the graph if the data changed
            this.updateGraph(this.props.data, this.props.selection);

        } else if(this.props.size !== prevProps.size) {
            // resize the graph if the split-pane changed size
            this.resize();
        }
    }

    resize() {
        if(this.resizeHandle) {
            window.clearTimeout(this.resizeHandle);
        }

        // wait a few milliseconds until the boundary dimensions have been recalculated
        this.resizeHandle = window.setTimeout(this.doResize.bind(this), 200);
    }

    doResize() {
        if(this.inputRef.current != null && this.props.data) {
            this.updateGraph(this.props.data, this.props.selection);
        }
    }

    render() {
        return (
            <div className="graph-container" ref={this.inputRef}>
                { this.props.loading || this.props.saving ? <ForecastDetailsLoadingOverlay/> : null }
                <svg className="graph-svg"/>
            </div>);
    }
}

export default connect(
    (state: StoreState) => ({
        data: getData(state.forecastDetails),
        size: getSplitSize(state.forecastDetails),
        loading: isRequestFetching(state, RequestId.FORECAST_TIME_SERIES),
        selection: getSelection(state.forecastDetails)
    }),
    { setItemQuantity, setSelectedItem, clearSelectedItem }
)(ForecastDetailsGraph);
