import React from 'react';
import FormSelector from './selector/FormSelector';
import FormGrid from './grid/FormGrid';
import FormChild from './FormChild';
import { FormConfig, GridConfig, SelectorConfig, FormModel } from './types/formConfigTypes';

interface Props {
    formId: string,
    formModel: FormModel,
    config: FormConfig
}

function FormInner(props: Props) {
    const { formId, formModel, config } = props;
    
    const formProps = {
        formId,
        formModel
    };

    function renderSelector(selector: SelectorConfig, split: boolean) {
        return <FormSelector split={split} {...formProps} {...selector}/>;
    }

    function renderGrid(grid: GridConfig) {
        return <FormGrid {...formProps} {...grid}/>;
    }

    function renderSplitForm(selector: SelectorConfig, grid: GridConfig) {
        return (
            <React.Fragment>
                { renderSelector(selector, true) }
                { renderGrid(grid) }
            </React.Fragment>);
    }

    function renderChildForms(children: { [key: string]: any }) {
        return Object.entries(children).map(([childFormId, childConfig]) =>
            <FormChild
                key={childFormId}
                formId={childFormId}
                parent={formModel}
                config={childConfig}/>);
    }

    function renderContent() {
        if(config.grid) {
            if(config.selector) {
                return <div className="form-wrapper-full">{ renderSplitForm(config.selector, config.grid) }</div>;

            } else {
                return <div className="form-wrapper-grid">{ renderGrid(config.grid) }</div>;
            }

        } else {
            return <div className="form-wrapper-selector">{ renderSelector(config.selector!, false) }</div>;
        }
    }

    return (
        <React.Fragment>
            { renderContent() }
            { config.children ? renderChildForms(config.children) : [] }
        </React.Fragment>);
}

export default FormInner;
