import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';

import { StoreState } from '../../../../types/storeTypes';

import { resolveDynamicProperty } from '../../../utils/masterform/formHelpers';
import { Action, bindActionCreators } from 'redux';
import { DynamicProperty, FormModel } from '../types/formConfigTypes';

interface OwnProps {
    formModel: FormModel,
    busy?: DynamicProperty<boolean>,
    disabled?: DynamicProperty<boolean>,
    action?: (formModel: FormModel) => Action,
}

interface Props {
    type?: string,
    formModel: FormModel,
    label: string,
    disabled: boolean,
    busy: boolean,
    insideGroup: boolean,
    action?: (formModel: FormModel) => void,
    t: Function,
    datacy?: string,
    requiresValid?: boolean,
    handleSubmit: () => void,
    isFormValid?: boolean
}

function CommandButton(props: Props) {
    const disabled = props.disabled || props.busy;

    function handleClicked() {
        if (props.requiresValid && !props.isFormValid) {
            props.handleSubmit();
        } else {
            props.action && props.action(props.formModel);
        }
    }

    let className = 'selector-command-btn';
    if (!props.insideGroup)
        // add a margin when the command is not inside a button group
        className += ' mr-2'

    return (
        <Button className={className}
            color={props.type}
            data-cy={props.datacy}
            disabled={disabled}
            onClick={handleClicked}>
            { props.t(props.label) }
            { props.busy &&
                <div style={{ marginLeft: 5, display: 'inline-block' }}>
                    <BeatLoader color={'#FFFFFF'} size={10}/>
                </div> }
        </Button>);
}

export default connect(
    (state: StoreState, { formModel, busy, disabled }: OwnProps) => ({
        disabled: disabled ? resolveDynamicProperty(disabled, formModel, state) : false,
        busy: busy ? resolveDynamicProperty(busy, formModel, state) : false
    }),
    (dispatch, { action }: OwnProps) => action ? bindActionCreators({ action }, dispatch) : { }
)(withTranslation(['prl'])(CommandButton));
