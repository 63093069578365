
export class LookupPeriodYear<T> {
    private readonly lookupYear = new Map<number, Map<number, T>>();

    get(year: number, period: number): T|undefined {
        const lookupPeriod = this.lookupYear.get(year);
        if(lookupPeriod === undefined) return;

        return lookupPeriod.get(period);
    }

    set(year: number, period: number, value: T) {
        let lookupPeriod = this.lookupYear.get(year);
        if(lookupPeriod === undefined)
            this.lookupYear.set(year, lookupPeriod = new Map());

        lookupPeriod.set(period, value);
    }
}
