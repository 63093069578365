import React from 'react';
import FontAwesome from 'react-fontawesome';

/**
 * A transparent loading spinner, overlayed over the graph
 */

export default function ForecastDetailsLoadingOverlay() {
    return <div className="graph-loading">
            <FontAwesome name="spinner" spin/>
        </div>
}
