import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MenuItem } from 'react-contextmenu';
import { resolveDynamicProperty } from '../../../utils/masterform/formHelpers';
import { bindActionCreators } from 'redux';

export function ContextMenuItem(props) {
    const { disabled, action, formModel, label } = props;
    return <MenuItem
        disabled={disabled}
        onClick={() => action(formModel)}>{ props.t(label) }</MenuItem>
}

ContextMenuItem.propTypes = {
    // own props
    formModel: PropTypes.object.isRequired,
    
    // connect
    t: PropTypes.func.isRequired,
    action: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}

export default connect(
    (state, { formModel, label, disabled }) => ({
        disabled: resolveDynamicProperty(disabled, formModel, state),
        label: resolveDynamicProperty(label, formModel, state)
    }),
    (dispatch, { action }) => bindActionCreators({ action }, dispatch)
)(withTranslation(['prl'])(ContextMenuItem));
