import React from 'react';
import { hot } from 'react-hot-loader';
import { withTranslation } from 'react-i18next';
import { createLocationItem } from '@pearlchain/component-lib-common';
import { LocationMenuItem, Navigation } from '@pearlchain/component-lib-ui';
import { List } from 'immutable';

import { Page } from '../../types/pageTypes';

import { pages, getPageTitleByPath } from './pages';
import SimContextDropdown from './SimContextDropdown';
import CurrentWeek from './CurrentWeek';
import i18n from '../../i18n';

interface Props {
    t: (s: string) => string,
    currentPage: Page|undefined
    onSignOut: () => void;
}

function VcoNavigation(props: Props) {
    const currentPageItem = createLocationItem({
        pathName: props.currentPage ? props.currentPage.path : '/',
        title: props.currentPage ? props.t(props.currentPage.title) : 'Title'
    });

    let path = window.location.hash;
    path = path.replace('#','');
    const breadcrumb = props.t(getPageTitleByPath(path));

    return (
        <>
            <Navigation
                onSignOut={props.onSignOut}
                loginPath="#/"
                initialLocation={List().push(currentPageItem)}
                menuItems={getMenuItems(props.t)}
                onRefreshTranslations={refreshTranslations}
                breadcrumb={breadcrumb}>
                <CurrentWeek/>
                <SimContextDropdown/>
            </Navigation>

        </>
    )
}

function getMenuItems(t: (s: string) => string): LocationMenuItem[] {
    return pages.map(page => {
        const title = t(page.title);
        return {
            title,
            icon: page.icon as any,
            location: createLocationItem({
                title,
                pathName: page.path
            })
        };
    });
}

function refreshTranslations() {
    for(let i = 0, n = localStorage.length; i < n; ++i) {
        const key = localStorage.key(i);
        if(key && key.startsWith('i18next_res_'))
            localStorage.removeItem(key);
    }
    i18n.reloadResources();
}

export default hot(module)(withTranslation(['prl'])(VcoNavigation));
