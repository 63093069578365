import { Map } from 'immutable';
import { COLUMN_QUANTITY, COLUMN_FIXED, COLUMN_REMARK, columns } from './columns';
import { constructMomentFromDate } from '../../utils/formattingHelpers';
import { copyColumnKeys } from '../../utils/powergrid/columnHelpers';

export const dataModelConfig = {
    columns,
    getId: (dataItem) => dataItem.get('id'),
    shouldApplyClasses: (column) => column._key === 'quantity',
    getRow(dataItem, { id }) {
        const periodType = dataItem.get('periodType');
        const startDate = dataItem.get('startDate');
        const periodMoment = constructMomentFromDate(startDate);
        return copyColumnKeys(Map()
            .set('id', id)
            .set('period', { periodType, periodMoment }),
            dataItem, columns);
    },
    getClass(column, dataItem) {
        if (column._key !== 'quantity') return;
        const algoQuantity = parseFloat(dataItem.get('algoQuantity'));
        const quantity = parseFloat(dataItem.get('quantity'));
        if (quantity !== algoQuantity) {
            return 'pg-cell-modified';
        }
    },
    modifyDataItem(columnIdx, value, dataItem, entry) {
        console.log('modifyDataItem');
        if (columnIdx == COLUMN_QUANTITY) {
            dataItem = dataItem.set('quantity', value);
            dataItem = dataItem.set('fixed', true);
            return dataItem;
        }
        if (columnIdx == COLUMN_FIXED) {
            dataItem = dataItem.set('fixed', value);
            return dataItem;
        }
        if (columnIdx == COLUMN_REMARK) {
            dataItem = dataItem.set('remark', value);
            return dataItem;
        }
    }
};
