import { all, cancel, fork, put, take, takeEvery } from 'redux-saga/effects';
import { change } from 'redux-form';
import ActionTypes, { hideForm, triggerFormEvent } from './formActions';
import { FORM_EVENT } from './formEvents';

const ACTION_VALUE_CHANGED = 'POWERGRID_VALUE_CHANGED';

export default function() {
    return all([
        takeEvery(ActionTypes.MOUNT_FORM_GRID, handleMountFormGrid),
        takeEvery(ActionTypes.COMPLETE_FORM_PROVIDE_RESULT, completeFormProvideResult)
    ]);
}

function filterUnmount(formId) {
    return (action) => action.formId === formId && (action.type === ActionTypes.MOUNT_FORM_GRID || action.type === ActionTypes.UNMOUNT_FORM_GRID);
}

function *handleMountFormGrid({ formId, gridId }) {
    const fk = yield fork(watchGridChanges, formId, gridId);
    yield take(filterUnmount(formId));
    yield cancel(fk);
}

function *watchGridChanges(formId, gridId) {
    while(true) {
        const action = yield take(ACTION_VALUE_CHANGED);
        if (action.rootId !== gridId) continue;

        yield put(triggerFormEvent(formId, FORM_EVENT.GRID_VALUE_CHANGED, {
            row: action.row,
            column: action.column,
            value: action.value
        }));
    }
}

function *completeFormProvideResult({ formId, binderFormId, binderFormFieldValueMap }) {
    yield put(hideForm(formId));
    for (let [key, value] of binderFormFieldValueMap) {
        yield put(change(binderFormId, key, value));
    }
}
