import React from 'react';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import { replaceLocation } from '@pearlchain/component-lib-common';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';

import { getCurrentSimulationInstance, KEY_SIM_CONTEXT_UID } from '../common/commonSelectors';
import { setPersistentParam } from '../common/commonActions';
import { RequestId } from '../utils/requests';

import { isActiveSimInstance } from '../utils/simulationHelpers';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

function SimulationContextDropdown(props) {
    return <RequestIdFetcher
        requestId={RequestId.FIND_SIMULATION_INSTANCES}
        render={(simulationInstances) => {
            const currentSimInstance = props.currentSimInstance;
            const title = currentSimInstance ? currentSimInstance.get('name') : 'live';
            return (
                <UncontrolledDropdown id="simulation-context">
                    <DropdownToggle nav caret>
                        Simulation ({ title })
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem
                            onClick={() => props.setPersistentParam(KEY_SIM_CONTEXT_UID, null)}
                            disabled={currentSimInstance == null}>
                            Live
                        </DropdownItem>
                        <DropdownItem divider/>
                        { simulationInstances && simulationInstances
                            .filter(isActiveSimInstance)
                            .map((instance, i) =>
                                <DropdownItem key={i} eventKey={'' + i} disabled={instance === currentSimInstance}
                                    onClick={() => {
                                        props.setPersistentParam(KEY_SIM_CONTEXT_UID, instance.get('uniqueIdentifier'))}}>
                                    { instance.get('name') }
                                </DropdownItem>
                            )
                        }
                        <DropdownItem divider/>
                        <DropdownItem eventKey="switch"
                            onClick={() => props.replaceLocation('/simulationInstance', 'Simulation Instance', {})}>Choose Simulation</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>);
        }}/>
}

SimulationContextDropdown.propTypes = {
    data: PropTypes.instanceOf(List),
    setPersistentParam: PropTypes.func.isRequired,
    currentSimInstance: PropTypes.instanceOf(Map),
    replaceLocation: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({ currentSimInstance: getCurrentSimulationInstance(state) });

export default connect(mapStateToProps, { replaceLocation, setPersistentParam })(SimulationContextDropdown);
