import RequestBuilder from "../RequestBuilder";
import { VcoRequestHandler } from "../../requests";

import {
    ForecastAccuracyDetailsRequest,
    ForecastAccuracyDetailsResponse,
    ForecastAccuracyOverviewGroupResponse,
    ForecastAccuracyOverviewRequest
} from "../types/forecastAccuracyRequestTypes";

const FORECAST_ACCURACY_OVERVIEW_URL = 'sec/api/vco/forecast-accuracy/overview';
const FORECAST_ACCURACY_DETAILS_URL = 'sec/api/vco/forecast-accuracy/details';

export const findForecastAccuracyDetails: VcoRequestHandler = (requestBuilder: RequestBuilder, params: ForecastAccuracyDetailsRequest={}) => 
    requestBuilder.post(FORECAST_ACCURACY_DETAILS_URL)
        .setBodyJson({
            query: {
                fromDate: params.periodFrom,
                toDate: params.periodTo,
                product: {
                    productNo: params.productNo
                },
                plants: params.plants
            },
            resultMapping: [
                'actual[].year',
                'actual[].period',
                'actual[].quantity',
                'actual[].productId',
                'forecasts[].runId',
                'forecasts[].runDate',
                'forecasts[].groups[].forecastScheme',
                'forecasts[].groups[].plant',
                ['forecasts[].groups[].product.no', 'forecasts[].groups[].productNo'],
                'forecasts[].groups[].calculations[].quantity',
                'forecasts[].groups[].calculations[].startDate'
            ]
        })
        .toJS<ForecastAccuracyDetailsResponse>();

export const findForecastAccuracyOverview: VcoRequestHandler = (requestBuilder: RequestBuilder, params: ForecastAccuracyOverviewRequest={}) => 
    requestBuilder
        .post(FORECAST_ACCURACY_OVERVIEW_URL)
        .setBodyJson({
            query: {
                fromDate: params.periodFrom,
                toDate: params.periodTo,
                product: {
                    productNo: params.productNo,
                    description: params.productDesc,
                    productGroup: params.productGroup,
                    inventoryPlanner: params.inventoryPlanner,
                    companyCodes: params.company ? [params.company] : undefined,
                    hasForecastScheme: true
                },
                customer: {
                    no: params.companyNo,
                    name: params.companyName
                },
                plants: params.plants
            },
            maxResults: params.maxResults
        })
        .toJS<ForecastAccuracyOverviewGroupResponse>();
