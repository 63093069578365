import React from 'react';
import PropTypes from 'prop-types';

import ChildFormModal from './ChildFormModal';

class FormChild extends React.Component {

    render() {
        const wrappedComponent = this.getWrappedComponent();
        const childProps = this.getChildProps();
        return React.createElement(wrappedComponent, childProps);
    }

    getChildProps() {
        const props = {
            formId: this.props.formId,
            parent: this.props.parent,
            ...this.props.config
        };

        // don't pass the component to itself
        delete props.component;

        return props;
    }

    getWrappedComponent() {
        const component = this.props.config.component;
        if(typeof component === 'string') {
            switch(component) {
                case 'modal': return ChildFormModal;
                default: throw new Error('Unrecognized child component: ' + component);
            }
        }

        return component;
    }
}

FormChild.propTypes = {
    formId: PropTypes.string.isRequired,
    parent: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired
}

export default FormChild;
