import { TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';
import { formatters } from '../../utils/powergrid/formatters';
import { manualEntryGenerateRows, manualEntrySaveLines } from '../forecastingActions';
import moment, { Moment } from 'moment';

import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { refreshForm } from '../../common/masterform/formActions';
import { columns, COLUMNS_META } from './columns';
import FieldsLayoutWithGeneralRemark from '../../common/layout/FieldsLayoutWithGeneralRemark';
import productBinderFormConfig from '../../common/binder/product/productLookupFormConfig';
import customerBinderFormConfig from '../../common/binder/customer/customerLookupFormConfig';
import { requiredValidator } from '../../utils/validators/validators';

import DateBinder from '../../common/binder/DateBinder';
import ProductBinder from '../../common/binder/product/ProductBinder';
import CustomerBinder from '../../common/binder/customer/CustomerBinder';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import { FormConfig, FormModel } from '../../common/masterform/types/formConfigTypes';
import PlantBinder from '../../common/binder/PlantBinder';
import { StoreState } from '../../../types/storeTypes';
import { formValueSelector } from 'redux-form';

type FM = FormModel<any, Values, {}>;

const PRODUCT_BINDER_FORM_ID = 'manual-entry-product-binder';
const CUSTOMER_BINDER_FORM_ID = 'manual-entry-customer-binder';

export interface Values {
    company?: string,
    plant?: string,
    productNo?: string,
    productDescription?: string,
    stockUom?: string,
    customerNo?: string,
    customerName?: string,
    periodFrom: Moment
}

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            values: () => ({
                periodFrom: moment().startOf('isoWeek')
            })
        },
        fetchData: (formModel) => manualEntrySaveLines(formModel)
    },
    selector: {
        layout: {
            component: FieldsLayoutWithGeneralRemark,
            generalRemarkFieldName: 'generalRemark',
            numCols: 2,
        },
        fields: {
            company: { validate: requiredValidator, component: CompanyBinder, label: 'common.selector.company', datacy: 'company' },
            plant: { validate: requiredValidator, component: PlantBinder, label: 'common.selector.plant', multi: false, datacy: 'plant' },
            productNo: { validate: requiredValidator,
                binderFormId: PRODUCT_BINDER_FORM_ID, component: ProductBinder, label: 'common.selector.productNo', useForecastSchemeBasedOnCurrentSimulation: true,
                dependsOn: ['company', 'plant'],
                datacy: 'manual-forecasting-entry-product-no',
                resultMapping: {description: 'productDescription', stockUom: 'stockUom'}
            },
            productDescription: { component: TextBinder, label: 'common.selector.productDesc' },
            customerNo: { binderFormId: CUSTOMER_BINDER_FORM_ID, component: CustomerBinder, label: 'common.selector.customerNo', resultMapping: {name: 'customerName'} },
            customerName: { component: TextBinder, label: 'common.selector.customerName' },
            stockUom: { component: TextBinder, label: 'common.selector.stockUom' },
            periodFrom: { validate: requiredValidator, component: DateBinder, label: 'common.selector.periodFrom' }
        },
        commands: [
            { label: 'forecasting.manentry.command.save', type: 'primary', group: -1, disabled: isUnmodified, action: (formModel) => refreshForm(formModel.formId), requiresValid: true },
            { label: 'forecasting.manentry.command.generate', disabled: isProductNoEmpty, action: (formModel) => manualEntryGenerateRows(formModel) }
        ]
    },  
    grid: {
        dataModel: {
            columns
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META })
        ],
        extensions: {
            [EXTS.FORMATTING]: {
                formatters
            },
            [EXTS.EDITING]: true
        }
    },
    children: {
        [PRODUCT_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.product.title',
            form: {
                config: productBinderFormConfig
            }
        },
        [CUSTOMER_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.customer.title',
            form: {
                config: customerBinderFormConfig
            }
        }
    }
};

function isUnmodified(formModel: FM): boolean {
    return formModel.bufferedData === formModel.data;
}

function isProductNoEmpty(formModel: FM, state: StoreState): boolean {
    const selector = formValueSelector(formModel.formId);
    const productNo = selector(state, 'productNo');
    return !productNo;
}

export default formConfig;
