import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Button } from 'reactstrap';

import { clearFormActivePage } from './formActions';

function FormPage(props) {
    return <div className="form-page">
        <div className="header">
            <Button onClick={() => props.clearFormActivePage(props.parentFormId)}>
                <FontAwesome name="arrow-left"/> {' '} Back
            </Button>
            { props.title && <div className="title">{ props.title }</div> }
        </div>
        { React.Children.only(props.children) }
    </div>
}

FormPage.propTypes = {
    title: PropTypes.string,
    parentFormId: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    clearFormActivePage: PropTypes.func.isRequired
};

export default connect(undefined, { clearFormActivePage })(FormPage);