import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';
import { TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import Form from "../../common/masterform/Form";
import { CommandConfig, FormConfig, FormModel } from "../../common/masterform/types/formConfigTypes";
import { apiRequest } from "../../common/commonActions";
import { RequestId } from "../../utils/requests";
import { getApiData } from "../../common/commonSelectors";
import ProductBinder from "../../common/binder/product/ProductBinder";
import DateBinder from "../../common/binder/DateBinder";
import { createDataModel } from "../detailsDataModel";
import { ForecastAccuracyDetailsResponse } from "../../utils/remoting/types/forecastAccuracyRequestTypes";
import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { refreshForm, setFormValues } from '../../common/masterform/formActions';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import { DETAILS_COLUMN_META } from '../columns';
import PlantBinder from '../../common/binder/PlantBinder';
import productBinderFormConfig from '../../common/binder/product/productLookupFormConfig';
import { StoreState } from '../../../types/storeTypes';
import { getFormValues } from '../../common/masterform/formSelectors';


export interface Params {
    productNo: string,
    productDescription: string,
    periodFrom: Moment,
    periodTo: Moment,
    plant: string
}

interface Props {
    params: Params
}

interface Values {
    company?: string[],
    customerNo?: string,
    productNo?: string,
    productDescription?: string,
    customerName?: string,
    periodFrom?: Moment,
    periodTo?: Moment,
    plants?: string[]
}

type FM = FormModel<ForecastAccuracyDetailsResponse, Values, Params>;

const searchCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.search',
    type: 'primary',
    group: -1,
    disabled: false,
    action: (formModel) => refreshForm(formModel.formId)
};

function getInitialValues(p: Params): Values {
    const initialValues: Values = {
        productNo: p.productNo,
        productDescription: p.productDescription
    };

    if (p.periodFrom) {
        initialValues.periodFrom = moment(p.periodFrom);
    }

    if (p.periodTo) {
        initialValues.periodTo = moment(p.periodTo);
    }

    if (p.plant) {
        initialValues.plants = [p.plant];
        initialValues.company = [p.plant]
    }

    return initialValues;
}

function buildApiRequestParams(formModel: FM) {
    let periodFrom, periodTo, plants, productNo;
    if (formModel.bufferedValues) {
        ({ periodFrom, periodTo, plants, productNo } = formModel.bufferedValues);
    } else {
        periodFrom = formModel.values && formModel.values.periodFrom;
        periodTo = formModel.values && formModel.values.periodTo;
        productNo = formModel.values && formModel.values.productNo;
        plants = formModel.values && formModel.values.plants;
    }

    return {
        periodFrom,
        periodTo,
        productNo,
        plants
    };
}

const PRODUCT_BINDER_FORM_ID = 'product-binder-form';

const formConfig: FormConfig<FM> = {
    form: {
        fetchOnMount: true,
        model: {
            data: (state) => getApiData(state, RequestId.FIND_FORECAST_ACCURACY_DETAILS),
            values: (state, formId, params, select) => select(params)(getInitialValues)
        },
        fetchData: (formModel) => apiRequest(RequestId.FIND_FORECAST_ACCURACY_DETAILS, buildApiRequestParams(formModel))
    },
    selector: {
        layout: {
            component: 'grid'
        },
        fields: {
            company: { label: 'common.selector.company', component: PlantBinder },
            plants: { label: 'common.selector.plant', component: PlantBinder },
            productNo: {
                label: 'common.selector.productNo', component: ProductBinder, disabled: true, binderFormId: PRODUCT_BINDER_FORM_ID,
                resultMapping: {description: 'productDescription'}
            },
            productDescription: { label: 'common.selector.productDesc', component: TextBinder, disabled: true },
            customerNo: { label: 'common.selector.customerNo', component: TextBinder },
            customerName: { label: 'common.selector.customerName', component: TextBinder },
            periodFrom: { label: 'common.selector.periodFrom', component: DateBinder },
            periodTo: { label: 'common.selector.periodTo', component: DateBinder }
        },
        commands: [
            searchCommand
        ]
    },
    grid: {
        dataModel: createDataModel,
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: DETAILS_COLUMN_META })
        ],
        extensions: {
            [EXTS.RESIZING]: true,
            [EXTS.STYLING]: true
        }
    },
    children: {
        [PRODUCT_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.product.title',
            form: {
                config: productBinderFormConfig
            }
        }
    }
};

export default function ForecastAccuracyDetails(props:any) {
    const dispatch = useDispatch()
    const values = useSelector((state: StoreState) => getFormValues(state,'forecast-accuracy-details'));

    //If user goes back set forecast-accuracy-details undefined
    useEffect(() => {
        props.router.setRouteLeaveHook(props.route, () => {
            dispatch(setFormValues('forecast-accuracy-details', undefined))
        })
    }, [])

    return <Form
            formId="forecast-accuracy-details"
            config={formConfig as FormConfig<FormModel>}
            params={values}/>
}
