import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';

export default function Text(props) {
    return (     
    <FormGroup>
        <Label>{props.controlLabel}</Label>
        <Input readOnly static value={props.label}/>
    </FormGroup> )
}

Text.propTypes = {
    label: PropTypes.string.isRequired,
    colDim: PropTypes.any
}
