import React from 'react';
import FontAwesome from 'react-fontawesome';

import { FormModel } from "../types/formConfigTypes";

interface Props {
    formModel: FormModel
}

const GridOverlay: React.SFC<Props> = (props: Props) => {
    if(props.formModel.busy) {
        return <div className="form-grid-overlay">
            <FontAwesome spin name="spinner"/>
        </div>;

    } else {
        return null;
    }
}

export default GridOverlay;
