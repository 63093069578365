import { List, Map } from 'immutable';
import moment from 'moment';

import { formatMoment } from '../../utils/helpers';

const NUM_PERIODS_GENERATED = 52;

export function generateLines(uom, periodFrom) {
    const start = moment(periodFrom, 'DD-MM-YYYY');
    
    let period;
    const lines = [];
    for(let i = 0; i < NUM_PERIODS_GENERATED; ++i) {
        const periodType = 'ForecastROPScheme.PeriodType.Week';
        const periodMoment = moment(start).add(i, 'week');
        const startDate = formatMoment(moment(periodMoment).startOf('isoWeek'));
        const endDate = formatMoment(moment(periodMoment).endOf('isoWeek'));
        period = { periodType, periodMoment };

        lines.push(
            Map({
                period,
                uom,
                startDate,
                endDate
            }));
    }

    return List(lines);
}

export function dataToRequestLines(data) {
    return data
        .filter(d => !!d.get('quantity'))
        .map((d) => {
            const quantity = parseFloat(d.get('quantity'));
            const remark = d.get('remark');
            const { periodType, periodMoment } = d.get('period');
            const period = periodMoment.week();
            let year = periodMoment.weekYear();
            return { quantity, remark, period, year, periodType };
        }).toArray();
}

