import { Record } from 'immutable';

export interface SimulationInstanceDetailsResponse {
    id: number,
    version: number,
    uniqueIdentifier: string,
    name: string,
    loadSalesOrder: boolean,
    loadSalesOrderToBeConfirmed: boolean,
    loadForecastLines: boolean,
    loadProductionOrder: boolean,
    loadPurchaseOrder: boolean,
    loadDemandHorizon: number,
    loadShortTermHorizon: number,
    calcMRP: boolean,
    calcCRP: boolean,
    calcReorderPoint: boolean,
    calcForecast: boolean,
    publishProductionOrder: boolean,
    publishPurchaseOrder: boolean,
    publishResourceSetup: boolean,
    publishWorkPeriod: boolean,
    publishReorderPoint: boolean,
    publishForecast: boolean,
    creationDate: string,
    creationUser: string,
    plants: string[],
    templateUid: string,
    templateName: string,
    pending: boolean,
    simulationType: string,
    tableName: string,
    modificationDate: string,
    modificationUser: string,
    planningState: string,
    publishingDate: string,
    basedOnPlanningDefaultTemplate: boolean,
    companyCodes: string[]
}
export const SimulationInstanceRecord = Record<SimulationInstanceDetailsResponse>({
    id: 0,
    version: 0,
    uniqueIdentifier: '',
    name: '',
    loadSalesOrder: false,
    loadSalesOrderToBeConfirmed: false,
    loadForecastLines: false,
    loadProductionOrder: false,
    loadPurchaseOrder: false,
    loadDemandHorizon: 365,
    loadShortTermHorizon: 90,
    calcMRP: false,
    calcCRP: false,
    calcReorderPoint: false,
    calcForecast: false,
    publishProductionOrder: false,
    publishPurchaseOrder: false,
    publishResourceSetup: false,
    publishWorkPeriod: false,
    publishReorderPoint: false,
    publishForecast: false,
    creationDate: '',
    creationUser: '',
    plants: [],
    templateUid: '',
    templateName: '',
    pending: false,
    simulationType: '',
    tableName: '',
    modificationDate: '',
    modificationUser: '',
    planningState: '',
    publishingDate: '',
    basedOnPlanningDefaultTemplate: false,
    companyCodes: []
});

