import moment from 'moment'
import {ForecastUpdateResponse} from '../../utils/remoting/types/forecastOverviewResponseTypes'
import {List, Map, Record} from 'immutable'
import {FM} from '../../reorderPoint/details/formConfig';
import {ReorderPointDetailsResponse} from '../../utils/remoting/types/reorderPointRequestTypes'

const NUM_PERIODS_GENERATED = 52;

export function generateForecastLines(formModel: FM) {

    const data: List<Record<ReorderPointDetailsResponse>> = formModel.data || List();

    const {linesBeforeCurrentPeriod, linesSinceCurrentPeriod} = splitByCurrentPeriod(data);

    const unitOfMeasurement = formModel.bufferedValues.stockUnitOfMeasurement;
    const periodType = 'ForecastROPScheme.PeriodType.Week';

    const generatedLines = generateAndFillGaps(unitOfMeasurement, periodType, linesSinceCurrentPeriod);

    const result = linesBeforeCurrentPeriod.concat(generatedLines);

    return List(result);
}

/**
 * Generate lines since the current period. SLdo it takes into account existing lines.
 * @param unitOfMeasurement
 * @param periodType
 * @param existingLines which already exist
 * @returns {Array} of generated lines
 */
function generateAndFillGaps(unitOfMeasurement: string, periodType: string, existingLines: Record<ForecastUpdateResponse>[]) : any[] {

    let start = existingLines.length > 0 ? dateOf(existingLines[0]) : moment();

    const lines: any[] = [];

    existingLines.forEach((line) => {
        const position = moment().isoWeek(getWeek(line)).year(getYear(line)).diff(start, 'week');

        // Note! We're putting existing line at a specific position, because there can be gaps between existing lines.
        // e.g., we have two lines - line for 1 week and 5 week. So, the gap it's 2, 3 and 4 weeks and we have to
        // provide a possibility to fill their too.
        lines[position] = line;
    });

    for (let i = 0; i < NUM_PERIODS_GENERATED; ++i) {
        const newLineMoment = moment(start).add(i, 'week');

        if (!lines[i]) {
            lines[i] = Map({
                period: newLineMoment.isoWeek(),
                year: newLineMoment.weekYear(),
                periodType,
                startDate: newLineMoment.startOf('isoWeek').toISOString(),
                endDate: newLineMoment.endOf('isoWeek').toISOString(),
                unitOfMeasurement
            });
        }
    }

    return lines;
}

export function dateOf(line: Record<ForecastUpdateResponse>) : moment.Moment {
    return moment()
        .isoWeek(getWeek(line))
        .year(getYear(line))
        .startOf('isoWeek');
}

export function getWeek(line: Record<ForecastUpdateResponse>) : number {
    return line.get('period');
}

export function getYear(line: Record<ForecastUpdateResponse>) : number {
    return line.get('year');
}

export function getDataDiffForGeneralRemark(data: List<Record<ForecastUpdateResponse>>, bufferedData: List<Record<ForecastUpdateResponse>>) : List<any>  {

    const diff = [];

    for (let i = 0; i < data.size; ++i) {
        const b = bufferedData.get(i);
        if (b && b.get('quantity') && !b.get('remark') && !b.get('fixed')) {
            diff.push(Map({index: i, next: b, prev: data.get(i)}));
        }
    }

    return List(diff);
}

/**
 * It checks that a line belongs to a period before the current, using year and week instead of moment() comparision,
 * because a line contains only a ISO date string of moment()
 * @param line for check
 * @returns {boolean} returns true, if a line belongs to a period before the current
 */
export function lineIsBeforeCurrentPeriod(line: Record<ForecastUpdateResponse>) : boolean {
    const currentWeek = moment().isoWeek();
    let currentYear = moment().weekYear();

    if (getYear(line) === currentYear) {
        return getWeek(line) < currentWeek;
    }

    return getYear(line) < currentYear;
}

export function splitByCurrentPeriod(data: List<Record<ReorderPointDetailsResponse>>) {

    const linesBeforeCurrentPeriod: Record<any>[] = [];
    const linesSinceCurrentPeriod: Record<any>[] = [];

    data.forEach((line) => {
        if (lineIsBeforeCurrentPeriod(line)) {
            linesBeforeCurrentPeriod.push(line);
        } else {
            linesSinceCurrentPeriod.push(line);
        }
    });

    return {linesBeforeCurrentPeriod, linesSinceCurrentPeriod};
}
