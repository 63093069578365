import { fromJS } from 'immutable';

export const columns = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'period', width: 130, title: 'common.grid.period', formatter: 'period' },
    { _key: 'algoQuantity', width: 130, title: 'common.grid.algoQuantity' },
    { _key: 'quantity', width: 130, title: 'common.grid.quantity', editable: true, formatter: 'roundNumber'},
    { _key: 'fixed', width: 50, title: 'common.grid.fixed', editable: true, type: 'checkbox'},
    { _key: 'unitOfMeasurement', width: 50, title: 'common.grid.uom' },
    { _key: 'remark', width: 130, title: 'common.grid.remark', editable: true },
    { _key: 'startDate', width: 130, title: 'common.grid.startDate', formatter: 'date' },
    { _key: 'endDate', width: 130, title: 'common.grid.endDate', formatter: 'endDate' }
]);

export const COLUMN_QUANTITY = 3;
export const COLUMN_FIXED = 4;
export const COLUMN_REMARK = 6;

export const COLUMNS_META = new Map([
    ['unitOfMeasurement', {
        translateValue: true
    }]
])
