import { Map } from 'immutable';
import ActionTypes from './reorderPointActions';

export default function(state=Map(), action: {type: string}) {
    switch(action.type) {
        case ActionTypes.RECEIVE_SELECT_STATE:
            return handleReceiveSelectState(state, action);
    }
    return state;
}

function handleReceiveSelectState(state: any, action: any) {
    return state.set('selectState', action.result);
}