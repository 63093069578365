import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Input, Label } from 'reactstrap';

function CheckboxBinder(props) {
    const checked = props.input.value || false;
    return (
        <Label check>
            <Input type="checkbox" checked={checked} disabled={!!props.disabled} onClick={() => props.input.onChange(!checked)} data-cy={props.datacy}/>
            { props.t(props.label) }
        </Label>
    );
}

CheckboxBinder.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.object.isRequired,
    datacy: PropTypes.string,

    // react-i18next
    t: PropTypes.func.isRequired
}

export default withTranslation(['prl'])(CheckboxBinder);
