import { fromJS } from 'immutable';

export const columns = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'actions', width: 100, title: 'Actions', actions: [{
        cssClass: 'btn btn-primary fa',
        icon: 'fa-chart-line',
        eventName: 'action-show-forecast-details'
    }]},
    { _key: 'plant', width: 130, title: 'common.grid.plant'},
    { _key: 'productNo', width: 130, title: 'common.grid.productNo' },
    { _key: 'productDescription', width: 130, title: 'common.grid.productDesc' },
    { _key: 'forecastROPScheme', width: 130, title: 'common.grid.forecastROPScheme' },
    { _key: 'company', width: 130, title: 'common.grid.company' },
    { _key: 'productGroup', width: 130, title: 'common.grid.productGroup' },
    { _key: 'stockUnitOfMeasurement', width: 130, title: 'common.grid.stockUOM' },
    { _key: 'customerName', width: 130, title: 'common.grid.customerName' },
    { _key: 'forecastAlgo', width: 130, title: 'common.grid.forecastAlgo' },
    { _key: 'toBePublished', width: 130, title: 'common.grid.toBePublished', type: 'checkbox', editable: true }
]);

export const COLUMNS_META = new Map([
    ['company', {
        translateHeader: true
    }],
    ['productNo', {
        translateHeader: true
    }],
    ['productDescription', {
        translateHeader: true
    }],
    ['productGroup', {
        translateHeader: true
    }],
    ['stockUnitOfMeasurement', {
        translateHeader: true,
        translateValue: true
    }],
    ['customerName', {
        translateHeader: true
    }],
    ['forecastROPScheme', {
        translateHeader: true,
        translateValue: true
    }],
    ['forecastAlgo', {
        translateHeader: true,
        translateValue: true
    }],
    ['toBePublished', {
        translateHeader: true,
    }],
    ['plant', {
        translateHeader: true
    }]
]);

/**
 * Used for editing 'toBePublished' column and for moving 'toBePublished' column to the end of the grid after dynamically added period columns
 * @type {number}
 */
export const COLUMN_TO_BE_PUBLISHED = 11;
