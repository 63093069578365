import React from 'react';
import { connect } from 'react-redux';
import { List, Map, Set } from 'immutable';
import { bindActionCreators } from 'redux';
import { PowergridComponent } from '@pearlchain/component-lib-powergrid';
import { ContextMenu } from 'react-contextmenu';
import ContextMenuItem from './ContextMenuItem';
import { withTranslation } from 'react-i18next';

import { DataModelConfig, FormModel } from '../types/formConfigTypes';
import { DataModel } from '../types/gridDataModelTypes';

import { createExtensions, EXTS } from '../../../utils/powergrid/powergridHelpers';
import { ApplyClassesAdapter } from './ApplyClassesAdapter';
import { columnSettingsToSorting } from '../../../utils/powergrid/sorting';
import { createGridDataModelSelector } from './gridHelpers';
import GridOverlay from './GridOverlay';
import FormEventObserver from '../FormEventObserver';
import { FORM_EVENT } from '../formEvents';
import { applyDecoratorsOverColumns, applyDecoratorsOverRows, GridDataDecorator, GridDataDecoratorCreator } from '../../../utils/datamodel/GridDataDecorator';
import { mountFormGrid, setFormBufferedData, setFormGridSorting, setFormSelection, triggerFormEvent, unmountFormGrid } from '../formActions';
import { Column } from '../../../../types/columnTypes';
import { arrayEquals } from '../../../utils/helpers';
import { StoreState } from '../../../../types/storeTypes';

interface BaseProps {
    formId: string,
    formModel: FormModel,
    extensions?: { [key: string]: any },
}

interface OwnProps extends BaseProps {
    dataModel: DataModelConfig,
    decorators?: GridDataDecoratorCreator[]
}

interface Props extends BaseProps {
    mountFormGrid: typeof mountFormGrid,
    unmountFormGrid: typeof unmountFormGrid,
    setFormBufferedData: typeof setFormBufferedData,
    setFormSelection: typeof setFormSelection,
    setFormGridSorting: typeof setFormGridSorting,
    events?: any[],
    dataModel: DataModel,
    dispatch: any,
    columns?: List<Column>,
    rows?: List<Map<string, any>>,
    decorators?: GridDataDecorator[],
    t: any,
    frozenColumnsLeft?: number
}

interface State {
    forceRerenderCount: number,
    extensions: { [key: string]: any },
    events: List<Map<string, any>>|undefined,
    columns?: List<Column>,
    decorators?: GridDataDecorator[],
    rows?: List<Map<string, any>>,
    columnsDecorated?: List<Column>,
    rowsDecorated?: List<Map<string, any>>
}

interface PowergridValueChangedEvent {
    eventType: string,
    params: PowergridValueChangedParams
}

export interface PowergridValueChangedParams {
    row: number,
    column: number,
    value: any
}

const EVENT_KEYS = Set([
    FORM_EVENT.GRID_VALUE_CHANGED
]);

class FormGrid extends React.Component<Props, State> {
    private applyClassesAdapter: ApplyClassesAdapter;

    constructor(props: Props) {
        super(props);
        this.onApplyClasses = this.onApplyClasses.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
        this.onSortColumnsChanged = this.onSortColumnsChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.applyClassesAdapter = new ApplyClassesAdapter(props.dataModel);

        this.state = {
            forceRerenderCount: 0,
            // performance: initialize the extensions once
            extensions: this.createExtensions(props),
            events: this.createEvents()
        };
    }

    static getDerivedStateFromProps(props: Props, state: State): Partial<State>|null {
        const columns = props.columns;
        const rows = props.rows;
        const decorators = props.decorators;

        let modified = false;
        let columnsDecorated = state.columnsDecorated;
        let rowsDecorated = state.rowsDecorated;
        let forceRerenderCount = state.forceRerenderCount;

        const recalculateDecorators = 
            !arrayEquals(decorators, state.decorators);

        // if rows were changed, apply decorators and update the state with the new rows
        if(rows && (recalculateDecorators || rows !== state.rows)) {
            if(decorators) {
                rowsDecorated = applyDecoratorsOverRows(decorators, rows);    
            } else {
                rowsDecorated = rows;
            }

            modified = true;
        }

        // if columns were changed, apply decorators and update the state with the new cols
        if(columns && (recalculateDecorators || !columns.equals(state.columns))) {
            if(decorators) {
                columnsDecorated = applyDecoratorsOverColumns(decorators, columns);
            } else {
                columnsDecorated = columns;
            }
            
            forceRerenderCount += 1;
            modified = true;
        }

        if(modified) {
            // update the state, triggering a re-render
            return {
                columns,
                decorators,
                rows,
                columnsDecorated,
                rowsDecorated,
                forceRerenderCount
            }

        } else {
            // nothing changed, don't update the state
            return null;
        }
    }

    componentDidMount() {
        this.props.mountFormGrid(this.props.formId, this.getGridId());
    }

    componentWillUnmount() {
        this.props.unmountFormGrid(this.props.formId);
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if(this.props.dataModel !== prevProps.dataModel) {
            this.applyClassesAdapter.updateDataModel(this.props.dataModel);
        }

        if(this.state.forceRerenderCount !== prevState.forceRerenderCount) {
            this.props.mountFormGrid(this.props.formId, this.getGridId());
        }
    }
    
    render() {
        // Changing the key forces React to completely remount the Powergrid, after the columns changed.
        // This is a hack, because doesn't seem any easy way to change Powergrid columns after initialization.
        const rootId = this.getGridId();

        return (
            <div className="selector-grid" data-cy="master-grid">
                <GridOverlay formModel={this.props.formModel}/>

                <FormEventObserver
                    formId={this.props.formId}
                    eventKeys={EVENT_KEYS}
                    onEvent={this.onValueChanged}/>

                <PowergridComponent
                    key={rootId}
                    columns={this.state.columnsDecorated}
                    extensions={this.state.extensions}
                    input={this.state.rowsDecorated}
                    rootId={rootId}
                    frozenColumnsLeft={this.props.frozenColumnsLeft}
                    dispatch={this.props.dispatch}
                    events={this.state.events}/>

                { this.renderContextMenu() }
            </div>
        );
    }

    renderContextMenu() {
        const contextMenuExt = this.props.extensions && this.props.extensions[EXTS.CONTEXT_MENU];
        if(!contextMenuExt) return;

        const commands = contextMenuExt.commands;
        if(!commands) return;
        
        return (
            <ContextMenu id={'ctx-' + this.props.formId}>
                { commands.map((command: any, idx: number) =>
                    <ContextMenuItem key={idx} formModel={this.props.formModel} { ...command }/>
                )}
            </ContextMenu>);
    }

    createEvents(): List<Map<string, any>>|undefined {
        const events = this.props.events;
        return events && List(events.map((eventName) => Map({
            name: eventName,
            action: triggerFormEvent.bind(null, this.props.formId, eventName)
        })));
    }

    createExtensions(props: Props): { [key: string]: any } {
        const extensions = props.extensions;
        return createExtensions(extensions, {
            onRowSelected: this.onRowSelected,
            onApplyClasses: this.onApplyClasses,
            onSortColumnsChanged: this.onSortColumnsChanged
        }, props.formId);
    }

    onApplyClasses(record: { id: string }, column: { key: number }, callback: (className: string|undefined) => void) {
        this.applyClassesAdapter.onApplyClasses(record, column, callback);
    }

    onRowSelected(_event: any, rowId: string) {
        const item = this.props.dataModel.getItemById('' + rowId);
        if(item) {
            this.props.setFormSelection(this.props.formId, item);
        }
    }

    onSortColumnsChanged(columnSettings: any) {
        const sorting = columnSettingsToSorting(columnSettings, this.state.columns);
        this.props.setFormGridSorting(this.props.formId, sorting);
    }

    onValueChanged(event: PowergridValueChangedEvent) {
        const {columns = List(), columnsDecorated = List()} = this.state;
        const params = event.params;

        // According to https://pearlchain.atlassian.net/browse/VCO-436 we have to take into account a case,
        // when we have hidden columns. Incoming event has only row, column and value. And when column is hidden,
        // we have to correct actual column number.
        if (columns.size !== columnsDecorated.size) {
            const columnKey = columnsDecorated.get(params.column).get('_key');
            const colIdx = columns.findIndex((column) => column.get('_key') === columnKey);
            if (colIdx != null) params.column = colIdx;
        }

        const dataModel = this.props.dataModel;

        if (dataModel.modifyFormData === undefined) return;
        
        const data = dataModel.modifyFormData(params);

        if (data) {
            const version = this.props.formModel.version || 0;
            this.props.setFormBufferedData(this.props.formId, data, version);
        }
    }

    getGridId(): string {
        return `pg-${this.props.formId}` +  `-${this.state.forceRerenderCount}`;
    }
}

export default connect(
    (state: StoreState, { dataModel }: OwnProps) => {
        const gridDataModelSelector = createGridDataModelSelector(dataModel);        
        return (state: StoreState, { decorators: decoratorCreators, formModel }: OwnProps) =>  {
            const dataModel = gridDataModelSelector(formModel);

            let decorators: GridDataDecorator[]|undefined;
            if(decoratorCreators) {
                decorators = decoratorCreators.map((creator) => creator(formModel, state));
            }

            return {
                columns: dataModel.getColumns(),
                rows: dataModel.getRows(),
                decorators,
                dataModel
            }
        }
    },
    (dispatch) => ({
        dispatch,
        ...bindActionCreators({
            mountFormGrid,
            unmountFormGrid,
            setFormBufferedData,
            setFormSelection,
            setFormGridSorting
        }, dispatch)
    })
)(withTranslation(['prl'])(FormGrid));
