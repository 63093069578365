import { List, Map, Record } from "immutable";

export interface EventState {
    eventType: string,
    params?: any
}

export interface EventsState {
    count: number,
    observers: number,
    offset: number,
    queue: List<Record<EventState>>
}

export interface ActivePageState {
    no: number,
    params: any
}

export interface FormState {
    refreshCount: number,
    events?: Record<EventsState>,
    data?: any,
    bufferedData?: any,
    values?: any,
    selection?: any,
    version?: number,
    visible: boolean,
    activePage?: Record<ActivePageState>,
    sortColumns?: any,
    params?: any,
    sync?: string
};  

export interface FormsState {
    form: Map<string, Record<FormState>>
}

/**
 * Immutable record factories
 */

export const form = Record<FormState>({
    refreshCount: 0,
    events: undefined,
    data: undefined,
    bufferedData: undefined,
    values: undefined,
    selection: undefined,
    version: undefined,
    visible: false,
    activePage: undefined,
    sortColumns: undefined,
    params: undefined,
    sync: undefined
});

export const forms = Record<FormsState>({
    form: Map()
});

export const events = Record<EventsState>({
    count: 0,
    observers: 0,
    offset: 0,
    queue: List()
});

export const eventObserver = Record<EventState>({
    eventType: '',
    params: undefined
});

export const activePage = Record<ActivePageState>({
    no: 0,
    params: undefined
});
