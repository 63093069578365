import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '../Modal';
import Form from './Form';
import { isFormVisible } from './formSelectors';
import { hideForm } from './formActions';

function ChildFormModal(props) {
    return <Modal
        showing={props.visible}
        title={props.title}
        className={props.className}
        onClose={() => props.hideForm(props.formId)}>
        <Form
            formId={props.formId}
            config={props.form.config}
            parent={props.parent}
            component={props.form.component}/>
    </Modal>
}

ChildFormModal.propTypes = {
    // own-props
    formId: PropTypes.string.isRequired,
    parent: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    className: PropTypes.string,

    // connect
    hideForm: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
}

export default connect(
    (state, { formId }) => ({ visible: isFormVisible(state, formId) }),
    { hideForm }
)(ChildFormModal);
