import moment from 'moment';
import { FormModel } from '../common/masterform/types/formConfigTypes';

export const FORM_ID = 'forecast-details-form';

export const Field = {
    PRODUCT_ID: 'productId',
    COMPANY_ID: 'companyId',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    PLANT: 'plant'
};

export function getRequestParams(formModel: FormModel) {
    let values = formModel.bufferedValues;
    
    if(!values) {
        // if the form hasn't been initialized yet, use the form model initial values
        values = formModel.values;
    }

    const formStartDate = values[Field.START_DATE];
    const formEndDate = values[Field.END_DATE];
    const productId = values[Field.PRODUCT_ID];
    const companyId = values[Field.COMPANY_ID];
    const plant = values[Field.PLANT];
    const startDate = moment(formStartDate, 'DD-MM-YYYY').valueOf();
    const endDate = moment(formEndDate, 'DD-MM-YYYY').valueOf();
    
    return { productId, companyId, plant, startDate, endDate };
}

export function getInitialValues(forecastGroup: any) {
    const now = moment();
    const startDate = moment(now).subtract(6, 'months');
    const endDate = moment(now).add(1, 'months');
    const productId = forecastGroup.get('productId');
    const plant = forecastGroup.get('plant');

    return {
        productId,
        companyId: 1,
        startDate,
        endDate,
        plant
    };
}
