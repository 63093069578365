import React from 'react';
import { Field } from 'redux-form';
import { TextBinder } from '@pearlchain/component-lib-ui';

import CheckboxBinder from '../common/binder/CheckboxBinder';
import Binder from '../common/binder/Binder';
import { numericValidator, optional, boolValidator } from '../utils/validators/validators';
import { SimulationTypeResponse, SimulationTypeConfig } from '../utils/remoting/types/simulationTypeRequestTypes';
import { Record, Seq } from 'immutable';
import { SimulationTemplateOverviewResponse } from '../utils/remoting/types/simulationTemplateRequestTypes';

const validateNumber = optional(numericValidator({ min: 0 }));

const validateBoolean = boolValidator();

export function generateFields(formFields: Record<SimulationTypeResponse>, group: string, disabled: boolean): Array<any> {
    const entries = formFields.toSeq().entrySeq() as Seq.Indexed<[keyof SimulationTypeResponse, Record<SimulationTypeConfig>]>;

    return entries
        .filter(([key, value]) => {
            return key != 'externalReference' && (typeof value !== 'string' && value.get('group') === group);
        })
        .sortBy(([key, value]) => {
            return value.get('order');
        })
        .map(([key, value]) => {
            let binder = null;
            let validate = null;

            if (typeof value.get('defaultValue') === 'boolean') {
                binder = CheckboxBinder;
                validate = validateBoolean;

            } else if (typeof value.get('defaultValue') === 'number') {
                binder = TextBinder;
                validate = validateNumber;

            } else {
                binder = TextBinder;
            }

            if (binder) {       
                return (<Field name={key} validate={validate}
                    disabled={disabled ? disabled : !value.get('editable')}
                    component={Binder}
                    binder={binder}
                    inline={true}
                    label={`simulationinstance.selector.${key}`}/>);
            }
        }).toArray();
}

export function convertToListOfInitialValues(simulationType: Record<SimulationTypeResponse>, selectedTemplate: Record<SimulationTemplateOverviewResponse>) {

    const fieldMap = simulationType.toSeq().map((value, key) => {

        if (selectedTemplate && selectedTemplate.has(key)) {
            return selectedTemplate.get(key);
        } else if (key === 'externalReference') {
            return value;
        } else {
            return (value as Record<SimulationTypeConfig>).get('defaultValue');
        }
    })
    
    return fieldMap.toJS();
}
