import * as Imm from "immutable";

import i18n from "../../../i18n";
import { Column, ColumnMeta } from "../../../types/columnTypes";
import { GridDataDecorator, GridDataDecoratorCreator } from "./GridDataDecorator";

/**
 * Grid data decorator that translates rows and columns
 */

 export interface Params {
    // whether all headers should be translated. Default: false
    allHeaders?: boolean,
    
    // columns to translate
    columns?: Map<string, ColumnMeta>
 }

export class TranslateGridDataDecorator implements GridDataDecorator {
    private _params: Params;

    constructor(params: Params) {
        this._params = params;
    }

    private _shouldTranslateColumnHeader(key: string): boolean {
        if(this._params.allHeaders === true) {
            return true;
        }

        const columns = this._params.columns;
        if(columns) {
            const col = columns.get(key);
            return !!(col && col.translateHeader);

        } else {
            return false;
        }
    }

    modifyColumn(column: Column): Column {
        if(this._shouldTranslateColumnHeader(column.get('_key'))) {
            let title = column.get('title');
            title = this._translate(title);
            return column.set('title', title);

        } else {
            return column;
        }
    }

    modifyRow(row: Imm.Map<string, any>): Imm.Map<string, any> {
        const columns = this._params.columns;
        if(columns === undefined) return row;

        for(let [key, meta] of columns.entries()) {
            let value = row.get(key);

            if(meta.translateValue && typeof value === 'string') {
                value = this._translate(value);
                row = row.set(key, value);
            }
        };

        return row;
    }

    private _translate(input: string): string {
        return i18n.t(input, { ns: ['prl'] });
    }
}

export const createTranslateDecorator = (params: Params): GridDataDecoratorCreator => {
    const decorator = new TranslateGridDataDecorator(params);
    return () => decorator;
}
