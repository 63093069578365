import { fromJS, List } from 'immutable';
import { Column } from '../../../../types/columnTypes';

export const columns: List<Column> = fromJS([
    { _key: 'no', width: 150, title: 'common.binder.product.grid.no', idField: true },
    { _key: 'source', width: 150, title: 'common.binder.product.grid.source' },
    { _key: 'description', width: 150, title: 'common.binder.product.grid.description' },
    { _key: 'productGroup', width: 150, title: 'common.binder.product.grid.productGroup' },
    { _key: 'status', width: 150, title: 'common.binder.product.grid.status' },
    { _key: 'stockUom', width: 150, title: 'common.binder.product.grid.stockUom' },
    { _key: 'uom2', width: 150, title: 'common.binder.product.grid.uom2' },
    { _key: 'uom3', width: 150, title: 'common.binder.product.grid.uom3' },
    { _key: 'uom4', width: 150, title: 'common.binder.product.grid.uom4' },
    { _key: 'warehouse', width: 150, title: 'common.binder.product.grid.warehouse' },
    { _key: 'altDescription', width: 150, title: 'common.binder.product.grid.altDescription' },
    { _key: 'remark', width: 150, title: 'common.binder.product.grid.remark' }
]);
