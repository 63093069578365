
import { List, Map, Record } from 'immutable';
import { FormsState, forms, FormState, events, eventObserver, form, EventsState, activePage } from './types/storeTypes';

import ActionTypes, {
    UnmountFormAction,
    RefreshFormAction,
    SetFormDataAction,
    SetFormValuesAction,
    SetFormSelectionAction,
    SetFormBufferedDataAction,
    SetFormGridSortingAction,
    ShowFormAction,
    HideFormAction, 
    TriggerFormEventAction,
    MountEventObserverAction,
    UnmountEventObserverAction,
    ConsumeEventsAction,
    ClearActivePageAction,
    SetActivePageAction,
    SetFormSyncKeyAction } from './formActions';

export default function(state: Record<FormsState> = forms(), action: any): Record<FormsState> {
    switch(action.type) {
        case ActionTypes.UNMOUNT_FORM:
            return unmountForm(state, action);
        case ActionTypes.REFRESH_FORM:
            return refreshForm(state, action);
        case ActionTypes.SET_FORM_DATA:
            return setFormData(state, action);
        case ActionTypes.SET_FORM_VALUES:
            return setFormValues(state, action);
        case ActionTypes.SET_FORM_SELECTION:
            return setFormSelection(state, action);
        case ActionTypes.SET_FORM_BUFFERED_DATA:
            return setFormBufferedData(state, action);
        case ActionTypes.SET_FORM_GRID_SORTING:
            return setFormGridSorting(state, action);
        case ActionTypes.SHOW_FORM:
            return showForm(state, action);
        case ActionTypes.HIDE_FORM:
            return hideForm(state, action);
        case ActionTypes.TRIGGER_FORM_EVENT:
            return triggerFormEvent(state, action);
        case ActionTypes.MOUNT_EVENT_OBSERVER:
            return mountEventObserver(state, action);
        case ActionTypes.UNMOUNT_EVENT_OBSERVER:
            return unmountEventObserver(state, action);
        case ActionTypes.CONSUME_EVENTS:
            return consumeEvents(state, action);
        case ActionTypes.CLEAR_FORM_ACTIVE_PAGE:
            return clearActivePage(state, action);
        case ActionTypes.SET_FORM_ACTIVE_PAGE:
            return setActivePage(state, action);
        case ActionTypes.SET_FORM_SYNC_KEY:
            return setFormSyncKey(state, action);
        default: return state;
    }
}

function unmountForm(state: Record<FormsState>, { formId }: UnmountFormAction) {
    return state.update('form', (forms) => forms ? forms.delete(formId) : forms);
}

function refreshForm(state: Record<FormsState>, { formId }: RefreshFormAction) {
    return updateForm(state, formId, (form) => form.update('refreshCount', increment));
}

function setFormData(state: Record<FormsState>, { formId, data }: SetFormDataAction) {
    return updateForm(state, formId, (form) => form
        .set('data', data)
        .delete('bufferedData'));
}

function setFormValues(state: Record<FormsState>, { formId, values }: SetFormValuesAction) {
    return updateForm(state, formId, (form) => form.set('values', values));
}

function setFormSelection(state: Record<FormsState>, { formId, selection }: SetFormSelectionAction) {
    return updateForm(state, formId, (form) => form.set('selection', selection));
}

function setFormBufferedData(state: Record<FormsState>, { formId, newData, version }: SetFormBufferedDataAction) {
    return updateForm(state, formId, (form) => form
        .set('bufferedData', newData)
        .set('version', version));
}

function setFormGridSorting(state: Record<FormsState>, { formId, sortColumns }: SetFormGridSortingAction) {
    return updateForm(state, formId, (form) => form.set('sortColumns', sortColumns));
}

function showForm(state: Record<FormsState>, { formId, params }: ShowFormAction) {
    return updateForm(state, formId, (form) => form.set('visible', true).set('params', params));
}

function hideForm(state: Record<FormsState>, { formId }: HideFormAction) {
    // just set the visibility instead of removing the child config from the store
    // because the child form can take some time to disappear, in which time the formModel will be invalid
    return updateForm(state, formId, (form) => form.set('visible', false));
}

function triggerFormEvent(state: Record<FormsState>, { formId, eventType, params }: TriggerFormEventAction) {
    return updateFormEvents(state, formId, (events) => events
        .set('count', events.get('observers'))
        .update('queue', (queue = List()) => queue.push(eventObserver({ eventType, params }))));
}

function mountEventObserver(state: Record<FormsState>, { formId }: MountEventObserverAction) {
    return updateFormEvents(state, formId, (events) =>
        events.update('observers', increment));
}

function unmountEventObserver(state: Record<FormsState>, { formId }: UnmountEventObserverAction) {
    return updateFormEvents(state, formId, (events) =>
        events.update('observers', decrement));
}

function consumeEvents(state: Record<FormsState>, { formId, offset }: ConsumeEventsAction) {
    return updateFormEvents(state, formId, (events) => {
        const prevOffset = events.get('offset');
        if(prevOffset !== undefined && prevOffset > offset)
            return events;

        events = events.update('count', decrement);
        const count = events.get('count');

        if(!count) {
            const queue = events.get('queue');
            const queueSize = queue === undefined ? 0 : queue.size;
            events = events.set('queue', List()).set('offset', offset + queueSize);
        }

        return events;
    });
}

function clearActivePage(state: Record<FormsState>, { formId }: ClearActivePageAction) {
    return updateForm(state, formId, (form) => form ? form.delete('activePage') : form);
}

function setActivePage(state: Record<FormsState>, { formId, page, params }: SetActivePageAction) {
    return updateForm(state, formId, (form) =>
        form.set('activePage', activePage({
            no: page,
            params
        })
    ));
}

function setFormSyncKey(state: Record<FormsState>, { formId, syncKey }: SetFormSyncKeyAction) {
    return updateForm(state, formId, (form) => form
        .set('sync', syncKey)
        .update('refreshCount', increment));
}

function increment(v: number|undefined): number {
    return (v || 0) + 1;
}

function decrement(v: number|undefined): number {
    return v ? v - 1 : 0;
}

function updateForm(state: Record<FormsState>, formId: string,
    fn: (form: Record<FormState>) => Record<FormState>): Record<FormsState> {
    return state.update('form', (forms = Map()) => forms.update(formId, (fm = form()) => fn(fm)));
}

function updateFormEvents(state: Record<FormsState>, formId: string,
    fn: (events: Record<EventsState>) => Record<EventsState>): Record<FormsState> {
    return updateForm(state, formId, (form) => form.update('events', (evts = events()) => fn(evts)));
}
