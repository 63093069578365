import React from 'react';
import { connect } from 'react-redux';
import * as Imm from 'immutable';

import { getFormEventsQueue, getFormEventsOffset } from './formSelectors';
import { mountEventObserver, unmountEventObserver, consumeEvents } from './formActions';
import { createSelector } from 'reselect';
import { EventState } from './types/storeTypes';
import { StoreState } from '../../../types/storeTypes';

export type EventHandler = (evt: any) => void

export interface OwnProps {
    formId: string,
    eventKeys: Imm.Set<string>,
    onEvent: EventHandler
}

export interface Props extends OwnProps {
    mountEventObserver: typeof mountEventObserver,
    unmountEventObserver: typeof unmountEventObserver,
    consumeEvents: typeof consumeEvents,
    formEvents: Imm.List<Imm.Record<EventState>>,
    formEventsOffset: number,
}

class FormEventObserver extends React.Component<Props> {

    componentDidMount() {
        this.props.mountEventObserver(this.props.formId);
    }

    componentWillUnmount() {
        this.props.unmountEventObserver(this.props.formId);
    }

    componentDidUpdate(prevProps: Props) {
        if(this.props.formEvents !== prevProps.formEvents && this.props.formEvents.size) {
            this.props.consumeEvents(this.props.formId, this.props.formEventsOffset);

            for(let evt of this.props.formEvents) {
                if(this.props.eventKeys === undefined || this.props.eventKeys.has(evt.get('eventType'))) {
                    this.props.onEvent(evt);
                }
            }
        }
    }

    render() {
        return null;
    }
}

function createFormEventsSelector() {
    let prevOffset = 0;

    /**
     * Creates a selector, returning a subset of form-events to
     * provide to the component
     */
    
    return createSelector(getFormEventsQueue, getFormEventsOffset,
        (formEvents, offset) => {
            if(formEvents == undefined)
                return Imm.List<Imm.Record<EventState>>();

            const listOffset = Math.max(0, prevOffset - offset);

            if(listOffset < formEvents.size) {
                prevOffset = offset + formEvents.size;

                return formEvents
                    .slice(listOffset, formEvents.size);

            } else {
                return Imm.List<Imm.Record<EventState>>();
            }
        });
}

export default connect(
    (state: StoreState, { formId }: OwnProps) => {
        const getFormEvents = createFormEventsSelector();

        return (state: StoreState) => ({
            formEventsOffset: getFormEventsOffset(state, formId),
            formEvents: getFormEvents(state, formId)
        })
    },
    { mountEventObserver, unmountEventObserver, consumeEvents }
)(FormEventObserver)
