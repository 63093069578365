import { StoreState } from "../../../types/storeTypes";
import { DynamicProperty, FormModel } from "../../common/masterform/types/formConfigTypes";

export function resolveDynamicProperty<T>(value: DynamicProperty<T>, formModel: FormModel, state: StoreState): T {
    if(typeof value === 'function') {
        return (value as (formModel: FormModel, state: StoreState) => T)(formModel, state);
    } else {
        return value;
    }
}
