import { SimulationTypeResponse } from '../../utils/remoting/types/simulationTypeRequestTypes';

const ActionTypes = {
    GET_FORM_FIELDS: 'vco.simulationtemplate.GET_FORM_FIELDS',
    RECEIVE_FORM_FIELDS: 'vco.simulationtemplate.RECEIVE_FORM_FIELDS',
    CLEAR_FORM_FIELDS: 'vco.simulationtemplate.CLEAR_FORM_FIELDS',
    RECEIVE_INITIAL_VALUES: 'vco.simulationtemplate.RECEIVE_INITIAL_VALUES',
    CLEAR_INITIAL_VALUES: 'vco.simulationtemplate.CLEAR_INITIAL_VALUES',
    FIND_SIMULATION_TEMPLATE: 'vco.simulationtemplate.FIND_SIMULATION_TEMPLATE',
    CREATE_SIMULATION_TEMPLATE: 'vco.simulationtemplate.CREATE_SIMULATION_TEMPLATE',
    DELETE_SIMULATION_TEMPLATE: 'vco.simulationtemplate.DELETE_SIMULATION_TEMPLATE',
    FETCH_SIMULATION_TEMPLATES: 'vco.simulationtemplate.FETCH_SIMULATION_TEMPLATES',
};

export default ActionTypes;

export function findSimulationTemplate(templateUid: string) {
    return { type: ActionTypes.FIND_SIMULATION_TEMPLATE, templateUid };
}

export function fetchFormFields(typeUid: string) {
    return {
        type: ActionTypes.GET_FORM_FIELDS,
        typeUid
    }
}

export function receiveFormFields(result: Map<string, any>) {
    return {
        type: ActionTypes.RECEIVE_FORM_FIELDS,
        result
    }
}

export function clearFormFields() {
    return {
        type: ActionTypes.CLEAR_FORM_FIELDS
    }
}

export function receiveInitialValues(result: SimulationTypeResponse) {
    return {
        type: ActionTypes.RECEIVE_INITIAL_VALUES,
        result
    }
}

export function clearInitialValues() {
    return {
        type: ActionTypes.CLEAR_INITIAL_VALUES
    }
}

export function createSimulationTemplate(formId: string, parentFormId: string, values: any) {
    return {
        type: ActionTypes.CREATE_SIMULATION_TEMPLATE,
        formId,
        parentFormId,
        values
    };
}
export function deleteTemplateByUID(templateUid: string, formId: string) {
    return {
        type: ActionTypes.DELETE_SIMULATION_TEMPLATE,
        templateUid,
        formId
    }
}

export function fetchSimulationTemplates() {
    return {
        type: ActionTypes.FETCH_SIMULATION_TEMPLATES,
    }
}
