import Imm from 'immutable';

export const Path = {
    OVERVIEW_DATA: ['overview', 'data'],
    FORECAST_GROUPS: ['forecast-groups'],
    forecastDetailsLines(forecastGroupId) {
        return ['forecast-details', forecastGroupId, 'lines'];
    }
};

export function getForecastGroups(state) {
    return state.forecasting.getIn(Path.FORECAST_GROUPS, Imm.List());
}

export function getForecastDetailsLines(state, forecastGroupId) {
    return state.forecasting.getIn(Path.forecastDetailsLines(forecastGroupId));
}
