import { all } from 'redux-saga/effects';
import { sagas as componentLibSagas } from '@pearlchain/component-lib-common';

import forecastingSagas from '../components/forecasting/forecastingSagas';
import simulationTemplateSagas from '../components/simulation/simulationTemplate/simulationTemplateSagas';
import simulationInstanceSagas from '../components/simulationInstance/simulationInstanceSagas';
import reorderPointSagas from '../components/reorderPoint/reorderPointSagas';
import forecastDetailsSagas from '../components/forecast-details/sagas';
import commonSagas from '../components/common/commonSagas';
import propertySagas from '../components/home/propertySagas';

export default function *() {
    yield all([
        componentLibSagas(),
        simulationInstanceSagas(),
        reorderPointSagas(),
        forecastingSagas(),
        forecastDetailsSagas(),
        simulationTemplateSagas(),
        propertySagas(),
        commonSagas()
    ]);
}
