import { Map, List } from 'immutable';

export function withGeneralRemark(dataDiff, generalRemark) {
    return dataDiff.map((diff) => {
        const a = diff.get('next');
        const b = diff.get('prev');
        if(compare(a.get('remark'), b ? b.get('remark') : undefined)) {
            return diff.set('next', a.set('remark', generalRemark));
        } else return diff;
    });
}

export function getDataDiff(data, bufferedData) {
    const diff = [];
    for(let i = 0; i < data.size; ++i) {
        const a = data.get(i);
        const b = bufferedData.get(i);
        if(!compare(a ? a.get('quantity') : undefined, b ? b.get('quantity') : undefined)
        || !compare(a ? a.get('remark') : undefined, b ? b.get('remark') : undefined)
        || !compare(a ? a.get('fixed') : undefined, b ? b.get('fixed') : undefined)) {
            diff.push(Map({
                index: i,
                next: b,
                prev: a
            }));
        }
    }

    return List(diff);
}

export function modifiedLinesMissingRemarks(dataDiff) {
    for(let diff of dataDiff) {
        const a = diff.get('next');
        const b = diff.get('prev');
        if(compare(a.get('remark'), b ? b.get('remark') : undefined)) {
            return true;
        }
    }
    return false;
}

export function compare(a, b) {
    return a == b || (!a && !b);
}
