import { fromJS, List } from 'immutable';
import { ColumnMeta, Column } from '../../types/columnTypes';

export const KEY_ID = 'id';
export const KEY_TYPE = 'type';
export const KEY_RUN_DATE = 'runDate';
export const KEY_TOTAL_FORECAST = 'totalForecast';
export const KEY_TOTAL_ACTUAL = 'totalActual';
export const KEY_DEVIATION = 'deviation';
export const KEY_ACCURACY_EVOLUTION = 'accuracyEvolution';
export const KEY_PLANT = 'plant';

export const columns: List<Column> = fromJS([
    { _key: 'id', width: 0, idField: true },
    { _key: 'actions', width: 100, title: 'Actions', actions: [{
        cssClass: 'btn btn-primary fa',
        icon: 'fa-chart-line',
        eventName: 'action-show-forecast-details'
    }]},
    { _key: 'productNo', width: 130, title: 'common.grid.productNo' },
    { _key: 'productDescription', width: 150, title: 'common.grid.productDesc' },
    { _key: 'plant', width: 130, title: 'common.grid.plant' },
    { _key: 'customerNo', width: 130, title: 'common.grid.customerNo' },
    { _key: 'customerName', width: 130, title: 'common.grid.customerName' }
]);

export const detailsColumns: List<Column> = fromJS([
    { _key: KEY_ID, width: 0, idField: true },
    { _key: KEY_TYPE, width: 130, title: 'common.grid.type' },
    { _key: KEY_PLANT, width: 130, title: 'common.grid.plant' },
    { _key: KEY_RUN_DATE, width: 130, title: 'forecastAccuracy.grid.forecastRunDate' },
    { _key: KEY_TOTAL_FORECAST, width: 130, title: 'forecastAccuracy.grid.total' },
    { _key: KEY_TOTAL_ACTUAL, width: 130, title: 'forecastAccuracy.grid.totalActual' },
    { _key: KEY_DEVIATION, width: 130, title: 'forecastAccuracy.grid.deviation' },
    { _key: KEY_ACCURACY_EVOLUTION, width: 130, title: 'forecastAccuracy.grid.accEvo' }
]);

export const DETAILS_COLUMN_META = new Map<string, ColumnMeta>([
    [KEY_TYPE, {
        translateValue: true
    }]
]);
