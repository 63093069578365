import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import ActionTypes, { receiveFormFields, receiveInitialValues } from './simulationTemplateActions';
import { getApiData, getCommonApiData } from '../../common/commonSelectors';
import { RequestId } from '../../utils/requests';
import { convertToListOfInitialValues } from '../formFieldGenerator';
import { performApiRequest } from '../../common/commonSagas';
import { clearApiData } from '../../common/commonActions';
import { refreshForm, hideForm } from '../../common/masterform/formActions';
import { success, error } from 'react-notification-system-redux';
import i18n from '../../../i18n';
import { callRequestHandler, receiveCachedData, invokeRequestHandler } from '@pearlchain/component-lib-common';

export default function () {
    return all([
        takeEvery(ActionTypes.GET_FORM_FIELDS, handleGetFormFields),
        takeEvery(ActionTypes.FIND_SIMULATION_TEMPLATE, handleFindSimulationTemplate),
        takeEvery(ActionTypes.CREATE_SIMULATION_TEMPLATE, handleCreateSimulationTemplate),
        takeEvery(ActionTypes.DELETE_SIMULATION_TEMPLATE, handleDeleteSimulationTemplate),
        takeEvery(ActionTypes.FETCH_SIMULATION_TEMPLATES, fetchSimulationInstances),
    ]);
}

function *handleDeleteSimulationTemplate({templateUid, formId}) {
    const result = yield* performApiRequest(RequestId.DELETE_SIMULATION_TEMPLATE, [templateUid]);
    if (!result || !result[0]) {
        return;
    }
    const messageObj = result[0].messages[0];
    if (messageObj.type === 'ERROR') {
        const translatedMessage = i18n.t(messageObj.message, {
            ns: ['prl'], replace: {
                'simulations': messageObj.params[0],
                'rest': messageObj.params[1]
            }
        });
        yield put(error({
            title: 'Couldn\'t delete template',
            message: '' + translatedMessage,
            autoDismiss: false
        }));
    } else if ((messageObj.type === 'INFO')) {
        const translatedMessage = i18n.t(messageObj.message, {
            ns: ['prl'], replace: {
                'name': messageObj.params[0]
            }
        });
        yield put(success({title: '' + translatedMessage}));
        yield put(refreshForm(formId));
    }
}

function *handleGetFormFields({ typeUid }) {
    const simulationType = yield call(getSimulationTypeFromStore, typeUid);
    if (simulationType) {
        const initialValues = convertToListOfInitialValues(simulationType[1]);
        yield put(receiveFormFields(simulationType[1]));
        yield put(receiveInitialValues(initialValues));
    }        
}

function *getSimulationTypeFromStore(typeUid) {
    let simulationTypes = yield select(getCommonApiData, RequestId.LIST_SIMULATION_TYPES);
    if (!simulationTypes) {
        const [simulationTypesResponse] = yield* callRequestHandler(RequestId.LIST_SIMULATION_TYPES);
        if (!simulationTypesResponse) {
            console.error("There is no simulation types.");
            return null;
        }
        yield put(receiveCachedData(RequestId.LIST_SIMULATION_TYPES, simulationTypesResponse));
        simulationTypes = simulationTypesResponse;
    }
    return simulationTypes.findEntry((value) => { return value.get('externalReference') === typeUid});
}

function *handleFindSimulationTemplate({ templateUid }) {
    yield* performApiRequest(RequestId.FIND_SIMULATION_TEMPLATE, [templateUid]);
    const simulationTemplate = yield select(getApiData, RequestId.FIND_SIMULATION_TEMPLATE);
    if (simulationTemplate) {
        const simulationType = yield call(getSimulationTypeFromStore, simulationTemplate.get('externalReference'));
        if (simulationType) {
            const initialValues = convertToListOfInitialValues(simulationType[1], simulationTemplate);
            yield put(receiveFormFields(simulationType[1]));
            yield put(receiveInitialValues(initialValues));
        }        
    }
}

function *handleCreateSimulationTemplate({ formId, parentFormId, values }) {
    // perform the HTTP request to create the new template
    const params = Object.assign({}, values, {companyCodes: [values.companyCodes]});
    const [ result ] = yield* performApiRequest(RequestId.CREATE_SIMULATION_TEMPLATE, [params]);
    if(!result) return;

    // hide the details dialog and refresh the main form
    yield put(refreshForm(parentFormId));
    yield put(hideForm(formId));
    yield put(success({ title: 'Simulation Template Created' }));

    // clear the simulation templates list
    yield put(clearApiData(RequestId.LIST_SIMULATION_TEMPLATES));

}

function *fetchSimulationInstances() {
    yield put(invokeRequestHandler(RequestId.LIST_SIMULATION_TEMPLATES));
}

