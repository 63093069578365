import RequestBuilder from '../RequestBuilder';
import { List, Map } from 'immutable';

const url = {
    FIND_REORDER_POINTS: 'sec/api/reorderpoint/find'
};

const sortColumnToRequestKey:{[index:string]: string}  = {
    companyName: 'COMPANY_NAME',
    plant: 'PLANT',
    productNo: 'PRODUCT_NO',
    productDescription: 'PRODUCT_DESCRIPTION',
    productGroup: 'PRODUCT_GROUP',
    forecastROPScheme: 'FORECAST_ROP_SCHEME',
    uom: 'UOM',
    previousQuantity: 'PREV_QUANTITY',
    algoQuantity: 'ALGO_QUANTITY',
    quantity: 'QUANTITY',
    remark: 'REMARK',
    toBePublished: 'TO_BE_PUBLISHED'
};

export function findReorderPoints(requestBuilder: RequestBuilder, formValues: any, sortColumns: List<Map<string, any>>) {
    const requestParams = Object.assign({}, formValues);
    requestParams.sort = toSortParams(sortColumns);

    return requestBuilder
        .post(url.FIND_REORDER_POINTS)
        .setBodyJson(requestParams)
        .toImmutable();
}

function toSortParams(sortColumns: List<Map<string, any>>) {
    if(!sortColumns) return;
    
    const result = [];
    for(let sortColumn of sortColumns) {
        const key = sortColumn.get('key');
        const direction = sortColumn.get('direction');
        const paramKey = sortColumnToRequestKey[key];
        if(paramKey) {
            const asc = direction === 'ascending';
            result.push({ key: paramKey, asc });
        }
    }

    return result;
}
