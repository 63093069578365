import { Map } from 'immutable';

import NewSimulationInstanceFormSelector from '../new/NewSimulationInstanceFormSelector';
import { SimulationInstanceDetailsResponse } from '../../utils/remoting/types/simulationInstanceRequestTypes';
import { FormModel, FormConfig } from '../../common/masterform/types/formConfigTypes';

type FM = FormModel<SimulationInstanceDetailsResponse, any, {}>;

function getValues(selection: Map<string, any>) {
    return selection.toJS();
}

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            values: (state, formId, params, select) => select(params.parent.selection)(getValues)
        }
    },
    selector: {
        layout: {
            component: NewSimulationInstanceFormSelector,
        },
        enableReinitialize: true,
        readOnly: true,
        commands: [
            { label: 'common.selector.actions.auditLog' }
        ]
    }
}

export default formConfig;

