import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import {dataToOptionsWithSorting} from '../../utils/binder/binderHelpers';
import { RequestId } from '../../utils/requests';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';
import { List } from 'immutable';

function PlantBinder(props: any) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_PLANTS}
        render={(opts: unknown) => <SelectBinder multi options={dataToOptionsWithSorting(opts as List<string> | undefined)} { ...props }/>}
        />
}

export default PlantBinder;
