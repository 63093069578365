import { SelectBinder, TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import { apiRequest } from '../../commonActions';
import { RequestId } from '../../../utils/requests';
import { getApiData } from '../../commonSelectors';
import { refreshForm, completeFormProvideResult } from '../../masterform/formActions';
import { columns } from './customerLookupColumns';
import { EXTS } from '../../../utils/powergrid/powergridHelpers';
import { FieldConfig, FormConfig, FormModel } from '../../masterform/types/formConfigTypes';
import { resolveResultFieldValueMap } from '../BinderHelper';
import {createTranslateDecorator} from "../../../utils/datamodel/TranslateGridDataDecorator";
import {COLUMNS_META} from "../../../forecasting/manualentry/columns";

export const FORM_ID = 'customer-binder';

interface OwnFormModelValues {
}

interface FormModelValues extends OwnFormModelValues {
    companyCodes: FieldConfig,
    no: FieldConfig,
    name: FieldConfig,
    vatNo: FieldConfig,
    address: FieldConfig,
    postalCode: FieldConfig,
    city: FieldConfig,
    country: FieldConfig,
    department: FieldConfig,
    limit: FieldConfig
}

interface InitialValues extends OwnFormModelValues {
    customer: boolean;
}

export interface Params {
    customerNo: string,
    resultFormId: string,
    resultField: string,
    resultMapping: { [key: string]: string }
}

function getInitialValues(params: Params) {
    return { customer: true, no: params.customerNo };
}

const formParams: FormConfig<FormModel<any, OwnFormModelValues>> =  {
    form: {
        fetchOnMount: false,
        model: {
            values: (state, formId, params, select) => select(params)(getInitialValues),
            data: (state) => getApiData(state, RequestId.FIND_PARTNERS)
        },
        fetchData: (formModel) => apiRequest(RequestId.FIND_PARTNERS, formModel.bufferedValues)
    },
    selector: {
        layout: {
            component: 'grid',
            numCols: 2
        },
        fields: {
            companyCodes: { label: 'common.selector.company', component: CompanyBinder, multi: true },
            no: { label: 'common.binder.customer.selector.no', component: TextBinder },
            name: { label: 'common.binder.customer.selector.name', component: TextBinder },
            vatNo: { label: 'common.binder.customer.selector.vatNo', component: TextBinder },
            address: { label: 'common.binder.customer.selector.address', component: TextBinder },
            postalCode: { label: 'common.binder.customer.selector.postalCode', component: TextBinder },
            city: { label: 'common.binder.customer.selector.city', component: TextBinder }, 
            country: { label: 'common.binder.customer.selector.country', component: TextBinder },
            department: { label: 'common.binder.customer.selector.department', component: TextBinder },
            limit: { label: 'common.binder.selector.limit', component: SelectBinder, options: [
                    { label: '10', value: 10 },
                    { label: '20', value: 20 },
                    { label: '50', value: 50 },
                    { label: '100', value: 100 }
            ]}
        },
        commands: [
            {
                label: 'common.binder.customer.command.search',
                action: (formModel) => refreshForm(formModel.formId)
            },
            {
                label: 'common.binder.customer.command.select',
                disabled: (formModel) => !formModel.selection,
                action: (formModel) => {
                    const resultMapping = resolveResultFieldValueMap('no', formModel);
                    return completeFormProvideResult(
                        formModel.formId,
                        formModel.params.resultFormId,
                        resultMapping);
                }
            }
        ]
    },
    grid: {
        dataModel: {
            columns,
            getId: (dataItem) => dataItem.get('no'),
            rowIdField: 'id'
        },
        extensions: {
            [EXTS.RESIZING]: true,
            [EXTS.SELECTION]: true
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META })
        ]
    }
}

export default formParams;
