import { all, put, select, takeEvery } from 'redux-saga/effects';
import { success } from 'react-notification-system-redux';

import { requestInDetailRefreshMaster } from '../common/commonActions';
import ActionTypes, { receiveSelectState, SelectDeselectAllReorderPointsActionType } from './reorderPointActions';
import { hideForm, refreshForm, setFormBufferedData } from '../common/masterform/formActions';
import { RequestId } from '../utils/requests';
import { performApiRequest } from '../common/commonSagas';
import { FormId as RopFormId } from './overview/ReorderPointOverview';

export default function () {
    return all([
        takeEvery(ActionTypes.CALCULATE_PARTIAL_REORDER_POINTS, handleCalculatePartial),
        takeEvery(ActionTypes.SELECT_DESELECT_ALL_REORDER_POINTS, handleSelectDeselectAllReorderPoints)
    ]);
}


function *handleCalculatePartial({ formModel }: any) {
    let ropSelector = yield select((state) => state.form[formModel.parent.formId].values);
    if (ropSelector == null) ropSelector = {};
    const selector = {
        productDescription: ropSelector.productDescription,
        productGroup: ropSelector.productGroup,
        inventoryPlanner: ropSelector.inventoryPlanner, 
        scheme: ropSelector.forecastRopScheme,
        plants: ropSelector.plants,
        productNo: ropSelector.productNo
    }
    const amendedData = Object.assign({}, formModel, {
        bufferedValues: Object.assign({}, 
        formModel.bufferedValues, {selector})
    });
    yield put(requestInDetailRefreshMaster(amendedData, 
        RequestId.CALCULATE_REORDERPOINT_OR_FORECAST, 
        'Reorderpoints Calculated'))
}


function *handleSelectDeselectAllReorderPoints(action: SelectDeselectAllReorderPointsActionType) {
    const selectState = yield select((state: any) => {
        return state.reorderPoint.get('selectState');
    });

    yield put(receiveSelectState(!selectState));

    if (action.data.size > 0) {
        const newData = action.data.map((entry: any) => {
            return entry.set('toBePublished', !selectState);
        });

        yield put(setFormBufferedData(action.formId, newData, action.version));
    }
}