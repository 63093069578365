import * as Imm from "immutable";
import moment from 'moment';

import { DataModel } from "../common/masterform/types/gridDataModelTypes";
import { Column } from "../../types/columnTypes";

import { ForecastAccuracyDetailsResponse, ForecastRunResponse } from "../utils/remoting/types/forecastAccuracyRequestTypes";
import { detailsColumns, KEY_ACCURACY_EVOLUTION, KEY_DEVIATION, KEY_PLANT, KEY_RUN_DATE, KEY_TOTAL_ACTUAL, KEY_TOTAL_FORECAST, KEY_TYPE } from "./columns";
import { PeriodColumnsBuilder } from "../utils/datamodel/PeriodColumnsBuilder";
import { formatMoment, formatPeriod } from "../utils/helpers";
import { calculateForecastDetailsStats, ForecastDetailsStats } from "./helpers/forecastAccuracyDetailsStats";

class DetailsDataModel implements DataModel<any> {

    private _rowIndexById: Map<string, number>;
    private _rows: Imm.List<Imm.Map<string, any>>;
    private _columns: Imm.List<Column>;

    constructor(
        data: ForecastAccuracyDetailsResponse,
        bufferedData: ForecastAccuracyDetailsResponse|undefined
    ) {
        if(bufferedData === undefined) {
            bufferedData = data;
        }

        const stats = calculateForecastDetailsStats(bufferedData.actual, bufferedData.forecasts);
        const columnsBuilder = new PeriodColumnsBuilder(this._createGroupColumns);
        const actualRow = this._getActualRow(bufferedData, columnsBuilder);
        const [forecastRows, rowIndexById] = this._getForecastRows(stats, bufferedData, columnsBuilder);
        
        this._rowIndexById = rowIndexById;

        this._rows = Imm.List<Imm.Map<string, any>>()
            .push(actualRow)
            .concat(forecastRows)
            .toList();

        this._columns = detailsColumns
            .concat(columnsBuilder.getColumns())
            .toList();
    }

    private _createGroupColumns(period: number, year: number, colIdx: number) {
        const title = formatPeriod(year, period);
        const column = Imm.Map({ _key: 'period-' + colIdx, title, width: 130 });
        return [column];
    }

    private _getActualRow(data: ForecastAccuracyDetailsResponse, periodColumns: PeriodColumnsBuilder) {
        let row: Imm.Map<string, any> = Imm.Map({ [KEY_TYPE]: 'Actual', id: 'actual' });

        for(let order of data.actual) {
            const period = order.period;
            const year = order.year;

            const groupColumn = periodColumns.getOrCreateColumn(period, year);
            row = row.set(groupColumn.columns[0].get('_key'), order.quantity);
        }

        return row;
    }

    private _getForecastRows(stats: ForecastDetailsStats[], data: ForecastAccuracyDetailsResponse, periodColumns: PeriodColumnsBuilder): [Imm.List<Imm.Map<string, any>>, Map<string, number>] {
        const rowsById = new Map<string, number>();
        const rows: Imm.Map<string, any>[] = [];
        
        let i = 0;
        for(let forecast of data.forecasts) {
            let row: Imm.Map<string, any> = this._createRow(stats[i++], forecast);
            const group = forecast.groups[0];

            for(let calculation of group.calculations) {
                const p = moment(calculation.startDate);
                const year = p.weekYear();
                const period = p.week();

                const groupColumn = periodColumns.getOrCreateColumn(period, year);
                row = row.set(groupColumn.columns[0].get('_key'), calculation.quantity);
            }

            rowsById.set('' + forecast.runId, rows.length + 1);
            rows.push(row);
        }

        return [Imm.List(rows), rowsById];
    }

    private _createRow(stats: ForecastDetailsStats, forecast: ForecastRunResponse) {
        const runDate = formatMoment(moment(forecast.runDate));
        const accEvolution = stats.accuracyEvolution;
        const group = forecast.groups[0];

        return Imm.Map({
            id: '' + forecast.runId,
            [KEY_PLANT]: group.plant,
            [KEY_TYPE]: group.forecastScheme,
            [KEY_RUN_DATE]: runDate,
            [KEY_DEVIATION]: stats.deviation.toFixed(2),
            [KEY_TOTAL_ACTUAL]: stats.totalActual,
            [KEY_TOTAL_FORECAST]: stats.totalForecast,
            [KEY_ACCURACY_EVOLUTION]: accEvolution !== undefined ? accEvolution.toFixed(2) : undefined
        });
    }

    private _getRowById(id: string) {
        const idx = this._rowIndexById.get(id);
        if(idx !== undefined) {
            return this._rows.get(idx);
        }
    }

    getRows(): Imm.List<Imm.Map<string, any>> {
        return this._rows;
    }

    getColumns(): Imm.List<Column> {
        return this._columns;
    }

    getItemById(id: string): any {
        return undefined;
    }

    hasItem(id: string): boolean {
        return this._rowIndexById.has(id);
    }

    shouldApplyClasses(id: string, column: { key: number }): boolean {
        const targetColumn = this._columns.get(column.key);
        if(targetColumn) {
            const columnKey: string = targetColumn.get('_key');
            return columnKey.startsWith(KEY_ACCURACY_EVOLUTION);

        } else {
            return false;
        }
    }

    getClass(record: { id: string }, column: { key: number }): string|undefined {
        const row = this._getRowById(record.id);
        if(row === undefined) return;

        const columnKey: string = this._columns.get(column.key)!.get('_key'); 
        const deviationText: string|undefined = row.get(columnKey);

        if(deviationText != undefined) {
            const deviationAmt = parseFloat(deviationText);
            return deviationAmt > 0 ? 'pg-cell-positive' : deviationAmt < 0 ? 'pg-cell-negative' : undefined;
        }
    }
}

const EMPTY_RESPONSE: ForecastAccuracyDetailsResponse = {
    forecasts: [],
    actual: []
}

export function createDataModel(data: ForecastAccuracyDetailsResponse, bufferedData: ForecastAccuracyDetailsResponse|undefined): DataModel<any> {
    return new DetailsDataModel(data, bufferedData || EMPTY_RESPONSE);
}
