import { all, call, takeEvery, put } from "redux-saga/effects";
import { ActionType, receiveProperties } from "./propertyActions";

const URL = 'api/properties.json';

export default function sagas() {
    return all([
        takeEvery(ActionType.FETCH_PROPERTIES, handleFetchProperties)
    ]);
}

function *handleFetchProperties() {
    const result: { [key: string]: unknown } = yield call(() => fetch(URL).then(r => r.json()));
    yield put(receiveProperties(result));
}
