import React from 'react';
import { connect } from 'react-redux';
import Props from 'prop-types';
import { Field, formValueSelector } from 'redux-form';

import { StoreState } from '../../../types/storeTypes';

import { resolveDynamicProperty } from '../../utils/masterform/formHelpers';
import { FieldConfig } from '../masterform/types/formConfigTypes';
import Binder from '../binder/Binder';


interface DependsDictionary {
    [key: string]: string;
}

interface StateProps {
    disabled: boolean,
    dependsOn?: DependsDictionary
}

interface OwnProps {
    formId: string,
    formModel: any,
    inline: boolean,
    fieldConfig: FieldConfig,
}

type Props = OwnProps & StateProps;

function FieldWrapper(props: Props) {
    const { name, label, component, validate, ...rest } = props.fieldConfig;
    const formId = props.formId;
    const inline = props.inline;

    const disabled = props.disabled ? props.disabled : false;
    const dependsOn = props.dependsOn;
    const binderProps = Object.assign(rest, { disabled, dependsOn });

    return <Field
        formId={formId}
        name={name}
        label={label}
        binder={component}
        binderProps={binderProps}
        inline={inline}
        validate={validate}
        component={Binder}/>
}

export default connect(
    (state: StoreState, {formModel, fieldConfig}: OwnProps) => {
        const props:StateProps = {
            disabled: fieldConfig.disabled ? resolveDynamicProperty(fieldConfig.disabled, formModel, state) : false,
            dependsOn: undefined
        };

        if (fieldConfig.dependsOn) {
            const dependsOn:DependsDictionary = {};
            for (let i = 0; i < fieldConfig.dependsOn.length; i++) {
                const field = fieldConfig.dependsOn[i];
                dependsOn[field] = formValueSelector(formModel.formId)(state, field);
            }
            props.dependsOn = dependsOn;
        }
        return props;
    })
(FieldWrapper);
