import React from 'react';
import { connect } from 'react-redux';
import Modal from '../../Modal';
import { StoreState } from '../../../../types/storeTypes';
import Form from '../../masterform/Form';
import productLookupFormConfig from './productLookupFormConfig';
import { isFormVisible } from '../../masterform/formSelectors';
import { hideForm } from '../../masterform/formActions';

export const PRODUCT_BINDER_FORM_ID = 'product-binder';

interface OwnProps { }

interface Props extends OwnProps {
    showing: boolean,
    hideForm: (formId: string) => void
}

function ProductLookupModal(props: Props) {
    return (
        <Modal title="product" showing={props.showing} onClose={() => props.hideForm(PRODUCT_BINDER_FORM_ID)}>
            <Form formId={PRODUCT_BINDER_FORM_ID} config={productLookupFormConfig}/>
        </Modal>);
}

export default connect(
    (state: StoreState, props: OwnProps) => ({
        showing: isFormVisible(state, PRODUCT_BINDER_FORM_ID)
    }),
    { hideForm }
)(ProductLookupModal);
