import { CommandConfig, FormModel } from '../../common/masterform/types/formConfigTypes';
import { AUDIT_LOG_TYPE, SHAPEIT_PROPERTY_ID } from '../../utils/auditLogHelpers';
import { showForm } from '../../common/masterform/formActions';
import { FM } from './formConfig';
import { isCurrentSimulationSuitable, isLive, isNothingSelected } from '../../utils/simulationHelpers';
import { selectDeselectAllReorderPoints } from '../reorderPointActions';
import { StoreState } from '../../../types/storeTypes';

export const CALCULATE_ROPS_FORM_ID = 'calculate-rops';
export const VIEW_ROPS_FORM_ID = 'view-rops';
export const AUDIT_LOG_FORM_ID = 'rop-audit-log';

function isLiveOrNotROPSimulation(formModel: FormModel, state: StoreState) {
    return isLive(formModel) || !isCurrentSimulationSuitable(state, (simulation) => simulation.calcReorderPoint);
}

export const recalculateCommand: CommandConfig<FM> = {
    label: 'reorderpoint.selector.actions.recalculate',
    datacy: 'recalculate-rop',
    disabled: isLiveOrNotROPSimulation,
    action: () => showForm(CALCULATE_ROPS_FORM_ID, { initialValues: { calcROP: true }})
};

export const viewdetailsCommand: CommandConfig<FM> = {
    label: 'reorderpoint.selector.actions.viewdetails',
    datacy: 'view-details-rop',
    disabled: isNothingSelected,
    action: () => showForm(VIEW_ROPS_FORM_ID, {})
};

export const selectAllCommand = {
    label: 'reorderpoint.selector.actions.selectdeselectall',
    disabled: isLive,
    action: (formModel: FM) => selectDeselectAllReorderPoints(
        formModel.formId, formModel.bufferedData, formModel.version ? formModel.version : 0
    ),
    datacy: 'select-deselect-all'
};

export const auditlogCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.auditLog',
    disabled: isNothingSelected,
    datacy: 'show-audit-log-btn',
    action: (formModel: FM) => {
        const isLive = !formModel.simulationUid;
        const params = isLive
            ? getParamsInLive(formModel.selection) : getParamsInSnapshot(formModel.selection);

        return showForm(AUDIT_LOG_FORM_ID, params);
    }
};

function getParamsInLive(selection: any) {
    const parentId = selection.get('productId');
    const plant = selection.get('plant');

    const fields = [
        SHAPEIT_PROPERTY_ID.REORDER_POINT_QUANTITY,
        SHAPEIT_PROPERTY_ID.SAFETY_STOCK
    ];

    return {
        type: AUDIT_LOG_TYPE.PRODUCT,
        snaType: undefined,
        fields,
        plant,
        parentId
    }
}

function getParamsInSnapshot(selection: any) {
    const parentId = selection.get('id');

    return {
        type: AUDIT_LOG_TYPE.REORDER_POINT_CALCULATION,
        snaType: AUDIT_LOG_TYPE.SNA_REORDER_POINT_CALCULATION,
        plant: undefined,
        parentId
    };
}
