import ColorScheme from 'color-scheme';

const scheme = new ColorScheme();
const lineColors: string[] = scheme.from_hue(0)
    .scheme('tetrade')
    .colors();

const predefinedColors: string[] = ['#0040ff', '#bf4040', '#0f9890']
const finalColorsArray: string[] = predefinedColors.concat(lineColors);

export function getLineColor(idx: number) {
    return finalColorsArray[idx % lineColors.length];
}

export function translate(x: number, y: number) {
    return `translate(${x},${y})`;
}
