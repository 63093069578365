import { Record, List } from 'immutable';
import { getFormValues as getReduxFormValues } from 'redux-form';
import { createSelector, createStructuredSelector } from 'reselect';

import { EventState, EventsState, FormState, ActivePageState, form } from './types/storeTypes';
import { StoreState } from '../../../types/storeTypes';

import { getSimulationContextUid } from '../commonSelectors';
import { ModelConfig, FormModel } from './types/formConfigTypes';

export type MemoizingSelector<T> = <T1 extends any[]>(...value: T1) => (fn: (...x: T1) => T) => T

export type Provider<T, P=any> = (state: StoreState, formId: string, params: P, select: MemoizingSelector<T>) => T

export type ProviderSelector<T> = (state: StoreState, formId: string, params: any) => T

export type Providers<T, P=any> = { [K in keyof T]: Provider<T[K], P> };

export type ProviderSelectors<TProvided> = (store: StoreState, formId: string, params: any) => { version: number, values: TProvided }

export function getForm(state: StoreState, formId: string): Record<FormState>|undefined {
    return state.masterForm.get('form').get(formId);
}

export function getFormEvents(state: StoreState, formId: string): Record<EventsState>|undefined {
    const form = getForm(state, formId);
    return form ? form.get('events') : undefined;
}

export function getFormEventsQueue(state: StoreState, formId: string): List<Record<EventState>> {
    const formEvents = getFormEvents(state, formId);
    return formEvents ? formEvents.get('queue') : List();
}

export function getFormEventsOffset(state: StoreState, formId: string): number {
    const formEvents = getFormEvents(state, formId);
    return formEvents ? formEvents.get('offset') : 0;
}

export function getFormRefreshCount(state: StoreState, formId: string): number {
    const form = getForm(state, formId);
    return form ? form.get('refreshCount') : 0;
}

export function getFormReduxFormValues(state: StoreState, formId: string): any {
    return getReduxFormValues(formId)(state) as any;
}

export function getFormData<TData = any>(state: StoreState, formId: string): TData|undefined {
    const form = getForm(state, formId);
    return form ? form.get('data') : undefined;
}

export function getFormVersion(state: StoreState, formId: string): number|undefined {
    const form = getForm(state, formId);
    return form ? form.get('version') : undefined;
}

export function getFormBufferedData<TData = any>(state: StoreState, formId: string): TData|undefined {
    const form = getForm(state, formId);
    return form ? form.get('bufferedData') : undefined;
}

export function getFormValues<TValues = any>(state: StoreState, formId: string): TValues|undefined {
    const form = getForm(state, formId);
    return form ? form.get('values') : undefined;
}

export function getFormSelection<TDatum = any>(state: StoreState, formId: string): TDatum|undefined {
    const form = getForm(state, formId);
    return form ? form.get('selection') : undefined;
}

export function getFormParams<TParams = any>(state: StoreState, formId: string): TParams|undefined {
    const form = getForm(state, formId);
    return form ? form.get('params') : undefined;
}

export function isFormVisible(state: StoreState, formId: string): boolean {
    const form = getForm(state, formId);
    return form ? form.get('visible') : false;
}

export function getFormActivePage(state: StoreState, formId: string): Record<ActivePageState>|undefined {
    const form = getForm(state, formId);
    return form ? form.get('activePage') : undefined;
}

export function getFormSyncKey(state: StoreState, formId: string): string|undefined {
    const form = getForm(state, formId);
    return form ? form.get('sync') : undefined;
}

export function getSimulationUid(state: StoreState, formId: string): string|undefined {
    return getSimulationContextUid(state);
}

export function createProviderSelector<T>(fn: Provider<T>): ProviderSelector<T> {
    let prevArgs: any[]|undefined,
        prev: T|undefined;

    function getPrevious(): T {
        return prev!;
    }

    function select<TArgs extends any[]>(...args: TArgs): (callback: (...args: TArgs) => T) => T {
        let modified;

        if(prevArgs !== undefined) {
            modified = false;
            for(let i in args) {
                if(prevArgs[i] !== args[i]) {
                    modified = true;
                    break;
                }
            } 

        } else {
            modified = true;
        }

        if (!modified) {
            return getPrevious;
        }

        prevArgs = args;
        return (callback: (...args: TArgs) => T) => {
            prev = callback.apply(null, args) as T;
            return prev;
        }
    }

    return (state: StoreState, formId: string, params: any): T => {
        if(!fn.length && prevArgs) {
            return prev!;

        } else {
            return fn(state, formId, params, select);
        }
    }
}

export function createProvidersSelector<TProvided>(providers: Providers<TProvided>): ProviderSelectors<TProvided> {
    const providerSelectors: { [K in keyof TProvided]: ProviderSelector<TProvided[K]> } = {} as any;
    for(let key in providers) {
        providerSelectors[key] = createProviderSelector(providers[key]);
    }

    let version = -1;
    return createSelector(
        createStructuredSelector(providerSelectors),
        (providedValues) => {
            // track changes
            let nextVersion = ++version;

            return {
                version: nextVersion,
                values: providedValues
            };
        })
}

const createParamsSelector = (formId: string) => {
    let prev: any;
    return (state: StoreState, ownProps: any) => {
        // merge params passed as component props with params in the store
        const formParams = getFormParams(state, formId);
        const params = { ...formParams, ...ownProps };

        for(let key in params) {
            if(prev == null || prev[key] !== params[key]) {
                prev = params; break;
            }
        }
        return prev;
    }
}

export function createFormModel(formId: string, modelConfig: ModelConfig): (state: StoreState, ownProps: any) => FormModel {
    const providersSelector = createProvidersSelector(modelConfig.model);
    const paramsSelector = createParamsSelector(formId);
    return createSelector(
        (state: StoreState) => getForm(state, formId),
        (state: StoreState, params: any) => providersSelector(state, formId, paramsSelector(state, params)),
        (state: StoreState) => getFormReduxFormValues(state, formId),
        (state: StoreState) => modelConfig.busy != null && (modelConfig.busy(state)),
        (state: StoreState) => getSimulationUid(state, formId),
        paramsSelector,
        (fm, provided, bufferedValues, busy, simulationUid, params): FormModel => {
            // use defaults
            fm = fm || form();

            // use "values" as "bufferedValues"
            if(bufferedValues == null)
                bufferedValues = fm.get('values');

            // use "data" as "bufferedData"
            if(fm.get('bufferedData') == null)
                fm = fm.set('bufferedData', fm.get('data'));

            // reset the buffered state if the provided version
            // and store version are different
            if(provided.version !== fm.get('version')) {
                if('data' in provided.values) {
                    fm = fm.set('bufferedData', provided.values.data);
                }
            }

            // extract the parent passed in from the component props
            const parent = params && params.parent;

            // build the form-model
            return {
                ...fm.toObject(),
                formId,
                bufferedValues,
            busy,
                simulationUid,
                version: provided.version,
                params,
                parent,
                ...provided.values
            } as any;
        }
    );
}
