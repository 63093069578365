import React, { useEffect } from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestId } from '../../utils/requests';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';
import { useDispatch } from 'react-redux';
import { fetchSimulationTemplates } from '../../simulation/simulationTemplate/simulationTemplateActions';

const mapper = (data) => {
    return data ? data.map((d) => ({ value: d.get('uniqueIdentifier'), label: d.get('name') })).toArray() : [];
};

export default function TemplateBinder(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchSimulationTemplates())
    }, [])

    return <RequestIdFetcher
        requestId={RequestId.LIST_SIMULATION_TEMPLATES}
        render={(opts) => <SelectBinder options={mapper(opts)} { ...props }/>}
        />;
}
