import moment from 'moment';

import { ForecastRunResponse, SalesOrderLineResponse } from "../../utils/remoting/types/forecastAccuracyRequestTypes";
import { LookupPeriodYear } from "../../utils/datamodel/LookupPeriodYear";

export interface ForecastDetailsStats {
    totalActual: number,
    totalForecast: number,
    deviation: number,
    accuracyEvolution: number|undefined    
};

export function calculateActualValuesLookup(actual: SalesOrderLineResponse[]) {
    const lookup = new LookupPeriodYear<number>();
    for(let salesOrderLine of actual) {
        lookup.set(salesOrderLine.year, salesOrderLine.period, salesOrderLine.quantity);
    }
    return lookup;
}

export function calculateForecastDetailsStats(actual: SalesOrderLineResponse[], forecasts: ForecastRunResponse[]) {
    const n = forecasts.length;
    const results: ForecastDetailsStats[] = new Array<ForecastDetailsStats>(n);
    const actualValuesLookup = calculateActualValuesLookup(actual);

    for(let i = 0; i < n; ++i) {
        const forecast = forecasts[i];

        let totalForecast = 0,
            totalActual = 0,
            totalDifference = 0;
        
        // there should only ever be one group
        const group = forecast.groups[0];

        for(let calculation of group.calculations) {
            const forecastValue = calculation.quantity;
            totalForecast += forecastValue;

            const m = moment(calculation.startDate);
            const actualValue = actualValuesLookup.get(m.year(), m.week());

            if(actualValue !== undefined) {
                totalActual += actualValue;
                totalDifference += Math.abs(actualValue - forecastValue);
            }
        }

        const deviation = 100 * totalDifference / totalActual;
        const accuracyEvolution = i > 0 ? results[i - 1].deviation - deviation : undefined;

        results[i] = {
            accuracyEvolution,
            deviation,
            totalActual,
            totalForecast
        };
    }

    return results;
}
