import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Location, History } from 'history';
import Notifications, { warning } from 'react-notification-system-redux';
import { getAuthTokenPayload, TokenPayload } from '@pearlchain/component-lib-common';

import Navigation from './components/page/Navigation';
import i18n from './i18n';
import { StoreState } from './types/storeTypes';
import { pages, getInitialPageId } from './components/page/pages';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { SimpleStackNavigation, SubPage, useProperties } from "@pearlchain/stackbase-common";

library.add(fas);

const EXPIRING_NOTIFICATION_GAP = 30000;

function redirectToLoginPage(properties: {[key: string]: string}) {
    window.location.assign(properties?.urlLogin+ "#/?redirect=" + window.location.href);
}

interface Props {
    warning: (notification: any) => void,
    location: Location,
    history: History,
    router: any,
    notifications: [],
    children?: React.ReactNode
}

interface State {
    tokenExpiringTimerId?: any,
    notificationTimerId?: any
}

function App(props: Props & State) {
    const properties = useProperties();
    const [timer, setTimer]:any = useState({})
    const { t } = useTranslation();

    useEffect(() => {
        if(i18n.isInitialized) {
            // force reload translations after login
            // (called after moving back from loginPage -> home)
            i18n.reloadResources();
        }
        // fetch translations when page first loaded
        i18n.init();

        const token = getAuthTokenPayload();
        if (!token) {
            redirectToLoginPage(properties);
            return;
        }

        setExpiringInterval(token);
    }, []);

    useEffect(() => {
        const token = getAuthTokenPayload();
        if (!token) {
            redirectToLoginPage(properties);
            return;
        }

        setExpiringInterval(token);
    }, []);

    function setExpiringInterval(token: TokenPayload) {
        const exp = new Date(0);
        exp.setUTCSeconds(token.expiry);
        const now = new Date();
        const expiringTime = exp.getTime() - now.getTime();
        const notificationTime = expiringTime - EXPIRING_NOTIFICATION_GAP;

        if (notificationTime > 0) {
            const notificationTimerId = setTimeout(() => {
                props.warning({
                    title: 'Auth-token expiration warning',
                    message: 'Your auth-token will be expired in ' + EXPIRING_NOTIFICATION_GAP / 1000 + ' seconds. You will need to login again.',
                    autoDismiss: false
                });
            }, notificationTime);

            setTimer({notificationTimerId});
        }

        if (expiringTime > 0) {
            const tokenExpiringTimerId = setTimeout(() => {
                redirectToLoginPage(properties);
            }, expiringTime);

            setTimer({tokenExpiringTimerId});
        } else {
            redirectToLoginPage(properties);
        }
    }

    useEffect(() => {
        if (timer.tokenExpiringTimerId) {
            clearTimeout(timer.tokenExpiringTimerId);
        }
        if (timer.notificationTimerId) {
            clearTimeout(timer.notificationTimerId);
        }
    }, [timer]);


    function getCurrentPage() {
        return pages.find((page) => page.path === props.location.pathname);
    }
  
    const subPages: SubPage[] = getMenuItems();
    const initalPageId = getInitialPageId(window.location.hash , subPages)

    return (
        <I18nextProvider i18n={i18n as any}>
            <div id="app">
                <Notifications
                    notifications={props.notifications}/>

                <SimpleStackNavigation
                    appTitle="VCO"
                    appIcon='home'
                    subPages={subPages}
                    initialSubPage={subPages[initalPageId].id}
                    pathSignOut="/login"
                    history={props.router}
                />


                <div className="page-container">
                    <Navigation
                        t={t}
                        currentPage={getCurrentPage()}
                        onSignOut={() => redirectToLoginPage(properties)}
                        />

                    { React.Children.only(props.children) }
                </div>
            </div>
        </I18nextProvider>
    );


}

function getMenuItems(): SubPage[] {
    return pages.map((page, index) => {
        return {
            id: ""+index,
            label: page.title,
            icon: page.icon as any,
            path: page.path
        };
    });
}

export default connect(
    (state: StoreState) => ({
        notifications: state.notifications
    }),
    {warning}
)(App);
