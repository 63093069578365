import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Alert, Button, FormGroup, Input, Label } from 'reactstrap';

import { cancelGeneralRemark, confirmGeneralRemark, ignoreGeneralRemark } from '../commonActions';
import { isGeneralRemarkVisible } from '../commonSelectors';

function GeneralRemark(props) {
    if(!props.visible) return <div></div>
    const value = props.input.value;
    const formId = props.formId;
    
    return (
        <Alert color="warning" onDismiss={() => props.cancelGeneralRemark(formId)}>
            { props.t('forecasting.details.warning.missingRemarks') }

            <FormGroup>
                <Label>{ props.t('forecasting.details.selector.generalRemark') }</Label>
                <Input {...props.input}/>
            </FormGroup>
            
            <Button color="primary" onClick={() => value && props.confirmGeneralRemark(formId, value)}>Confirm</Button>
            <Button style={{ marginLeft: 5 }} onClick={() => props.ignoreGeneralRemark(formId)}>Save Anyway</Button>
        </Alert>)
}

GeneralRemark.propTypes = {
    input: PropTypes.object.isRequired,
    formId: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    confirmGeneralRemark: PropTypes.func.isRequired,
    ignoreGeneralRemark: PropTypes.func.isRequired,
    cancelGeneralRemark: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
}

export default connect(
    (state, { formId }) => ({
        visible: isGeneralRemarkVisible(state, formId)
    }),
    { confirmGeneralRemark, ignoreGeneralRemark, cancelGeneralRemark }
)(withTranslation(['prl'])(GeneralRemark));
