import { Map } from 'immutable';
import CommonActionTypes from '../common/commonActions';
import ActionTypes from './forecastingActions';
import { Path } from './forecastingSelectors';

export default function(state=Map(), action) {
    switch(action.type) {
        case CommonActionTypes.HIDE_DETAIL_FORM:
            return handleHideDetailForm(state);
        case ActionTypes.SHOW_GENERAL_REMARK_FIELD:
            return handleShowGeneralRemark(state);
        case ActionTypes.CONFIRM_GENERAL_REMARK:
            return handleConfirmGeneralRemark(state);
        case ActionTypes.CANCEL_GENERAL_REMARK:
            return handleCancelGeneralRemark(state);
    }
    return state;
}

function handleHideDetailForm(state) {
    return state.setIn(Path.GENERAL_REMARK_SHOWING, false);
}

function handleShowGeneralRemark(state) {
    return state.setIn(Path.GENERAL_REMARK_SHOWING, true);
}

function handleConfirmGeneralRemark(state) {
    return state.setIn(Path.GENERAL_REMARK_SHOWING, false);
}

function handleCancelGeneralRemark(state) {
    return state.setIn(Path.GENERAL_REMARK_SHOWING, false);
}
