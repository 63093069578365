import React from 'react';
import { connect } from 'react-redux';
import { Record } from 'immutable';

import { StoreState, SelectionState } from '../../types/storeTypes';

import { getSelection, getSelectedDataItem, isAnyItemModified, getSelectedRun } from './selectors';
import { fetchData, resetAllChanges, saveAllChanges } from './actions';
import ForecastDetailsFooter from './ForecastDetailsInspectorFooter';
import ForecastDetailsInfo from './ForecastDetailsInfo';
import ForecastDetailsForm from './ForecastDetailsForm';
import { GraphDataPoint, GraphRunData, FormData } from './types';

interface Props {
    fetchOnMount?: boolean,
    initialValues?: { [key: string]: any },
    selection: Record<SelectionState>|undefined,
    selectedItem: GraphDataPoint|undefined,
    selectedRun: GraphRunData|undefined,
    anyModified: boolean,
    fetchData: (data: FormData) => void,
    saveAllChanges: () => void,
    resetAllChanges: () => void
}

function ForecastDetailsInspector(props: Props) {
    return <div className="forecast-details-inspector">
        { props.selection && props.selectedItem && props.selectedRun
            ? <ForecastDetailsInfo selection={props.selection} selectedItem={props.selectedItem} selectedRun={props.selectedRun}/>
            : <ForecastDetailsForm fetchOnMount={props.fetchOnMount} initialValues={props.initialValues} onSubmit={props.fetchData}/> }
        { props.anyModified && <ForecastDetailsFooter
            saveAllChanges={props.saveAllChanges}
            resetAllChanges={props.resetAllChanges}/> }
    </div>
}

export default connect(
    (state: StoreState) => ({
        selection: getSelection(state.forecastDetails), 
        selectedItem: getSelectedDataItem(state.forecastDetails),
        selectedRun: getSelectedRun(state.forecastDetails),
        anyModified: isAnyItemModified(state.forecastDetails)
    }),
    { fetchData, resetAllChanges, saveAllChanges }
)(ForecastDetailsInspector);
