import { formValueSelector } from 'redux-form';
import { Record } from 'immutable';
import moment, { Moment } from 'moment';
import { pushLocation } from '@pearlchain/component-lib-common';
import { SelectBinder, TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import { StoreState } from '../../../types/storeTypes';

import { apiRequest, apiRequestAndRefreshForm, apiSaveData, clearApiData } from '../../common/commonActions';
import ActionTypes, { refreshForm, showForm, setFormValues } from '../../common/masterform/formActions';
import { formatters } from '../../utils/powergrid/formatters';
import productBinderFormConfig from '../../common/binder/product/productLookupFormConfig';
import customerBinderFormConfig from '../../common/binder/customer/customerLookupFormConfig';
import updateForecastDetailsFormConfig from '../details/updateForecastDetailsFormConfig';
import manualEntryFormConfig from '../manualentry/formConfig';

import { RequestId } from '../../utils/requests';
import { selectDeselectAllForecastGroups, calculatePartial } from '../forecastingActions';
import { createDataModel } from './dataModel';

import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { getApiData, getSimulationContextUid, isRequestFetching } from '../../common/commonSelectors';

import { ForecastROPSchemeTypes, ForecastToBePublished } from '../../forecasting/forecastingActions';
import ProductBinder from '../../common/binder/product/ProductBinder';
import CustomerBinder from '../../common/binder/customer/CustomerBinder';
import ProductGroupBinder from '../../common/binder/ProductGroupBinder';
import InventoryPlannerBinder from '../../common/binder/InventoryPlannerBinder';
import ForecastAlgoBinder from '../../common/binder/ForecastAlgoBinder';
import ForecastROPSchemeBinder from '../../common/binder/ForecastROPSchemeBinder';
import ProductStatusBinder from '../../common/binder/ProductStatusBinder';
import DateBinder from '../../common/binder/DateBinder';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import { COLUMNS_META } from './columns';
import { getDiffs } from '../../utils/helpers';
import PlantBinder from '../../common/binder/PlantBinder';
import { createReadonlyDataDecorator } from '../../utils/datamodel/ReadonlyInLiveDataDecorator';
import { CommandConfig, FormConfig, FormModel } from '../../common/masterform/types/formConfigTypes';
import { ForecastOverviewResponse } from '../../utils/remoting/types/forecastOverviewResponseTypes';
import { isCurrentSimulationSuitable, isLive, isNothingSelected } from '../../utils/simulationHelpers';
import ForecastROPSchemeTypeBinder from '../../common/binder/ForecastROPSchemeTypeBinder';


type ForecastOverviewResponseRecord = Record<ForecastOverviewResponse>;

type FM = FormModel<ForecastOverviewResponseRecord[], Values, {}>;

export const icon = 'chart-line';

const UPDATE_FORECAST_FORM_ID = 'update-forecast-details';
const PRODUCT_BINDER_FORM_ID = 'forecast-overview-product-binder';
const CUSTOMER_BINDER_FORM_ID = 'forecast-overview-customer-binder';
const MAUAL_ENTRY_FORM_ID = 'manual-entry';

interface Values {
    companyCodes: string[],
    productNo: string,
    productDescription: string,
    productGroup: string,
    productInventoryPlanner: string,
    productStatus: string[],
    periodFrom: Moment,
    schemeType: string,
    scheme: string,
    algo: string,
    customerNo: string,
    customerName: string,
    toBePublished: boolean,
    plants: string[]
}

function isLiveOrCurrentSimulationIsNonROPAndNonForecastRelated(formModel: FM, state: StoreState) {
    return isLive(formModel) || !isCurrentSimulationSuitable(state, (simulation) => simulation.calcForecast || simulation.calcReorderPoint);
}

function isUpdateDisabled(formModel: FM, state: StoreState) {
    return isNothingSelected(formModel) || isLiveOrCurrentSimulationIsNonROPAndNonForecastRelated(formModel, state);
}

const updateCommand: CommandConfig<FM> = {
    label: 'forecasting.command.update',
    disabled: isUpdateDisabled,
    action: () => showForm(UPDATE_FORECAST_FORM_ID),
    datacy: 'forecasting-update-btn'
};

const manualEntryCommand: CommandConfig<FM> = {
    label: 'forecasting.command.new',
    disabled: isLiveOrCurrentSimulationIsNonROPAndNonForecastRelated,
    action: () => showForm(MAUAL_ENTRY_FORM_ID),
    datacy: 'manual-forecasting-entry-btn'
};

const calculateCommand: CommandConfig<FM> = {
    label: 'forecasting.command.calculate',
    disabled: isLiveOrCurrentSimulationIsNonROPAndNonForecastRelated,
    action: (formModel) =>
        apiRequestAndRefreshForm(
            RequestId.CALCULATE_REORDERPOINT_OR_FORECAST,
            formModel.formId,
            'Calculation has successfully finished',
            { calcForecast: true }
        ),
    datacy: 'forecasting-calculate-btn'
};

const calculatePartialCommand: CommandConfig<FM> = {
    label: 'forecasting.command.calculate.partial',
    disabled: isLiveOrCurrentSimulationIsNonROPAndNonForecastRelated,
    action: (formModel) => calculatePartial(formModel),
    datacy: 'forecasting-partial-calculate-btn'
};

function isSaveDisabled(formModel: FM) {
    return isLive(formModel) || (formModel.bufferedData === formModel.data);
}

function getUniqueIdentifier(dataItem: ForecastOverviewResponseRecord) {
    return dataItem.get('uniqueIdentifier');
}

function buildUpdateRequest(formModel: FM) {
    const { data, bufferedData } = formModel;
    const updated = getDiffs(data!, bufferedData!, getUniqueIdentifier, (a, b) => a.equals(b)).updated;
    return updated.map((upd) => upd[1].toJS());
}

const saveCommand: CommandConfig<FM> = {
    label: 'common.selector.actions.save',
    type: 'success',
    group: 1,
    disabled: isSaveDisabled,
    action: (formModel) => apiSaveData(RequestId.UPDATE_FORECAST_GROUPS, formModel.formId, 'Forecast groups are saved', buildUpdateRequest(formModel))
};

const selectAllCommand: CommandConfig<FM> = {
    label: 'reorderpoint.selector.actions.selectdeselectall',
    disabled: isLive,
    action: (formModel) => selectDeselectAllForecastGroups(formModel.formId, formModel.bufferedData, formModel.version),
    datacy: 'select-deselect-all'
};

function getToggledForecastFields(state: StoreState, selector: any) {
    if (selector(state,'schemeType') === ForecastROPSchemeTypes.MANUAL_ENTRY 
        || selector(state,'schemeType') === ForecastROPSchemeTypes.NO_FORECAST) {
        return { 'scheme' : true, 'algo' : true };
    } else {
        return { 'scheme' : false, 'algo' : false };
    }
}   

function isSchemeDisabled(formModel: FM, state: StoreState) {
    const toggledFields = getToggledForecastFields(state, formValueSelector(formModel.formId))
    return toggledFields ? toggledFields['scheme'] : false
}

function isForecastAlgoDisabled(formModel: FM, state: StoreState) {
    const toggledFields = getToggledForecastFields(state, formValueSelector(formModel.formId))
    return toggledFields ? toggledFields['algo'] : false
}

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            data: (state: StoreState) => {
                const response = getApiData(state, RequestId.FIND_FORECAST_GROUPS);
                if (response) {
                    return response.get('forecastGroups');
                }
            }
        },
        busy: (state: StoreState) => isRequestFetching(state, RequestId.FIND_FORECAST_GROUPS),
        reloadWhenChanged: (state: StoreState) => getSimulationContextUid(state),
        eventHandlers: {
            ['action-show-forecast-details']: (event: string, formModel: FM) => {
                return setFormValues('forecast-details',{
                    fetchOnMount: true,
                    initialValues: {
                        productNo: formModel.selection.get('productNo'),
                        plant: [formModel.selection.get('plant')],
                        startDate: moment(formModel.selection.get('initialPeriodFrom'),"DD-MM-YYYY"),
                        endDate: moment(formModel.selection.get('initialPeriodTo'),"DD-MM-YYYY"),
                        pastPeriods: true,
                        historicalForecasts: false,
                        activeForecast: true
                    }
                })
            }
        },
        clearData: () => clearApiData(RequestId.FIND_FORECAST_GROUPS),
        fetchData: (formModel: FM) => apiRequest(RequestId.FIND_FORECAST_GROUPS, {...formModel.bufferedValues, latest: true}),
    },
    selector: {
        icon: { name: icon },
        layout: {
            component: 'grid',
            numCols: { xs: 1, sm: 2, md: 3, lg: 4 },
        },
        fields: {
            companyCodes: { component: CompanyBinder, label: 'common.selector.companies', multi: true },
            plants: { component: PlantBinder, label: 'common.selector.plant'},
            productNo: { component: ProductBinder, binderFormId: PRODUCT_BINDER_FORM_ID, label: 'common.selector.productNo' },
            productDescription: { component: TextBinder, label: 'common.selector.productDesc' },
            productGroup: { component: ProductGroupBinder, label: 'common.selector.productGroup' },
            productInventoryPlanner: { component: InventoryPlannerBinder, label: 'common.selector.inventoryPlanner' },
            productStatus: { component: ProductStatusBinder, label: 'common.selector.productStatus' },
            periodFrom: { component: DateBinder, label: 'common.selector.periodFrom' },
            schemeType: { component: ForecastROPSchemeTypeBinder, label: 'common.selector.schemeType', datacy: 'scheme-type'},
            scheme: { disabled: isSchemeDisabled, component: ForecastROPSchemeBinder, label: 'common.selector.forecastropscheme', datacy: 'scheme' },
            algo: { disabled: isForecastAlgoDisabled, component: ForecastAlgoBinder, label: 'common.selector.algo', datacy: 'algo' },
            customerNo: { binderFormId: CUSTOMER_BINDER_FORM_ID, component: CustomerBinder, label: 'common.selector.customerNo' },
            customerName: { component: TextBinder, label: 'common.selector.customerName' },
            toBePublished: { component: SelectBinder, label: 'common.selector.tobepublished',
                options: [
                    { value: '', label: '' },
                    { value: ForecastToBePublished.ToBePublished, label: 'To be published' },
                    { value: ForecastToBePublished.NotToBePublished, label: 'Not to be published' }
                ]
            }
        },
        commands: [
            { label: 'common.selector.actions.search', type: 'primary', group: -1, action: (formModel: FM) => refreshForm(formModel.formId), datacy: 'search-forecasting-btn' },
            saveCommand,
            selectAllCommand,
            updateCommand,
            manualEntryCommand,
            calculateCommand,
            calculatePartialCommand
        ]
    },
    grid: {
        dataModel: createDataModel,
        readOnly: isLive,
        decorators: [
            createTranslateDecorator({ columns: COLUMNS_META }),
            createReadonlyDataDecorator()
        ],
        extensions: {
            [EXTS.SELECTION]: true,
            [EXTS.RESIZING]: true,
            [EXTS.EDITING]: true,
            [EXTS.DIRECT_INPUT]: true,
            [EXTS.STYLING]: true,
            [EXTS.FORMATTING]: {
                formatters
            },
            [EXTS.CONTEXT_MENU]: {
                commands: [
                    updateCommand,
                    manualEntryCommand
                ]
            },
            [EXTS.ACTION_BUTTONS]: true
        },
        frozenColumnsLeft: 6,
        events: ['action-show-forecast-details']
    },
    children: {
        [PRODUCT_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.product.title',
            form: {
                config: productBinderFormConfig
            }
        },
        [CUSTOMER_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.customer.title',
            form: {
                config: customerBinderFormConfig
            }
        },
        [UPDATE_FORECAST_FORM_ID]: {
            component: 'modal',
            title: 'forecasting.details.updateTitle',
            form: {
                config: updateForecastDetailsFormConfig
            }
        },
        [MAUAL_ENTRY_FORM_ID]: {
            component: 'modal',
            title: 'forecasting.manentry.title',
            form: {
                config: manualEntryFormConfig
            }
        }
    }
}

export default formConfig;
