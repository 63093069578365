import React from 'react';
import PropTypes from 'prop-types';
import { without } from '../../utils/helpers';
import FormInner from './FormInner';
import FormPage from './FormPage';

class FormPagesRoot extends React.Component {

    render() {
        return this.renderActivePage() || this.renderForm();
    }

    renderForm() {
        const innerProps = without(this.props, 'pages');
        return <FormInner {...innerProps}/>
    }
    
    renderActivePage() {
        const pages = this.props.config.pages;
        if(!pages) return;

        const activePage = this.props.formModel.activePage;
        if(!activePage) return;
        
        const pageNo = activePage.get('no');
        const pageParams = activePage.get('params');
        const pageConfig = pages[pageNo];

        const component = React.createElement(pageConfig.component, {
            parent: this.props.formModel,
            params: pageParams,
            config: pageConfig
        });

        return (
            <FormPage
                parentFormId={this.props.formId}
                title={pageConfig.title}>
                { component }
            </FormPage>);
    }
}

FormPagesRoot.propTypes = {
    formId: PropTypes.string.isRequired,
    formModel: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired
};

export default FormPagesRoot;
