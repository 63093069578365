import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import { CompanyBinder } from '@pearlchain/stackbase-common';
import { TextBinder } from '@pearlchain/component-lib-ui';

import Binder from '../../common/binder/Binder';
import GridLayout from '../../common/layout/GridLayout';
import TemplateBinder from '../../common/binder/TemplateBinder';
import SimulationParameters from '../../simulation/SimulationParameters';
import { clearFormFields, clearInitialValues, fetchFormFields, findSimulationInstance } from '../simulationInstanceActions';
import { requiredValidator, textValidator } from '../../utils/validators/validators';
import PlantBinder from '../../common/binder/PlantBinder';

export class NewSimulationFormSelector extends React.Component {

    constructor(props) {
        super(props);
        this.nameValidator = textValidator({ minLength: 1 });
    }

    render() {
        return (
        <div>
            <GridLayout numCols={1}>
                <Field name="name" validate={this.nameValidator} disabled={this.props.readOnly} component={Binder} binder={TextBinder} label="common.selector.name"/>
                <Field name="templateUid" validate={requiredValidator} disabled={this.props.readOnly} component={Binder} binder={TemplateBinder} label="simulationInstances.new.selector.simulationtemplates"
                    onChange={this.handleTemplateChange.bind(this)}/>
                <Field name="companyCodes" validate={requiredValidator} disabled={this.props.readOnly} component={Binder} binder={CompanyBinder} label="common.selector.companies" binderProps={{multi:true}} />
                <Field name="plants" validate={requiredValidator} disabled={this.props.readOnly} component={Binder} binder={PlantBinder} label="common.selector.plant"/>
            </GridLayout>

            <SimulationParameters
                formFields={this.props.formFields}
                disabled={this.props.readOnly}
                {...this.props}/>
        </div>);
    }

    handleTemplateChange(event, value) {
        if (value) {
            this.props.fetchFormFields(value);
        }
    }

    componentWillUnmount() {
        this.props.clearFormFields();
        this.props.clearInitialValues();
        this.props.reset(this.props.formId);
    }

    componentDidMount() {
        if (this.props.simulationInstanceUid) {
            this.props.findSimulationInstance(this.props.simulationInstanceUid);
        }
    }
}

export function getDefaultValues(simulationInstance) {
    return simulationInstance ? simulationInstance.get('initialValues', {}) : {};
}

NewSimulationFormSelector.propTypes = {
    formId: PropTypes.string.isRequired,
    formFields: PropTypes.object,
    fetchFormFields: PropTypes.func.isRequired,
    clearFormFields: PropTypes.func.isRequired,
    clearInitialValues: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    simulationInstanceUid: PropTypes.string,
    findSimulationInstance: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

export default connect(
    (state, { formId }) => {
        const selector = formValueSelector(formId);
        return {
            formFields: state.simulationInstance && state.simulationInstance.get('formFields'),
            simulationInstanceUid: selector(state, 'uniqueIdentifier')
        };
    },
    { fetchFormFields, clearFormFields, clearInitialValues, findSimulationInstance, reset }
)(NewSimulationFormSelector);

