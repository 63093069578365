import Immutable from 'immutable';

export function textValidator(opts) {
    return (input) => {
        if(input == null || typeof input !== 'string')
            return 'Required';

        const n = input.length;
        if(opts && opts.minLength != null && n < opts.minLength)
            return 'Too short. Min chars: ' + opts.minLength;

        if(opts && opts.maxLength != null && n > opts.maxLength)
            return 'Too long. Max chars: ' + opts.maxLength;
    }
}

export const requiredValidator = (input) => {
    if (!input || input.length === 0 || (Immutable.isImmutable(input) && input.isEmpty())) return 'Required';
};

export function numericValidator(opts) {
    return (input) => {
        if(input == null) return 'Required';

        let num = toNumber(input);
        if(num === undefined) return 'Invalid number';

        if(opts && opts.min != null && num < opts.min)
            return 'Too small. Min: ' + opts.min;

        if(opts && opts.max != null && num > opts.max)
            return 'Too large. Max: ' + opts.max;
    }
}

function toNumber(input) {
    if(typeof input === 'string') {
        return isNaN(input) ? undefined : parseFloat(input);

    } else if(typeof input === 'number') {
        return input;
    }
}

export function optional(validate) {
    return (input) => {
        return input == null ? undefined : validate(input);
    }
}

export function boolValidator() {
    return (input) => {
        if(!input
            || input === 'true'
            || input === true) return;

        return 'Invalid value';
    }
}
