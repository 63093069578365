import * as Imm from 'immutable';
import { Column } from '../../../types/columnTypes';

type GroupColumn = { colIdx: number, period: number, year: number, columns: Imm.Map<string, any>[] };

export type columnsCreatorFn = (period: number, year: number, colIdx: number) => Imm.Map<string, any>[]

export class PeriodColumnsBuilder {

    private idx = 0;
    private columnsLookup = new Map<number, Map<number, GroupColumn>>();
    private columns: GroupColumn[] = [];
    private columnsCreator: columnsCreatorFn

    constructor(columnsCreator: columnsCreatorFn) {
        this.columnsCreator = columnsCreator;
    }

    getOrCreateColumn(period: number, year: number): GroupColumn {
        let lookupByPeriod = this.columnsLookup.get(year);
        if(lookupByPeriod === undefined) {
            lookupByPeriod = new Map();
            this.columnsLookup.set(year, lookupByPeriod);
        }

        let group = lookupByPeriod.get(period);
        if(group === undefined) {
            const colIdx = this.idx++;
            const columns = this.columnsCreator(period, year, colIdx);
            
            group = { colIdx, period, year, columns };
            this.columns.push(group);
            lookupByPeriod.set(period, group);
        }

        return group;
    }

    getColumns(): Imm.List<Column> {
        return Imm.List(this.columns)
            .sort(this.compare)
            .flatMap((gp) => gp!.columns)
            .toList() as any;
    }

    compare(a: GroupColumn, b: GroupColumn) {
        if(a.year !== b.year) {
            return a.year - b.year;
        } else {
            return a.period - b.period;
        }
    }
}
