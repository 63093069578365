import React from 'react'
import PropTypes from 'prop-types';

import GridLayout from './GridLayout';
import FieldWrapper from './FieldWrapper';
import { FieldConfig, FormModel } from '../masterform/types/formConfigTypes';

interface Props {
    formId: string,
    numCols: number
    fields: FieldConfig[],
    formModel: FormModel
}

export default function GridFieldsLayout(props: Props) {
    const formId = props.formId;
    return <GridLayout numCols={props.numCols} horizontal>
        { props.fields.map((fieldConfig) =>
            <FieldWrapper
                key={fieldConfig.name}
                fieldConfig={fieldConfig}
                inline={true}
                formId={formId}
                formModel={props.formModel}/>
        )}
    </GridLayout>
}

GridFieldsLayout.propTypes = {
    fields: PropTypes.array.isRequired,
    numCols: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    formId: PropTypes.string.isRequired,
    formModel: PropTypes.object.isRequired
};
