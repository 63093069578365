import { Record } from 'immutable';
import { ForecastDetailsState, SelectionState } from '../../types/storeTypes';
import { GraphData, GraphDataPoint, GraphRunData } from './types';

export const KEY_DATA_ITEMS = 'data';

export function getData(state: Record<ForecastDetailsState>): GraphData|undefined {
    return state.get('data');
}

export function getSplitSize(state: Record<ForecastDetailsState>) {
    return state.get('split-size');
}

export function getSelection(state: Record<ForecastDetailsState>): Record<SelectionState>|undefined {
    return state.get('selection');
}

export function getSelectedRun(state: Record<ForecastDetailsState>): GraphRunData|undefined {
    const selection = state.get('selection');
    if (!selection) return;

    const data = state.get('data');
    if (!data) return;
    
    const runIdx = selection.get('runIdx');
    return data.getIn(['series', runIdx]);
}

export function getSelectedDataItem(state: Record<ForecastDetailsState>): GraphDataPoint|undefined {
    const selection = state.get('selection');
    if (!selection) return;

    const selectedRun = getSelectedRun(state);
    if (!selectedRun) return;

    const pointIdx = selection.get('pointIdx');
    return selectedRun.get('data').get(pointIdx);
}

export function isAnyItemModified(state: Record<ForecastDetailsState>): boolean {
    const data = state.get('data');
    if (!data) return false;

    const series = data.get('series');
    for (let line of series) {
        const data = line.get('data');
        for (let datum of data)
            if (isItemModified(datum))
                return true;
    }

    return false;
}


export function getKeyOriginal(key: string) {
    return key + '_original';
}

export function isItemModified(d: GraphDataPoint): boolean {
    return d.get('originalRemark') != null || d.get('originalQuantity') != null;
}

export function getKey(d: any, idx: number) {
    const id = d.get('uuid');
    return id == null ? idx : id;
}
