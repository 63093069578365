import { combineReducers } from 'redux';
import { reducer as formReducers } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';
import { reducers as componentLibCommonReducers, setStoreKey as setCommonStoreKey } from '@pearlchain/component-lib-common';
import {reducers as stackCommonReducers} from '@pearlchain/stackbase-common';

import forecastingReducers from '../components/forecasting/forecastingReducers';
import reorderPointReducers from '../components/reorderPoint/reorderPointReducers';
import simulationTemplateReducers from '../components/simulation/simulationTemplate/simulationTemplateReducers';
import simulationInstanceReducers from '../components/simulationInstance/simulationInstanceReducers';
import commonReducers from '../components/common/commonReducers';
import masterFormReducers from '../components/common/masterform/formReducers';
import forecastDetailsReducers from '../components/forecast-details/reducers';
import propertyReducers from '../components/home/propertyReducers';

const reducers = {
    // app
    forecasting: forecastingReducers,
    simulationTemplate: simulationTemplateReducers,
    simulationInstance: simulationInstanceReducers,
    reorderPoint: reorderPointReducers,
    common: commonReducers,
    componentLibCommon: componentLibCommonReducers,
    stackbaseCommon: stackCommonReducers,
    masterForm: masterFormReducers,
    forecastDetails: forecastDetailsReducers,
    properties: propertyReducers,
    // third-party
    form: formReducers,
    notifications
};

setCommonStoreKey('componentLibCommon');

export default function(state, action) {
    return combineReducers(reducers)(state, action);
}
