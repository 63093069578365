import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { error, success } from 'react-notification-system-redux';

import ActionTypes, {
    apiError,
    ApiRequestAndRefreshFormType,
    ApiRequestType,
    apiResult,
    ApiSaveDataType,
    beginApiRequest,
    RequestInDetailRefreshMasterType
} from './commonActions';
import { hideForm, refreshForm } from './masterform/formActions'
import { RequestHandlers, RequestId } from '../utils/requests';
import formSagas from './masterform/formSagas';
import { createRequestBuilderFromState } from '../utils/remoting/vcoRequestBuilder';

import { registerRequestHandlers } from '@pearlchain/component-lib-common';
registerRequestHandlers(RequestHandlers);

export default function() {
    return all([
        formSagas(),
        takeEvery(ActionTypes.API_REQUEST, handleApiRequest),
        takeEvery(ActionTypes.API_SAVE_DATA, handleApiSaveData),
        takeEvery(ActionTypes.REQUEST_REFRESH_FORM, handleRequestAndRefreshForm),
        takeEvery(ActionTypes.REQUEST_IN_DETAIL_REFRESH_MASTER, handleRequestInDetailAndRefreshMaster)
    ]);
}

/** Common */

export function *handleApiSaveData({ requestId, formId, successCaption, args }: ApiSaveDataType<any>) {
    try {
        const [result] = yield* performApiRequest(requestId, args);
        if (!result) return;

        yield put(refreshForm(formId));
        yield put(success({title: successCaption}));

    } catch (error) {
        console.error(error);
    }
}

export function *handleApiRequest({ requestId, args }: ApiRequestType<any>) {
    yield* performApiRequest(requestId, args);
}

export function *performApiRequest(requestId: RequestId, args?: any) {
    let result, err;
    try {
        yield put(beginApiRequest(requestId));
        
        // create the requestBuilder
        const requestBuilder = yield select(createRequestBuilderFromState);

        // get the request handler
        const request = RequestHandlers[requestId];
        if(request == null) throw 'Request not recognized with id: ' + requestId;

        // perform the api request
        // @ts-ignore
        result = yield call(request, requestBuilder, ...args);

        // got the result
        yield put(apiResult(requestId, result));

    } catch(err2) {
        err = err2;
        console.error(err);
        yield put(error({ title: 'Api Error', message: '' + err.message }));
        yield put(apiError(requestId, err.message));
    }

    return [result, err];
}

/** Simulation */

function *handleRequestInDetailAndRefreshMaster({ formModel, requestId, successMessage }: RequestInDetailRefreshMasterType) {
    const params = formModel.bufferedValues;
    const parentFormId = formModel.parent.formId;

    try {
        const [ result ] = yield* performApiRequest(requestId, [params]);
        const formId = formModel.formId;

        if(!result) return;
        yield put(refreshForm(parentFormId));
        yield put(hideForm(formId));
        yield put(success({ title: successMessage }));

    } catch(error) {
        console.error(error);
    }
}

export function *handleRequestAndRefreshForm({ requestId, formId, successMessage, args }: ApiRequestAndRefreshFormType) {
    try {
        const [result] = yield* performApiRequest(requestId, args);
        if (!result) return;

        yield put(refreshForm(formId));
        yield put(success({title: successMessage}));
    } catch(error) {
        console.error(error);
    }
}
