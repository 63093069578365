import { List, Map, Record } from 'immutable';
import moment from 'moment';

import { FormConfig, FormModel } from '../../common/masterform/types/formConfigTypes';

import { apiRequest } from '../../common/commonActions';
import { getApiData } from '../../common/commonSelectors';
import { RequestId } from '../../utils/requests';
import FieldsLayout from './FieldsLayout';
import { columns } from './columns';
import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { formatters } from '../../utils/powergrid/formatters';
import { constructMomentFromDate } from '../../utils/formattingHelpers';
import { copyColumnKeys } from '../../utils/powergrid/columnHelpers';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import { ReorderPointDetailsResponse, ReorderPointOverviewResponse } from '../../utils/remoting/types/reorderPointRequestTypes';
import { StoreState } from '../../../types/storeTypes';
import { getSimulationUid } from '../../common/masterform/formSelectors';

export type ReorderPointDetailsRespRecord = Record<ReorderPointDetailsResponse>;

export type FM = FormModel<List<ReorderPointDetailsRespRecord>, any, {}>;

const formConfig: FormConfig<FM> = {
    form: {
        fetchOnMount: true,
        model: {
            data: (state: StoreState) => getApiData(state, RequestId.FIND_FORECAST_LINES_BY_ROP),
            values: (state: StoreState, formId, params, select) => select(params.parent.selection, state, formId)(getInitialValues)
        },
        fetchData: (formModel: FM) => {
            const productNo = formModel.parent!.selection.get('productNo');
            const reorderPointUuid = formModel.parent!.selection.get('uniqueIdentifier');
            const plant = formModel.parent!.selection.get('plant');
            const params = { productNo, reorderPointUuid, plant };
            return apiRequest(RequestId.FIND_FORECAST_LINES_BY_ROP, params);
        }
    },
    selector: {
        layout: {
            component: FieldsLayout
        },
        enableReinitialize: true,
        clearDisabled: true
    },
    grid: {
        dataModel: {
            columns,
            rowIdField: 'id',
            getRow(dataItem, { index }) {
                const periodType = dataItem.get('periodType');
                const startDate = dataItem.get('startDate');
                const periodMoment = constructMomentFromDate(startDate);
                return copyColumnKeys(Map()
                    .set('id', index)
                    .set('period', { periodType, periodMoment }),
                    dataItem, columns);
            }
        },
        decorators: [
            createTranslateDecorator({ allHeaders: true })
        ],
        extensions: {
            [EXTS.FORMATTING]: {
                formatters
            }
        }
    }
};

function getInitialValues(sel: Record<ReorderPointOverviewResponse>, state: StoreState, formId: string) {
    if (sel) {

        const simulationUid = getSimulationUid(state, formId);
        const selection = sel.withMutations(mutableSelection => {
            mutableSelection.set('runDate', (mutableSelection.get('runDate') && moment(mutableSelection.get('runDate'))));
            mutableSelection.set('publishedDate', (mutableSelection.get('publishedDate') && moment(mutableSelection.get('publishedDate'))));

            if (simulationUid) {
                mutableSelection.set('safetyStock', mutableSelection.get('safetyStockQuantity'));
            } else {
                mutableSelection.set('safetyStock', mutableSelection.get('liveSafetyStockQuantity'));
            }

            if (mutableSelection.get('forecastROPScheme') === 'StandardForecastROPScheme.CloneROP') {
                mutableSelection.set('formulaFieldsFormatter', () => '');
            } else {
                mutableSelection.set('formulaFieldsFormatter', formatters.round);
            }
        });
        return selection.toJS();
    } else {
        return {};
    }
}

export default formConfig;
