import * as Imm from 'immutable';

/**
 * Maps the column settings returned by the powergrid sorting extension
 * to the key of the column.
 */
export function columnSettingsToSorting(sortSettings, columns) {
    return !sortSettings ? Imm.List() : Imm.List().withMutations((m) => {
        for(let { key, direction } of sortSettings) {
            const item = columns.get(key);
            m.push(Imm.Map({ key: item.get('_key'), direction }));
        }
    });
}
