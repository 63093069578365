import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestId } from '../../utils/requests';
import { dataToOptions } from '../../utils/binder/binderHelpers';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';
import { List } from 'immutable';

export default function ForecastAlgoBinder(props: any) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_FORECAST_ALGOS}
        render={(opts:any) => {
            let aux = opts;
            if(opts){
                aux = opts.insert(0,"")
           }
            return (
                <SelectBinder options={dataToOptions(aux)} { ...props }/>
            )
        }}
        />
}
