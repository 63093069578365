import { simulationInstance } from '../simulationInstance/simulationInstancePage.js';
import { page as forecasting } from '../forecasting/forecastingPage';
import { page as simulationTemplates } from '../simulation/simulationTemplate/simulationTemplatePage';
import { page as salesOrders } from '../salesorders/salesOrdersPage';
import { reorderPoint } from '../reorderPoint/reorderPointPage';
import { page as forecastAccuracy } from '../forecastAccuracy/page';
import { page as forecastAccuracyDetails } from '../forecastAccuracy/forecastAccuracyDetails/page';
import { page as forecastDetails } from '../forecast-details/page';
import { Page } from '../../types/pageTypes.js';
import { SubPage } from '@pearlchain/stackbase-common';

export const home: Page = {
    title: 'home.title',
    icon: 'home',
    path: '/'
}

export const pages = [
    home,
    simulationTemplates,
    simulationInstance,
    forecasting,
    reorderPoint,
    salesOrders,
    forecastAccuracy,
    forecastDetails
];

export const pagesByPathname = new Map();
for(let page of pages) {
    pagesByPathname.set(page.path, page);
}

export function getPageTitleByPath(path : String){
    pages.push(forecastAccuracyDetails);
    const title = pages.find(page => page.path === path)?.title
    return (title) ? title :'';
}

export function getInitialPageId(path: string, subPages: SubPage[]): number {
    path = path.replace('#','');
    for(let i=0; i < subPages.length; i++){
        if(subPages[i].path == path)
        return i;
    }
    return 0
}
