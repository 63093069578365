import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import GridFieldsLayout from '../../layout/GridFieldsLayout';
import SelectorIcon from './SelectorIcon';
import CommandGroups from './CommandGroups';

class FormSelectorContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { open: true };
    }

    render() {
        const iconProps = this.props.icon
            ? this.props.icon : {};

        return (
            <div className="form-selector">
                <div className="form-selector-header">
                    <CommandGroups
                        commands={this.props.commands} 
                        formModel={this.props.formModel}
                        handleSubmit={this.props.handleSubmit}
                        valid={this.props.valid}/>
                    <SelectorIcon {...iconProps}/>
                    { this.props.split && this.renderCollapseTag() }
                </div>
                <Collapse isOpen={this.state.open}>
                    <div>
                        { this.renderFields() }
                    </div>
                </Collapse>
                
            </div>);
    }

    renderCollapseTag() {
        return (
            <div className={ 'form-selector-collapse' + (this.state.open ? ' open' : '') } onClick={() => this.setState({ open: !this.state.open })}>
                <FontAwesome name={this.state.open ? 'chevron-up' : 'chevron-down'}/>
            </div>);
    }

    renderFields() {
        const fieldsLayoutComponent = this.getFieldsLayoutComponent();
        const fields = this.fieldsToArray(this.props.fields);

        return React.createElement(fieldsLayoutComponent, {
            formId: this.props.formId,
            formModel: this.props.formModel,
            readOnly: this.props.readOnly,
            fields,
            ...this.props.layout
        }); 
    }

    fieldsToArray(fields) {
        if(!fields) return [];

        const result = [];
        for(let key in fields) {
            result.push({ name: key, ...fields[key] });
        }

        return result;
    }

    getFieldsLayoutComponent() {
        const component = this.props.layout.component;
        if(component == null || typeof component === 'string') {
            switch(component) {
                case 'grid': return GridFieldsLayout;
            }

            throw new Error(`No fields layouts component exists with name ${component}`);
        }

        return component;
    }
}

FormSelectorContent.propTypes = {
    icon: PropTypes.object,
    formId: PropTypes.string.isRequired,
    formModel: PropTypes.object.isRequired,
    fields: PropTypes.object,
    layout: PropTypes.object.isRequired,
    commands: PropTypes.array.isRequired,
    split: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    valid: PropTypes.bool,
    handleSubmit: PropTypes.func
};

export default FormSelectorContent;
