import * as Imm from "immutable";
import { DataModel } from "../common/masterform/types/gridDataModelTypes";
import { ForecastAccuracyOverviewGroupResponse } from "../utils/remoting/types/forecastAccuracyRequestTypes";
import { columns } from './columns';
import { PeriodColumnsBuilder } from "../utils/datamodel/PeriodColumnsBuilder";
import { formatPeriod } from "../utils/helpers";
import { column, Column } from "../../types/columnTypes";

const COLUMN_TITLE_VALUE_FORECAST = 'Forecast';
const COLUMN_TITLE_VALUE_ORDERED = 'Ordered';
const COLUMN_TITLE_DEVIATION = 'Deviation %';

class ForecastAccuracyDataModel implements DataModel<any> {

    private readonly _rows: Imm.List<any>;
    private readonly _rowIndicesById: Map<string, number>;
    private readonly _columns: Imm.List<any>;

    constructor(
        data: ForecastAccuracyOverviewGroupResponse[]|undefined,
        bufferedData: ForecastAccuracyOverviewGroupResponse[]|undefined
    ) { 
        if(data === undefined)
            data = [];

        if(bufferedData === undefined)
            bufferedData = data;

        const columnsBuilder = new PeriodColumnsBuilder(this._createGroupColumns.bind(this));
        const [rows, rowIndicesById] = this._computeRows(data, columnsBuilder);

        this._rows = rows;
        this._rowIndicesById = rowIndicesById;

        const layoutFixColumn: Column = column({ _key: 'layout-fix', width: 0 });

        this._columns = columns
            .concat(columnsBuilder.getColumns())
            .toList()
            .push(layoutFixColumn);
    }

    getClass(record: { id: string }, column: { key: number }): string|undefined {
        const row = this._getRowById(record.id);
        const columnKey: string = this._columns.get(column.key).get('_key'); 
        const deviationText: string|undefined = row.get(columnKey);
        if(deviationText != undefined) {
            const deviationAmtPercent = parseFloat(deviationText);
            return Math.abs(deviationAmtPercent) > 50 ? 'pg-cell-deviation-toohigh' : undefined;
        }
    }

    getItemById(id: string) {
        return this._getRowById(id);
    }

    hasItem(id: string) {
        return this._rowIndicesById.has(id);
    }

    getRows() {
        return this._rows;
    }

    getColumns() {
        return this._columns;
    }
    
    shouldApplyClasses(id: string, column: { key: number }) {
        const targetColumn = this._columns.get(column.key);
        if(targetColumn) {
            const columnKey: string = targetColumn.get('_key');
            return columnKey.startsWith('deviation-');

        } else {
            return false;
        }
    }
    
    private _getRowById(id: string) {
        const index = this._rowIndicesById.get(id);
        return index === undefined ? undefined : this._rows.get(index);
    }

    private _computeRows(data: ForecastAccuracyOverviewGroupResponse[], columnsBuilder: PeriodColumnsBuilder): [Imm.List<Imm.Map<string, any>>, Map<string, any>] {
        const rowsLookup = new Map<string, number>();

        const rows = data.map((datum, i) => {
            const productNo = datum.productNo;
            const productDescription = datum.productDescription;
            const plant = datum.plant;

            const idKey = productNo + '#' + plant;
            rowsLookup.set(idKey, i);

            let row: Imm.Map<string, any> = Imm.Map<string, any>().withMutations(mutableRow => {
                mutableRow.set('id', idKey)
                    .set('productNo', productNo)
                    .set('productDescription', productDescription)
                    .set('plant', plant);

                for (let value of datum.values) {
                    const customerNo = value.customerNo;
                    const customerName = value.customerName;

                    const year = value.year;
                    const period = value.period;
                    const columnGroup = columnsBuilder.getOrCreateColumn(period, year);

                    const deviation = this._getDeviation(value.quantityOrdered, value.quantityForecast);

                    mutableRow.set('customerNo', customerNo)
                        .set('customerName', customerName)
                        .set(this._getValueForecastColumnKey(columnGroup.colIdx), value.quantityForecast)
                        .set(this._getValueOrderedColumnKey(columnGroup.colIdx), value.quantityOrdered)
                        .set(this._getDeviationColumnKey(columnGroup.colIdx), deviation);
                }
            });
            return row;
        });

        return [Imm.List(rows), rowsLookup];
    }

    private _getDeviation(quantityOrdered: number|undefined, quantityForecast: number|undefined) {
        if(quantityOrdered == undefined || quantityForecast == undefined || quantityOrdered <= 0) {
            return undefined;
        }
        return (100 * (quantityForecast - quantityOrdered) / quantityOrdered).toFixed(2);
    }

    private _createGroupColumns(period: number, year: number, colIdx: number) {  
        const name = formatPeriod(year, period);
        const group = 'gp-' + colIdx;

        return [
            Imm.Map({ _key: this._getValueForecastColumnKey(colIdx), width: 100, groupTitle: name, group, title: COLUMN_TITLE_VALUE_FORECAST }),
            Imm.Map({ _key: this._getValueOrderedColumnKey(colIdx), width: 100, groupTitle: name, group, title: COLUMN_TITLE_VALUE_ORDERED }),
            Imm.Map({ _key: this._getDeviationColumnKey(colIdx), width: 100, groupTitle: name, group, title: COLUMN_TITLE_DEVIATION })
        ];
    }

    private _getValueForecastColumnKey(colIndex: number) {
        return 'value-forecast-' + colIndex;
    }

    private _getValueOrderedColumnKey(colIndex: number) {
        return 'value-ordered-' + colIndex;
    }

    private _getDeviationColumnKey(colIndex: number) {
        return 'deviation-' + colIndex;
    }
}

export function createDataModel(data: ForecastAccuracyOverviewGroupResponse[]|undefined, bufferedData: ForecastAccuracyOverviewGroupResponse[]|undefined): DataModel<any> {
    return new ForecastAccuracyDataModel(data, bufferedData);
}

