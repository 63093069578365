import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

import { showForm } from '../../masterform/formActions';
import { WrappedFieldMetaProps } from 'redux-form';
import { Params } from './customerLookupFormConfig';

interface OwnProps {
    input: InputProps,
    formId: string,
    label: string,
    meta: WrappedFieldMetaProps,
    binderFormId: string,
    disabled?: boolean,
    resultMapping: {[key: string]: string}
}

interface InputProps {
    name: string,
    value: string
}

interface DispatchProps {
    showForm: typeof showForm,
}

type Props = OwnProps & DispatchProps;

const CustomerBinder: React.SFC<Props> = (props: Props): JSX.Element => {
    const name = props.input.name;
    const binderFormId = props.binderFormId;
    const resultMapping = props.resultMapping;

    function getFormParams(): Params {
        return {
            customerNo: props.input.value,
            resultField: name,
            resultFormId: props.formId,
            resultMapping
        };
    }

    return (
        <InputGroup>
            <Input
                disabled={!!props.disabled}
                {...props.input}/>
                
            <InputGroupAddon addonType="append">
                <Button onClick={() => props.showForm(binderFormId, getFormParams())}>
                    <FontAwesome name="search"/>
                </Button>
            </InputGroupAddon>
        </InputGroup>);
};

export default connect(
    undefined,
    { showForm }
)(CustomerBinder);
