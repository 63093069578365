import { SimulationTemplateOverviewResponse } from '../utils/remoting/types/simulationTemplateRequestTypes';
import { SimulationTypeResponse } from '../utils/remoting/types/simulationTypeRequestTypes';
import { Action } from 'redux';

const ActionTypes = {
    FIND_SIMULATION_INSTANCE: 'vco.simulationinstance.FIND_SIMULATION_INSTANCE',
    GET_FORM_FIELDS: 'vco.simulationinstance.GET_FORM_FIELDS',
    RECEIVE_FORM_FIELDS: 'vco.simulationinstance.RECEIVE_FORM_FIELDS',
    RECEIVE_INITIAL_VALUES: 'vco.simulationinstance.RECEIVE_INITIAL_VALUES',
    CLEAR_INITIAL_VALUES: 'vco.simulationinstance.CLEAR_INITIAL_VALUES',
    CLEAR_FORM_FIELDS: 'vco.simulationinstance.CLEAR_FORM_FIELDS',
    INVALIDATE_SIMULATION_INSTANCE: 'vco.simulationinstance.INVALIDATE_SIMULATION_INSTANCE',
    PUBLISH_SIMULATION_INSTANCE: 'vco.simulationinstance.PUBLISH_SIMULATION_INSTANCE',
    FETCH_SIMULATION_INSTANCES: 'vco.simulationinstance.FETCH_SIMULATION_INSTANCES'
};

export default ActionTypes;

export interface InvalidateSimulationInstanceAction extends Action { simulationInstanceUid: string, formId: string }

export interface PublishSimulationInstanceAction extends Action { simulationInstanceUid: string, formId: string }

export interface FindSimulationInstanceAction extends Action { instanceUid: string }

export interface FetchFormFieldsAction extends Action { templateUid: string };

export function invalidateSimulationInstance(simulationInstanceUid: string, formId: string): InvalidateSimulationInstanceAction {
    return { type: ActionTypes.INVALIDATE_SIMULATION_INSTANCE, simulationInstanceUid, formId };
}

export function publishSimulationInstance(simulationInstanceUid: string, formId: string): PublishSimulationInstanceAction {
    return { type: ActionTypes.PUBLISH_SIMULATION_INSTANCE, simulationInstanceUid, formId };
}

export function findSimulationInstance(instanceUid: string): FindSimulationInstanceAction {
    return { type: ActionTypes.FIND_SIMULATION_INSTANCE, instanceUid };
}

export function fetchFormFields(templateUid: string): FetchFormFieldsAction {
    return {
        type: ActionTypes.GET_FORM_FIELDS,
        templateUid
    }
}

export function receiveFormFields(result: SimulationTypeResponse) {
    return {
        type: ActionTypes.RECEIVE_FORM_FIELDS,
        result
    }
}

export function receiveInitialValues(result: SimulationTemplateOverviewResponse) {
    return {
        type: ActionTypes.RECEIVE_INITIAL_VALUES,
        result
    }
}

export function clearFormFields() {
    return {
        type: ActionTypes.CLEAR_FORM_FIELDS
    }
}

export function clearInitialValues() {
    return {
        type: ActionTypes.CLEAR_INITIAL_VALUES
    }
}

export function fetchSimulationInstances(params: any) {
    return {
        type: ActionTypes.FETCH_SIMULATION_INSTANCES,
        params
    }
}
