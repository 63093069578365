import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import React from 'react';
import {SingleDatePicker} from 'react-dates';
import { Moment } from 'moment';
import { WrappedFieldMetaProps } from 'redux-form';

interface OwnProps {
    input: InputProps,
    meta: WrappedFieldMetaProps,
    label: string,
    disabled?: boolean,
    displayFormat?: string,
    className?: string 
}

interface InputProps {
    value: Moment,
    onChange?: any,
    onFocus?: any,
    onBlur?: any
}

function DateBinder(props: OwnProps): JSX.Element {
    const {input : {value, onChange, onFocus, onBlur}, meta: { active=false }, disabled } = props;

    const onFocusFn = (arg: { focused: boolean|null }) => {
        if (arg.focused) {
            onFocus()
        } else {
            onBlur()
        }
    };

    const displayFormat = props.displayFormat ? props.displayFormat : 'DD-MM-YYYY';
    
    const onInternalDateChange = (value: Moment|null) => {
        onChange(value);
    };

    const className = `binder-datepicker ${props.className ? props.className : ''}`
    return (
        <div className={className}>
            <SingleDatePicker id='date-input' placeholder={''} date={value}
                        focused={active} numberOfMonths={1} disabled={disabled}
                        onDateChange={onInternalDateChange} onFocusChange={onFocusFn}
                        small={true} isOutsideRange={() => false}
                        firstDayOfWeek={1} displayFormat={displayFormat}            
                    />
        </div>);
}

export default DateBinder;

