export function calculateDeviation(previousQuantity: number|null, quantity: number): number {
    let deviation;
    if (previousQuantity) {
        deviation = (100 / previousQuantity) * quantity - 100;
    } else {
        deviation = 0;
    }
    return deviation;
}

export function getPosNeg(deviation: number): string {
    return deviation > 0 ? '+'
        : deviation < 0 ? '-'
        : ' ';
}
