import { fromJS, List } from 'immutable';
import { Column } from '../../types/columnTypes';

export const columns: List<Column> = fromJS([
    { _key: 'uniqueIdentifier', width: 0, idField: true },
    { _key: 'company', width: 100, title: 'common.grid.company' },
    { _key: 'plant', width: 100, title: 'common.grid.plant' },
    { _key: 'customerNo', width: 100, title: 'salesorders.grid.customerNo' },
    { _key: 'customerName', width: 150, title: 'salesorders.grid.customerName' },
    { _key: 'no', width: 150, title: 'salesorders.grid.solNo' },
    { _key: 'productNo', width: 150, title: 'common.grid.productNo' },
    { _key: 'productDescription', width: 150, title: 'common.grid.productDesc' },
    { _key: 'requestedDeliveryDate', width: 150, title: 'salesorders.grid.requestedDeliveryDate', formatter: 'date' },
    { _key: 'quantityOrdered', width: 130, title: 'salesorders.grid.quantityOrdered' },
    { _key: 'unitOfMeasurement', width: 60, title: 'common.grid.uom' },
    { _key: 'forecastingQuantity', width: 150, title: 'salesorders.grid.forecastQuantity', editable: true },
    { _key: 'forecastingDeliveryDate', width: 150, title: 'salesorders.grid.forecastDeliveryDate', editable: true }
]);

export const COLUMN_IDX_FORECAST_QUANTITY = 11;
export const COLUMN_IDX_FORECAST_DELIVERY_DATE = 12;

export default columns;

export const COLUMNS_META = new Map([
    ['unitOfMeasurement', {
        translateValue: true
    }],
    ['plant', {
        translateHeader: true
    }]
]);
