
export enum ActionType {
    FETCH_PROPERTIES = 'vco.properties.FETCH_PROPERTIES',
    RECEIVE_PROPERTIES = 'vco.properties.RECEIVE_PROPERTIES'
}

export function fetchProperties(): FetchPropertiesAction {
    return {
        type: ActionType.FETCH_PROPERTIES
    };
}

export type FetchPropertiesAction = {
    type: ActionType.FETCH_PROPERTIES;
}

export function receiveProperties(properties: { [key: string]: unknown }): ReceivePropertiesAction {
    return {
        type: ActionType.RECEIVE_PROPERTIES,
        properties
    };
}

export type ReceivePropertiesAction = {
    type: ActionType.RECEIVE_PROPERTIES,
    properties: { [key: string]: unknown }
}
