import { fromJS } from 'immutable';

export const columns = fromJS([
    {_key: 'id', width: 0, idField: true },
    {_key: 'creationDate', width: 130, title: 'common.grid.date', formatter: 'date'},
    {_key: 'creationUser', width: 130, title: 'common.grid.creationuser'},
    {_key: 'name', width: 130, title: 'common.grid.name'},
    {_key: 'externalReference', width: 130, title: 'simulation.grid.simulationType'},
    {_key: 'plants', width: 130, title: 'simulation.grid.plants'}
]);

export const COLUMNS_META = new Map([
    ['externalReference', {
        translateValue: true
    }]
]);
