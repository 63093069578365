import { DataModel } from "../types/gridDataModelTypes";

export class ApplyClassesAdapter {
    private dataModel: DataModel;
    private callbacks: Map<string, (dm: DataModel) => void>;

    constructor(dataModel: DataModel) {
        this.dataModel = dataModel;
        this.callbacks = new Map();
    }

    updateDataModel(dataModel: DataModel) {
        this.dataModel = dataModel;

        // update cell styles when the dataModel changed
        for(let callback of this.callbacks.values()) {
            callback(dataModel);
        }
    }

    onApplyClasses(record: { id: string }, column: { key: number }, callback: (cellClass: string|undefined) => void) {
        const id = '' + record.id;

        // should we track changes for this column
        if(!this.includeColumn(id, column)) return;

        const key = id + '_' + column.key;
        const self = this;

        const fn = (dataModel: DataModel) => {
            if(!dataModel.hasItem(id)
                || !this.includeColumn(id, column)) {

                // remove this callback
                self.callbacks.delete(id);
                return;
            }

            const cellClass = dataModel.getClass
                ? dataModel.getClass(record, column) : undefined;

            callback(cellClass);
        }

        fn(this.dataModel);

        // register the callback, overriding previous
        this.callbacks.set(key, fn);
    }

    includeColumn(id: string, column: { key: number }): boolean {
        return this.dataModel.shouldApplyClasses
            ? this.dataModel.shouldApplyClasses(id, column) : false;
    }
}

