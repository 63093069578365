import { Record } from "immutable";

export interface ColumnMeta {
    translateHeader?: boolean,
    translateValue?: boolean
}

type _Column = {
    _key: string,
    width: number,
    title: string,
    idField?: boolean,
    editable?: boolean,
    hideInLive?: boolean,
    formatter?: string
}

export type Column = Record<_Column>;

/**
 * Immutable Record factories
 */

export const column = Record<_Column>({
    _key: 'key',
    width: 150,
    title: 'title',
    idField: undefined,
    editable: undefined,
    formatter: undefined
});
