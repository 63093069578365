import { GridDataDecorator, GridDataDecoratorCreator } from '../../utils/datamodel/GridDataDecorator';
import { createSelector } from 'reselect';
import { FormModel } from '../../common/masterform/types/formConfigTypes';
import { StoreState } from '../../../types/storeTypes';
import { getForm } from '../../common/masterform/formSelectors';
import { Column } from '../../../types/columnTypes';

export class AlgoQuantityDecorator implements GridDataDecorator {
    private readonly hideAlgoQuantity: boolean;

    constructor(hideAlgoQuantity: boolean) {
        this.hideAlgoQuantity = hideAlgoQuantity;
    }

    includeColumn(column: Column): boolean{
        return !(this.hideAlgoQuantity && column.get('_key') === 'algoQuantity');
    }

    modifyColumn(column: Column): Column {
        return column;
    }
}

export const createAlgoQuantityDecorator = (): GridDataDecoratorCreator => createSelector(
    (formModel: FormModel, state: StoreState) => {
        const parentForm = getForm(state, formModel.parent!.formId);
        if (!parentForm || !parentForm.get('selection')) {
            return false;
        }
        const parentForecastROPScheme = parentForm.get('selection').get('forecastROPScheme');
        return 'StandardForecastROPScheme.ManualEntry' === parentForecastROPScheme || 'StandardForecastROPScheme.ManualEntryROP' === parentForecastROPScheme;
    },
    (hideAlgoQuantity) => new AlgoQuantityDecorator(hideAlgoQuantity));
