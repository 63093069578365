import { copyLocalStorageParamsToStore } from '../components/common/commonActions';

export const INIT_ACTION = '__INIT_LOCAL_STORAGE__';

/**
 * Redux middleware that monitors changes to certain parts of the store, and synchronizes them with localStorage.
 */

export default function createSyncLocalStorageMiddleware(config) {
    return function(store) {
        let prevStoreParams;
        return (next) => (action) => {
            next(action);
            
            if (action && action.type === INIT_ACTION) {
                // get initial values from localStorage
                const syncParams = [];
                for(let param of config.params) {
                    const value = deserialize(param, config.cache.getItem(param.key));
                    syncParams.push({ key: param.key, value });
                }

                // synchronize localStorage -> store
                store.dispatch(copyLocalStorageParamsToStore(syncParams));

            } else {
                const state = store.getState();
                
                // select the params object from the store
                const storeParams = config.selector(state);

                // return early if no params were actually changed
                if(storeParams === prevStoreParams) return;
                prevStoreParams = storeParams;

                // synchronize store -> localStorage
                for(let param of config.params) {
                    const value = prevStoreParams.get(param.key);
                    if(value !== config.cache.getItem(param.key)) {
                        if(value == null) {
                            // delete from localStorage
                            config.cache.removeItem(param.key);

                        } else {
                            // set in localStorage
                            config.cache.setItem(param.key, serialize(param, value));
                        }
                    }
                }
            }
        }
    }
}

/**
 * @param {ParamSpec} param 
 * @param {any} value 
 */
function serialize(param, value) {
    return param.serialize ? param.serialize(value) : value;
}

/**
 * @param {ParamSpec} param 
 * @param {any} value 
 */
function deserialize(param, value) {
    return param.deserialize ? param.deserialize(value) : value;
}
