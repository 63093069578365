import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { hashHistory, IndexRoute, Route, Router } from 'react-router';
import { Provider } from 'react-redux'

import { setStore } from '@pearlchain/component-lib-common'
import {TokenListener} from '@pearlchain/stackbase-common';
import '@pearlchain/powergrid';

import store from './store/store'
import App from './App'
import component from './components/simulation/simulationTemplate/overview/SimulationTemplateOverview'
import { pages } from './components/page/pages';
import LoginPage from './components/login/LoginPage';
import ForecastAccuracyDetails from './components/forecastAccuracy/forecastAccuracyDetails/ForecastAccuracyDetails';

// Import styles
require('@fortawesome/fontawesome-free/css/all.css');
require('./styles/react-contextmenu.css');

// Load main style last, allow to override 
require('./styles/main.scss');
require('@pearlchain/component-lib-ui/src/styles/_pearlchain-web.scss');

setStore(store);

function createPageRoutes() {
    return pages.map((page, index) => {
        return page.component ?
            <Route
                key={index}
                path={page.path.substring(1)}
                component={page.component}/>
            : undefined;
    })
}
const fallback = <h5>Loading translations...</h5>;
const routes = (
    <Provider store={store}>
        <TokenListener>
            <Suspense fallback={fallback}>
                <Router history={hashHistory}>
                    <Route path="/" component={App}>
                        <IndexRoute component={component}/>
                        <Route path="/forecast-accuracy-details" component={ForecastAccuracyDetails}/>
                        { createPageRoutes() }
                    </Route>
                </Router>
            </Suspense>
        </TokenListener>
    </Provider>);

render(routes, document.getElementById('root'))

