import { FormData, GraphData } from "./types";

enum ActionTypes {
    MOUNT_FORECAST_DETAILS = 'vco.forecast-details.MOUNT_FORECAST_DETAILS',
    UNMOUNT_FORECAST_DETAILS = 'vco.forecast-details.UNMOUNT_FORECAST_DETAILS',

    CHANGE_SPLIT_SIZE = 'vco.forecast-details.CHANGE_SPLIT_PANE_SIZE',
    SET_SELECTED_ITEM = 'vco.forecast-details.SET_SELECTED_ITEM',
    CLEAR_SELECTED_ITEM= 'vco.forecast-details.CLEAR_SELECTED_ITEM',
    SET_DATA= 'vco.forecast-details.SET_DATA',
    CLEAR_DATA= 'vco.forecast-details.CLEAR_DATA',
    SET_QUANTITY= 'vco.forecast-details.SET_QUANTITY',
    SET_REMARK= 'vco.forecast-details.SET_REMARK',
    RESET_CHANGES_SINGLE= 'vco.forecast-details.RESET_CHANGES_SINGLE',
    RESET_ALL_CHANGES= 'vco.forecast-details.RESET_ALL',
    SAVE_ALL_CHANGES= 'vco.forecast-details.SAVE_ALL_CHANGES',
    FETCH_DATA='vco.forecast-details.FETCH_DATA'
}

export function changeSplitSize(size: number) {
    return { type: ActionTypes.CHANGE_SPLIT_SIZE, size };
}

export function setSelectedItem(runIdx: number, pointIdx: number) {
    return { type: ActionTypes.SET_SELECTED_ITEM, runIdx, pointIdx };
}

export function clearSelectedItem() {
    return { type: ActionTypes.CLEAR_SELECTED_ITEM };
}

export function setData(data: GraphData) {
    return { type: ActionTypes.SET_DATA, data };
}

export function clearData() {
    return { type: ActionTypes.CLEAR_DATA };
}

export function setItemQuantity(runIdx: number, pointIdx: number, quantity: number) {
    return { type: ActionTypes.SET_QUANTITY, runIdx, pointIdx, quantity };
}

export function setItemRemark(runIdx: number, pointIdx: number, remark: string) {
    return { type: ActionTypes.SET_REMARK, runIdx, pointIdx, remark };
}

export function resetChangesSingle(runIdx: number, pointIdx: number) {
    return { type: ActionTypes.RESET_CHANGES_SINGLE, runIdx, pointIdx };
}

export function resetAllChanges() {
    return { type: ActionTypes.RESET_ALL_CHANGES };
}

export function saveAllChanges() {
    return { type: ActionTypes.SAVE_ALL_CHANGES };
}

export function mountForecastDetails() {
    return { type: ActionTypes.MOUNT_FORECAST_DETAILS };
}

export function unmountForecastDetails() {
    return { type: ActionTypes.UNMOUNT_FORECAST_DETAILS };
}

export function fetchData(data: FormData, dispatch: any, props: {initialValues: FormData}) {
    const params = Object.keys(data).length > 0 ? data : props.initialValues;
    return { type: ActionTypes.FETCH_DATA, data: params};
}

export default ActionTypes;
