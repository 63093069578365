import React from 'react';
import { withTranslation } from 'react-i18next';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

import { Col, FormGroup, Label } from 'reactstrap';

/**
 * Wraps the binder input field with a FormGroup + label
 */

export interface BinderComponentProps {
    formId: string,
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps
}

interface Props {
    input: WrappedFieldInputProps,
    meta: WrappedFieldMetaProps,
    formId: string,
    label: string,
    inline?: boolean,
    binder: React.ComponentType<BinderComponentProps>,
    binderProps: any,
    t: (key: string) => string,
    formatter: (v: any) => any,
    disabled?: boolean
}

function Binder(props: Props) {
    const meta = props.meta;
    const error = getErrorMessage(meta);
    const validationState = getValidationState(error);

    const componentProps = Object.assign({
        formId: props.formId,
        input: props.input,
        meta: props.meta,
        formatter: props.formatter,
        disabled: props.disabled
    }, props.binderProps);

    const title = props.t(props.label);

    if(props.inline) {
        return (
            <FormGroup validationState={validationState} row>
                <Label className="text-right col-4 mt-1">{ title }</Label>
                <Col xs={8}>
                    { React.createElement(props.binder, componentProps) }
                </Col>
                { error && <div className="form-text text-muted">{ error }</div> }
            </FormGroup>);

    } else {
        return (
            <FormGroup validationState={validationState}>
                <Label>
                    { props.t(props.label) }
                </Label>
                { React.createElement(props.binder, componentProps) }

                { error && <div className="form-text text-muted">{ error }</div> }
            </FormGroup>);
    }
}

function getErrorMessage(meta: WrappedFieldMetaProps) {
    return meta && meta.touched ? meta.error : undefined;
}

function getValidationState(error: string|undefined) {
    return error ? 'error' : null;
}

export default withTranslation(['prl'])(Binder) as any;
