const ActionTypes = {
    QUERY_FORECAST_GROUPS: 'vco.forecasting.QUERY_FORECAST_GROUPS',
    CONFIRM_GENERAL_REMARK: 'vco.forecasting.CONFIRM_GENERAL_REMARK',
    CANCEL_GENERAL_REMARK: 'vco.forecasting.CANCEL_GENERAL_REMARK',
    CALCULATE_PARTIAL: 'vco.forecasting.CALCULATE_PARTIAL',
    DETAILS_SAVE_FORECAST_LINES: 'vco.forecasting.DETAILS_SAVE_FORECAST_LINES',
    DETAILS_GENERATE_FORECAST_LINES: 'vco.forecasting.DETAILS_GENERATE_FORECAST_LINES',
    MANUAL_ENTRY_GENERATE_ROWS: 'vco.forecasting.MANUAL_ENTRY_GENERATE_ROWS',
    MANUAL_ENTRY_SAVE_LINES: 'vco.forecasting.MANUAL_ENTRY_SAVE_LINES',
    SELECT_DESELECT_ALL_FORECAST_GROUPS: 'vco.forecasting.SELECT_DESELECT_ALL_FORECAST_GROUPS'
};

export const RequestId = {
    FORECAST_GROUPS: 'forecast-groups'
};

export const ForecastROPSchemeTypes = {
    NO_FORECAST: 'ForecastROPScheme.Type.NoForecast',
    MANUAL_ENTRY: 'ForecastROPScheme.Type.ManualEntry',
    MANUAL_ENTRY_ROP: 'ForecastROPScheme.Type.ManualEntryROP',
    ALGO_BASED: 'ForecastROPScheme.Type.AlgoBased',
    ALGO_BASED_ROP: 'ForecastROPScheme.Type.AlgoBasedROP',
    CLONE_ROP: 'ForecastROPScheme.Type.CloneROP'
};

export const ForecastToBePublished = {
    ToBePublished: 'true',
    NotToBePublished: 'false'
};

export default ActionTypes;

export function calculatePartial(formModel) {
    return { type: ActionTypes.CALCULATE_PARTIAL, selectorFormId: formModel.formId }
}

/** Details Screen */

export function detailsGenerateForecastLines(formModel) {
    return { type: ActionTypes.DETAILS_GENERATE_FORECAST_LINES, formModel };
}

export function detailsFetchForecastGroupLines(formId, forecastGroupUuid, includeOld) {
    return { type: ActionTypes.DETAILS_FETCH_FORECAST_LINES, formId, forecastGroupUuid, includeOld };
}

export function selectDeselectAllForecastGroups(formId, data, version) {
    return { type: ActionTypes.SELECT_DESELECT_ALL_FORECAST_GROUPS, formId, data, version }
}

export function detailsSaveForecastGroupLines(formModel) {
    return { type: ActionTypes.DETAILS_SAVE_FORECAST_LINES, formModel };
}

/** Manual Entry Screen */

export function manualEntryGenerateRows(formModel) {
    return { type: ActionTypes.MANUAL_ENTRY_GENERATE_ROWS, formModel };
}

export function manualEntrySaveLines(formModel) {
    return { type: ActionTypes.MANUAL_ENTRY_SAVE_LINES, formModel };
}

/** Common */

export function confirmGeneralRemark(generalRemark, formId) {
    return { type: ActionTypes.CONFIRM_GENERAL_REMARK, generalRemark, formId };
}

export function cancelGeneralRemark(formId) {
    return { type: ActionTypes.CANCEL_GENERAL_REMARK, formId };
}

