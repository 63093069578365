import { getSimulationContextUid, getSimulationInstances } from '../../common/commonSelectors';
import { RequestBuilder } from '@pearlchain/component-lib-common';
import { isActiveSimInstance } from '../simulationHelpers';

export function createRequestBuilderFromState(state: any) {
    const simulationUid: string|undefined = getSimulationContextUid(state);
    const reqBuilder = new RequestBuilder();
    // default only OK is accepted, the vco service calls sometimes return 201 (CREATED) though, acceptt his too.
    // maybe this should be added in the default of RequestBuilder in component-lib-common?
    reqBuilder.setExpectedStatus([200, 201]);

    if (simulationUid) {
        const simulations = getSimulationInstances(state);
        const exist = simulations.some(
            (sim: any) => sim.get('uniqueIdentifier') === simulationUid && isActiveSimInstance(sim)
        );
        if (exist) {
            reqBuilder.setHeaderSimulationUid(simulationUid);
        }
    }

    return reqBuilder;
}

