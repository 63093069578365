import { fromJS } from 'immutable';

export const ROW_ID_KEY = 'id';

export const SIMULATION_INSTANCE_OVERVIEW_COLUMNS = fromJS([
    {_key: ROW_ID_KEY, width: 0, idField: true},
    {_key: 'name', width: 130, title: 'common.name' },
    {_key: 'templateName', width: 130, title: 'simulationInstances.master.column.templateName' },
    {_key: 'simulationType', width: 130, title: 'common.Type' },
    {_key: 'tableName', width: 130, title: 'simulationInstances.master.column.tableName' },
    {_key: 'planningState', width: 130, title: 'simulationInstances.master.column.planningState' },
    {_key: 'plants', width: 130, title: 'simulation.grid.plants'},
    {_key: 'creationDate', width: 130, title: 'common.creationDate', formatter: 'date' },
    {_key: 'creationUser', width: 130, title: 'common.creationUser'},
    {_key: 'modificationDate', width: 130, title: 'common.modificationDate', formatter: 'date'},
    {_key: 'modificationUser', width: 130, title: 'common.modificationUser' }
]);

export const COLUMNS_META = new Map([
    ['simulationType', {
        translateValue: true
    }],
    ['planningState', {
        translateValue: true
    }]
]);
