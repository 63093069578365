import { List, Record } from 'immutable';
import { CompanyBinder, TextBinder } from '@pearlchain/component-lib-ui';

import { FormConfig, FormModel } from '../../common/masterform/types/formConfigTypes';
import { ReorderPointOverviewResponse } from '../../utils/remoting/types/reorderPointRequestTypes';

import { apiRequest, apiSaveData, clearApiData } from '../../common/commonActions';
import {refreshForm, setFormValues} from '../../common/masterform/formActions';
import { RequestId } from '../../utils/requests';
import calculateROPsFormConfig from '../calculate/formConfig';
import viewROPSFormConfig from '../details/formConfig';
import auditLogFormConfig from '../../auditlog/formConfig';
import { formatters } from '../../utils/powergrid/formatters';
import { EXTS } from '../../utils/powergrid/powergridHelpers';
import { getApiData, getSimulationContextUid, isRequestFetching } from '../../common/commonSelectors';
import { getDiffs } from '../../utils/helpers';

import ProductBinder from '../../common/binder/product/ProductBinder';
import ProductGroupBinder from '../../common/binder/ProductGroupBinder';
import PlantBinder from '../../common/binder/PlantBinder';
import ForecastROPSchemeBinder from '../../common/binder/ForecastROPSchemeBinder';
import InventoryPlannerBinder from '../../common/binder/InventoryPlannerBinder';
import CheckboxBinder from '../../common/binder/CheckboxBinder';

import productLookupFormConfig from '../../common/binder/product/productLookupFormConfig';
import { ReorderPointDataModel } from './dataModel';
import { createTranslateDecorator } from '../../utils/datamodel/TranslateGridDataDecorator';
import { COLUMNS_META } from './columns';
import { createReadonlyDataDecorator } from '../../utils/datamodel/ReadonlyInLiveDataDecorator';
import { isLive } from '../../utils/simulationHelpers';
import { AUDIT_LOG_FORM_ID, auditlogCommand, CALCULATE_ROPS_FORM_ID, recalculateCommand, selectAllCommand, VIEW_ROPS_FORM_ID, viewdetailsCommand } from './commands';
import {StoreState} from "../../../types/storeTypes";
import moment from "moment";

export const icon = 'thermometer-three-quarters';
export const FormId = 'rop-overview';

const PRODUCT_BINDER_FORM_ID = 'rop-product-binder';

type ReorderPointOverviewRespRecord = Record<ReorderPointOverviewResponse>;

export type FM = FormModel<List<ReorderPointOverviewRespRecord>, Values, {}>;

interface Values {
    companyCodes: string[],
    plants:  string[],
    productNo: string,
    productDescription: string,
    productGroup: string,
    forecastRopScheme: string,
    inventoryPlanner: string,
    toBePublished: boolean,
    notToBePublished: boolean,
    processed: boolean,
    notProcessed: boolean
}

function isSaveDisabled(formModel: FM) {
    return isLive(formModel) || (formModel.bufferedData === formModel.data);
}

function getId(dataItem: ReorderPointOverviewRespRecord): number|string {
    return dataItem.get('id');
}

function buildUpdateRequest(formModel: FM) {
    const { data, bufferedData } = formModel;

    const updated = getDiffs(data!, bufferedData!, getId, (a: ReorderPointOverviewRespRecord, b: ReorderPointOverviewRespRecord) => a.equals(b)).updated;
    return updated.map((upd: ReorderPointOverviewRespRecord[]) => upd[1].toJS());
}

const formConfig: FormConfig<FM> = {
    form: {
        model: {
            data: (state: StoreState) => {
                const response = getApiData(state, RequestId.FIND_REORDER_POINTS);
                if (response) {
                    return response.get('reorderPoints');
                }
            }
        },
        busy: (state) => isRequestFetching(state, RequestId.FIND_REORDER_POINTS),
        reloadWhenChanged: (state) => getSimulationContextUid(state),
        eventHandlers: {
            ['action-show-forecast-details']: (event: string, formModel: FM) => {
                return setFormValues('forecast-details',{
                    fetchOnMount: true,
                    initialValues: {
                        productNo: formModel.selection.get('productNo'),
                        plant: [formModel.selection.get('plant')],
                        startDate: moment().subtract(2, 'years'),
                        endDate: moment().add(1, 'years'),
                        pastPeriods: true,
                        historicalForecasts: false,
                        activeForecast: true
                    }
                })
            }
        },
        clearData: () => clearApiData(RequestId.FIND_REORDER_POINTS),
        fetchData: (formModel) => apiRequest(RequestId.FIND_REORDER_POINTS, formModel.bufferedValues, formModel.sortColumns)
    },
    selector: {
        icon: { name: icon },
        layout: {
            component: 'grid',
            numCols: { xs: 1, sm: 2, md: 3, lg: 4 },
        },
        fields: {
            companyCodes: { component: CompanyBinder, label: 'common.selector.companies', multi: true },
            plants:  { component: PlantBinder, label: 'common.selector.plant' },
            productNo: { component: ProductBinder, binderFormId: PRODUCT_BINDER_FORM_ID, label: 'common.selector.productNo' },
            productDescription: { component: TextBinder, label: 'common.selector.productDesc' },
            productGroup: { component: ProductGroupBinder, label: 'common.selector.productGroup' },
            forecastRopScheme: { component: ForecastROPSchemeBinder, label: 'common.selector.forecastropscheme' },
            inventoryPlanner: { component: InventoryPlannerBinder, label: 'common.selector.inventoryPlanner' },
            toBePublished: {component: CheckboxBinder, colDim: 0.5, label: 'common.selector.tobepublished' },
            notToBePublished: {component: CheckboxBinder, colDim: 0.5, label: 'common.selector.nottobepublished' },
            processed: {component: CheckboxBinder, colDim: 0.5, label: 'common.selector.processed' },
            notProcessed: {component: CheckboxBinder, colDim: 0.5, label: 'common.selector.notprocessed' }
        },
        commands: [
            {
                label: 'common.selector.actions.search',
                type: 'primary',
                group: -1,
                action: (formModel) => refreshForm(formModel.formId),
                datacy: 'search-reorderpoint-btn'
            },
            {
                label: 'common.selector.actions.save',
                type: 'success',
                group: 1,
                disabled: isSaveDisabled,
                action: (formModel) => apiSaveData(RequestId.UPDATE_REORDER_POINTS, formModel.formId, 'Reorderpoints Saved', buildUpdateRequest(formModel))
            },
            viewdetailsCommand,
            recalculateCommand,
            auditlogCommand,
            selectAllCommand
        ]
    },
    grid: {
        dataModel: new ReorderPointDataModel(),
        readOnly: isLive,
        decorators: [
            createTranslateDecorator({ allHeaders: true, columns: COLUMNS_META }),
            createReadonlyDataDecorator()
        ],
        extensions: {
            [EXTS.SELECTION]: true,
            [EXTS.COLUMN_SIZING]: true,
            [EXTS.EDITING]: true,
            [EXTS.STYLING]: true,
            [EXTS.DIRECT_INPUT]: true,
            [EXTS.FORMATTING]: {
                formatters
            },
            [EXTS.SORTING]: {
                async: true
            },
            [EXTS.CONTEXT_MENU]: {
                formId: FormId,
                commands: [
                    viewdetailsCommand,
                    auditlogCommand
                ]
            },
            [EXTS.ACTION_BUTTONS]: true
        },
        events: ['action-show-forecast-details']
    },
    children: {
        [PRODUCT_BINDER_FORM_ID]: {
            component: 'modal',
            title: 'common.binder.product.title',
            form: {
                config: productLookupFormConfig
            }
        },
        [CALCULATE_ROPS_FORM_ID]: {
            component: 'modal',
            title: 'simulation.recalculation.title',
            form: {
                config: calculateROPsFormConfig
            }
        },
        [VIEW_ROPS_FORM_ID]: {
            component: 'modal',
            title: 'reorderpoint.details.title',
            form: {
                config: viewROPSFormConfig
            }
        },
        [AUDIT_LOG_FORM_ID]: {
            component: 'modal',
            title: 'Audit Log',
            form: {
                config: auditLogFormConfig
            }
        }
    }
};

export default formConfig;
