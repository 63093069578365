import React from 'react';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { TextBinder } from '@pearlchain/component-lib-ui';
import { CompanyBinder } from '@pearlchain/stackbase-common';

import Binder from '../../../common/binder/Binder';
import GridLayout from '../../../common/layout/GridLayout';
import SimTypeBinder from '../../../common/binder/SimTypeBinder';
import { requiredValidator, textValidator } from '../../../utils/validators/validators';
import { clearFormFields, clearInitialValues, fetchFormFields, findSimulationTemplate } from '../simulationTemplateActions';
import SimulationParameters from '../../SimulationParameters';
import PlantBinder from '../../../common/binder/PlantBinder';

const nameValidator = textValidator({ minLength: 1 });

export class SimulationTemplateFormSelector extends React.Component {

    render() {
        return (
            <div>
                <GridLayout numCols={1}>
                    <Field name="name" disabled={this.props.readOnly} validate={nameValidator} component={Binder} binder={TextBinder} label="common.selector.name"/>
                    <Field name="companyCodes" disabled={this.props.readOnly} validate={requiredValidator} component={Binder} binder={CompanyBinder} label="common.selector.companies" multi/>
                    <Field name="plants" disabled={this.props.readOnly} validate={requiredValidator} component={Binder} binder={PlantBinder} label="common.selector.plant"/>
                    <Field name="externalReference" disabled={this.props.readOnly} validate={requiredValidator} component={Binder} binder={SimTypeBinder} onChange={this.handleTypeChange.bind(this)} label="common.selector.type"/>
                </GridLayout>

                <SimulationParameters
                    formFields={this.props.formFields}
                    {...this.props}/>
            </div>
        );
    }

    handleTypeChange(event, value) {
        if (value) {
            this.props.fetchFormFields(value);
        }
    }

    componentWillUnmount() {
        this.props.clearFormFields();
        this.props.clearInitialValues();
    }

    componentDidMount() {
        if (this.props.simulationTemplateUid) {
            this.props.findSimulationTemplate(this.props.simulationTemplateUid);
        }
    }
}

SimulationTemplateFormSelector.propTypes = {
    formId: PropTypes.string.isRequired,
    fetchFormFields: PropTypes.func.isRequired,
    clearFormFields: PropTypes.func.isRequired,
    clearInitialValues: PropTypes.func.isRequired,
    findSimulationTemplate: PropTypes.func,
    simulationTemplateUid: PropTypes.string,
    formFields: PropTypes.object,
    readOnly: PropTypes.bool
}

export default connect(
    (state, { formId }) => {
        const selector = formValueSelector(formId);
        return {
            formFields: state.simulationTemplate && state.simulationTemplate.get('formFields'),
            simulationTemplateUid: selector(state, 'uniqueIdentifier')
        };
    },
    { fetchFormFields, clearFormFields, clearInitialValues, findSimulationTemplate }
)(SimulationTemplateFormSelector);

