import { Map, Record } from 'immutable';
import {
    COLUMN_DEVIATION,
    COLUMN_DEVIATION_KEY,
    COLUMN_QUANTITY,
    COLUMN_QUANTITY_KEY,
    COLUMN_REMARK,
    COLUMN_SAFETY_STOCK_QUANTITY,
    COLUMN_SAFETY_STOCK_QUANTITY_KEY,
    COLUMN_TO_BE_PUBLISHED,
    COLUMN_PROCESSED,
    reorderPointOverviewColumns as columns
} from './columns';
import { copyColumnKeys } from '../../utils/powergrid/columnHelpers';
import { calculateDeviation, getPosNeg } from '../reorderPointHelpers';
import { DataModelParams, Entry, EntryWithoutRow } from "../../common/masterform/types/gridDataModelTypes";
import { ReorderPointOverviewResponse } from '../../utils/remoting/types/reorderPointRequestTypes';

type DataItem = Record<ReorderPointOverviewResponse>;
export class ReorderPointDataModel implements DataModelParams<DataItem> {

    columns = columns;

    getId(item: any, index: number): string {
        return item.get('id');
    }

    shouldApplyClasses(column: { key: number }, item: DataItem, entry: Entry<any>): boolean {
        return column.key === COLUMN_DEVIATION
            || column.key === COLUMN_QUANTITY
            || column.key === COLUMN_SAFETY_STOCK_QUANTITY;
    }

    getRow(item: any, entry: EntryWithoutRow<any>): Map<any, any> {
        const deviation = calculateDeviation(item.get('liveQuantity'), item.get('quantity'));
        const absDeviation = deviation ? Math.abs(deviation).toFixed(2) : 0;
        const posNeg = getPosNeg(deviation ? deviation : 0);
        // const uuid = dataItem.get('uniqueIdentifier');
        const toBePublished = item.get('toBePublished');
        const processed = item.get('processed');

        let columnMap = Map()
        // .set('id', uuid)
            .set('deviation', absDeviation)
            .set('posNeg', posNeg)
            .set('toBePublished', toBePublished)
            .set('processed', processed);

        return copyColumnKeys(columnMap, item, columns);
    }


    modifyDataItem(columnIdx: number, value: any, dataItem: DataItem, entry: Entry<any>): any|undefined {
        if (columnIdx == COLUMN_QUANTITY) {
            dataItem = dataItem.set('quantity', value);
            dataItem = dataItem.set('toBePublished', true);
            return dataItem;
        }

        if (columnIdx == COLUMN_SAFETY_STOCK_QUANTITY) {
            return dataItem.set('safetyStockQuantity', value);
        }

        if (columnIdx == COLUMN_REMARK) {
            return dataItem.set('remark', value);
        }

        if (columnIdx == COLUMN_TO_BE_PUBLISHED) {
            return dataItem.set('toBePublished', value);
        }

        if (columnIdx == COLUMN_PROCESSED) {
            return dataItem.set('processed', value);
        }
    }

    getClass(column: { _key: string }, item: DataItem, entry: Entry<any>): string|undefined {
        if (column._key == COLUMN_DEVIATION_KEY) {
            const deviation = entry.row.get('deviation');
            if (deviation == null || deviation === 0) {
                return undefined;
            }
            if (deviation > 5) {
                return 'pg-cell-deviation-toohigh';
            } else {
                return 'pg-cell-deviation-applicable';
            }

        } else if (column._key == COLUMN_QUANTITY_KEY) {
            const quantity = parseFloat(''+item.get('quantity'));
            const oldQuantity = parseFloat(entry.original.get('quantity'));
            if (oldQuantity !== quantity) {
                return 'pg-cell-modified';
            }
        } else if (column._key == COLUMN_SAFETY_STOCK_QUANTITY_KEY) {
            const stock = parseFloat(''+item.get('safetyStockQuantity'));
            const oldStock = parseFloat(entry.original.get('safetyStockQuantity'));
            if (oldStock !== stock) {
                return 'pg-cell-modified';
            }
        }
    }
}

