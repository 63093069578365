import * as React from 'react';

import { FormConfig, FormModel, ExtractParams } from './types/formConfigTypes';
import FormModelComponent from './FormModel';
import FormInner from './FormInner';

type PropTypes<FM extends FormModel> = {
    formId: string,
    config: FormConfig<FM>,
    params?: ExtractParams<FM>,
    parent?: any,
}

function Form<FM extends FormModel>(props: PropTypes<FM>) {
    return <FormModelComponent
        formId={props.formId}
        config={props.config as FormConfig<any>}
        params={props.params}
        parent={props.parent}
        component={FormInner}/>
}

export default Form;
