import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Button, Col, Form, Row } from 'reactstrap';

import { FormData } from './types';

import DateBinder from '../common/binder/DateBinder';
import CheckboxBinder from '../common/binder/CheckboxBinder';
import ProductBinder from '../common/binder/product/ProductBinder';
import PlantBinder from '../common/binder/PlantBinder';
import Binder from '../common/binder/Binder';
import { requiredValidator } from '../utils/validators/validators';
import { PRODUCT_BINDER_FORM_ID } from '../common/binder/product/ProductLookupModal';
import { FORM_ID } from './helpers';

interface Props {
    fetchOnMount?: boolean
}

class ForecastDetailsForm extends React.Component<Props & InjectedFormProps<FormData, Props>> {
    componentDidMount() {
        if(this.props.fetchOnMount) {
            (this.props as any).submit();
        }
    }

    render() {
        return (
            <Form className="forecast-details-info" onSubmit={this.props.handleSubmit}>
                <Field name="productNo" validate={requiredValidator} component={Binder} label="common.selector.productNo"
                    formId={FORM_ID}
                    binder={ProductBinder}
                    binderProps={{
                        binderFormId: PRODUCT_BINDER_FORM_ID
                    }}/>
                    
                <Field name="plant" validate={requiredValidator} component={Binder} binder={PlantBinder} label="common.selector.plant"/>
                <Row>
                    <Col md={6}>
                        <Field name="startDate" component={Binder} binder={DateBinder} label="common.selector.periodFrom"/>
                    </Col>
                    <Col md={6}>
                        <Field name="endDate" component={Binder} binder={DateBinder} label="common.selector.periodTo"/>
                    </Col>
                </Row>
                <Field name="pastPeriods" component={Binder} binder={CheckboxBinder} label="forecast-details.inspector.pastPeriods"/>
                <Field name="historicalForecasts" component={Binder} binder={CheckboxBinder} label="forecast-details.inspector.historicalForecasts"/>
                <Field name="activeForecast" component={Binder} binder={CheckboxBinder} label="forecast-details.inspector.activeForecast"/>
                <Button type="submit" disabled={!!this.props.invalid}>Search</Button>
            </Form>);
    }

}

export default reduxForm<FormData, Props>({
    form: FORM_ID,
    enableReinitialize: true,
    destroyOnUnmount: false
})(ForecastDetailsForm);
