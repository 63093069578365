import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestId } from '../../utils/requests';
import { dataToOptionsByKey } from '../../utils/binder/binderHelpers';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

export default function SimTypeBinder(props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_SIMULATION_TYPES}
        render={(opts) => <SelectBinder options={dataToOptionsByKey(opts, 'externalReference')} { ...props }/>}
        />;
}
