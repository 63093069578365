import { FormModel } from '../common/masterform/types/formConfigTypes';
import { getCurrentSimulationInstance } from '../common/commonSelectors';
import { StoreState } from '../../types/storeTypes';
import { SimulationInstanceRecord } from './remoting/types/simulationInstanceRequestTypes';

const ACTIVE_PLANNING_STATES = new Set(['CREATION', 'NEW', 'RELEASE_READY']);

export function isActiveSimInstance(simInstance: any) {
    const planningState = simInstance.get('planningState');
    return ACTIVE_PLANNING_STATES.has(planningState);
}

export function findSimulationById(simulations: any, simId: any) {
    return simulations && simulations.find((sim: any) => sim.get('id') === simId);
}

export function isLive(formModel: FormModel) {
    return !formModel.simulationUid;
}

export function isNothingSelected(formModel: FormModel) {
    return !formModel.selection;
}

export function isCurrentSimulationSuitable(state: StoreState, checkFn: (simulationInstance: any) => boolean) {
    const currentSimulationInstance = SimulationInstanceRecord(getCurrentSimulationInstance(state));
    return currentSimulationInstance && checkFn(currentSimulationInstance);
}

