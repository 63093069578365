import { Action } from "redux";
import { Map } from "immutable";
import { PropertiesState, createPropertiesState } from "./propertyTypes";
import { ActionType, ReceivePropertiesAction } from "./propertyActions";

export default function reducers(state: PropertiesState = createPropertiesState(), action: Action): PropertiesState {
    switch (action.type) {
        case ActionType.RECEIVE_PROPERTIES:
            return handleReceiveProperties(state, action as ReceivePropertiesAction);
        default:
            return state;
    }
}

function handleReceiveProperties(state: PropertiesState, action: ReceivePropertiesAction): PropertiesState {
    return state.set('properties', Map(action.properties));
}
