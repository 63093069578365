import { List, Record } from 'immutable';
import { Moment } from 'moment';

export enum GraphRunType {
    ForecastActive = 'FORECAST_ACTIVE',
    Forecast = 'FORECAST',
    SalesOrders = 'SALES_ORDERS'
}

export type GraphDataPoint = Record<{
    uuid: string,
    version: number,
    quantity: number,
    startDate: string,
    endDate: string,
    remark: string|null,
    originalQuantity?: number,
    originalRemark?: string,
    forecastGroupUniqueIdentifier?: string
    historicalElementPeriod: number,
    amountOfHistoricalPeriods: number,
    plannedElementPeriod: number,
    amountOfPlannedPeriods: number
}>;

export type GraphRunData = Record<{
    type: GraphRunType
    runId: string|null,
    runDate: string,
    plant: string,
    productId: number,
    data: List<GraphDataPoint>
}>;

export type GraphData = Record<{
    forecastDate: string,
    series: List<GraphRunData>
}>;

export interface FormData {
    productId: string,
    plant: string[],
    startDate: Moment,
    endDate: Moment,
    includePast: boolean,
    activeForecast: boolean
}

export interface InfoFormData {
    quantity: string,
    remark: string
}
