import React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '../../../types/storeTypes';

import { setFormSyncKey } from './formActions';
import { getFormSyncKey, getFormRefreshCount } from './formSelectors';
import { ReloadWhenChangedCallback } from './types/formConfigTypes';

interface OwnProps {
    formId: string,
    fetchOnMount: boolean,
    reloadWhenChanged?: ReloadWhenChangedCallback,
    reload: () => void
}

interface Props extends OwnProps {
    syncKey: string|undefined,
    formSyncKey?: string,
    formRefreshCount: number,
    setFormSyncKey: typeof setFormSyncKey
}

class FormModelSync extends React.Component<Props> {

    componentDidMount() {
        if(this.props.syncKey !== this.props.formSyncKey) {
            if(this.props.fetchOnMount || this.props.formSyncKey !== undefined) {
                this.props.setFormSyncKey(this.props.formId, this.props.syncKey);
            }

        } else if(this.props.fetchOnMount) {
            this.props.reload();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if(this.props.syncKey !== this.props.formSyncKey) {
            this.props.setFormSyncKey(this.props.formId, this.props.syncKey);

        } else if(this.props.formRefreshCount > prevProps.formRefreshCount) {
            this.props.reload();
        }
    }

    render() {
        return null;
    }   
}

export default connect(
    (state: StoreState, { formId, reloadWhenChanged }: OwnProps) => ({
        formSyncKey: getFormSyncKey(state, formId),
        formRefreshCount: getFormRefreshCount(state, formId),
        syncKey: reloadWhenChanged ? reloadWhenChanged(state) : undefined
    }),
    { setFormSyncKey }
)(FormModelSync);
