import { Map, Record } from 'immutable';
import ActionTypes, { setSelectedItem, setData, setItemQuantity, resetChangesSingle, setItemRemark } from './actions';

import {
    KEY_DATA_ITEMS,
    getKeyOriginal, getKey
} from './selectors';
import { ForecastDetailsState, SelectionState } from '../../types/storeTypes';
import { GraphData, GraphDataPoint } from './types';

const initialState = Record<ForecastDetailsState>({
    data: undefined,
    selection: undefined,
    "split-size": undefined
});

const selection = Record<SelectionState>({
    pointIdx: 0,
    runIdx: 0
});

export default function(state = initialState(), action: any) {
    switch(action.type) {
        case ActionTypes.SET_DATA:
            return handleSetData(state, action);
        case ActionTypes.CLEAR_DATA:
            return handleClearData(state);
        case ActionTypes.SET_SELECTED_ITEM:
            return handleSetSelectedItem(state, action);
        case ActionTypes.CLEAR_SELECTED_ITEM:
            return handleClearSelectedItem(state);
        case ActionTypes.SET_QUANTITY:
            return handleSetQuantity(state, action);
        case ActionTypes.SET_REMARK:
            return handleSetRemark(state, action);
        case ActionTypes.RESET_CHANGES_SINGLE:
            return handleResetChangesSingle(state, action);
        case ActionTypes.RESET_ALL_CHANGES:
            return handleResetAllChanges(state);
        default:
            return state;
    }
}

function handleSetData(state: Record<ForecastDetailsState>, action: ReturnType<typeof setData>) {
    return state.set('data', action.data);
}

function handleClearData(state: Record<ForecastDetailsState>) {
    return state.delete('data');
}

function handleSetSelectedItem(state: Record<ForecastDetailsState>, action: ReturnType<typeof setSelectedItem>) {
    return state.set('selection', selection({
        pointIdx: action.pointIdx,
        runIdx: action.runIdx
    }));
}

function handleClearSelectedItem(state: Record<ForecastDetailsState>) {
    return state.delete('selection');
}

function handleSetQuantity(state: Record<ForecastDetailsState>, action: ReturnType<typeof setItemQuantity>) {
    const data = state.get('data');
    return state.set('data', data && updateDataPoint(data, action.runIdx, action.pointIdx, 
        (pt) => setValue(pt, 'quantity', 'originalQuantity', action.quantity)));
}

function handleSetRemark(state: Record<ForecastDetailsState>, action: ReturnType<typeof setItemRemark>) {
    const data = state.get('data');
    return state.set('data', data && updateDataPoint(data, action.runIdx, action.pointIdx, 
        (pt) => setValue(pt, 'remark', 'originalRemark', action.remark)));
}

function handleResetChangesSingle(state: Record<ForecastDetailsState>, action: ReturnType<typeof resetChangesSingle>) {
    const data = state.get('data');
    return state.set('data', data && updateDataPoint(data, action.runIdx, action.pointIdx, 
        (pt) => resetValue(pt, 'quantity', 'originalQuantity')));
}

function handleResetAllChanges(state: Record<ForecastDetailsState>) {
    const data = state.get('data');
    return state.set('data', data && data.update('series', (series) => series.map((run) =>
        run.update('data', (data) => data.map((datum) => {
            datum = resetValue(datum, 'quantity', 'originalQuantity');
            datum = resetValue(datum, 'remark', 'originalRemark');
            return datum;
        }))
    )));
}

function resetValue<T>(x: Record<T>, key: keyof T, originalKey: keyof T): Record<T> {
    const originalValue = x.get(originalKey);
    if(originalValue == null) return x;

    return x.delete(originalKey)
        .set(key, originalValue as any);
}

function setValue<T, S>(x: Record<T>, key: keyof T, originalKey: keyof T, value: S): Record<T> {
    const original = x.get(originalKey);
    if(original == null) {
        x = x.set(originalKey, x.get(key) as any);
    } 
    return x.set(key, value as any);
}

function updateDataPoint(data: GraphData, runIdx: number, pointIdx: number, fn: (point: GraphDataPoint) => GraphDataPoint) {
    return data.updateIn(['series', runIdx, 'data', pointIdx], (point) => point && fn(point));
}
