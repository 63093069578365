import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import GridFieldsLayout from './GridFieldsLayout';
import GeneralRemarkBinder from '../binder/GeneralRemarkBinder';

export default function FieldsLayoutWithGeneralRemark(props) {
    return (
        <React.Fragment>
            <Field name={props.generalRemarkFieldName} formId={props.formId} component={GeneralRemarkBinder}/>
            <GridFieldsLayout {...props}/>
        </React.Fragment>);
}

FieldsLayoutWithGeneralRemark.propTypes = {
    formId: PropTypes.string.isRequired,
    generalRemarkFieldName: PropTypes.string.isRequired
}
