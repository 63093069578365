import React from 'react';
import { Record } from 'immutable';
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { TextBinder } from '@pearlchain/component-lib-ui';

import { InfoFormData, GraphDataPoint, GraphRunData } from './types';

import { SelectionState, StoreState } from '../../types/storeTypes';
import { resetChangesSingle, setItemQuantity, setItemRemark } from './actions';
import Binder from '../common/binder/Binder';
import { stringToDateString } from '../utils/helpers';
import { getLineColor } from './graph/graphHelpers';

interface OwnProps {
    selection: Record<SelectionState>,
    selectedItem: GraphDataPoint,
    selectedRun: GraphRunData
}

interface Props extends OwnProps {
    quantity: string,
    remark: string,
    resetChangesSingle: typeof resetChangesSingle,
    setItemQuantity: typeof setItemQuantity,
    setItemRemark: typeof setItemRemark
}

const FORM_ID = 'forecast-details-info';

class ForecastDetailsInfo extends React.Component<Props & InjectedFormProps<InfoFormData, Props>> {

    componentDidUpdate(prevProps: Props) {
        const runIdx = this.props.selection.get('runIdx');
        const pointIdx = this.props.selection.get('pointIdx');

        const quantity = this.props.quantity;
        const remark = this.props.remark;

        if(quantity !== prevProps.quantity){
            const q = parseFloat(quantity);

            if(q != this.props.selectedItem.get('quantity')) {
                this.props.setItemQuantity(runIdx, pointIdx, q);
            }
        }

        if(remark !== prevProps.remark && remark !== this.props.selectedItem.get('remark')) {
            this.props.setItemRemark(runIdx, pointIdx, this.props.remark);
        }
    }

    render() {
        const runIdx = this.props.selection.get('runIdx');
        const pointIdx = this.props.selection.get('pointIdx');
    
        const isResetDisabled = this.isResetDisabled();
        const accentColor = getLineColor(runIdx);
    
        return <Form className="forecast-details-info" style={{ borderTop: '3px solid #' + accentColor }}>
            <Field name="plant" component={Binder} binder={TextBinder} binderProps={{ disabled: true }} label="forecast-details.inspector.plant"/>
            <Field name="startDate" component={Binder} binder={TextBinder} binderProps={{ disabled: true }} label="forecast-details.inspector.startDate"/>
            <Field name="quantity" component={Binder} binder={TextBinder} label="forecast-details.inspector.quantity"/>
            <Field name="remark" component={Binder} binder={TextBinder} label="forecast-details.inspector.remark"/>
            <Button disabled={isResetDisabled} onClick={() => this.props.resetChangesSingle(runIdx, pointIdx)}>Reset</Button>
        </Form>
    }

    isResetDisabled() {
        return this.props.selectedItem.get('originalQuantity') == null
            && this.props.selectedItem.get('originalRemark') == null;
    }
}

const form = reduxForm<InfoFormData, Props>({
    form: FORM_ID,
    enableReinitialize: true
})(ForecastDetailsInfo);

export default connect(
    (state: StoreState, ownProps: OwnProps) => {
        const valueSelector = formValueSelector(FORM_ID);
        return {
            quantity: valueSelector(state, 'quantity'),
            remark: valueSelector(state, 'remark'),
            initialValues: {
                plant: ownProps.selectedRun.get('plant'),
                startDate: stringToDateString(ownProps.selectedItem.get('startDate')),
                quantity: '' + ownProps.selectedItem.get('quantity'),
                remark: ownProps.selectedItem.get('remark') || ''
            }
        };
    },
    { resetChangesSingle, setItemQuantity, setItemRemark }
)(form);
