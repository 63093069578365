import React from 'react';
import { SelectBinder } from '@pearlchain/component-lib-ui';

import { RequestId } from '../../utils/requests';
import { dataToOptions } from '../../utils/binder/binderHelpers';
import { RequestIdFetcher } from '@pearlchain/component-lib-common';

export default function ProductStatusBinder(props) {
    return <RequestIdFetcher
        requestId={RequestId.LIST_PRODUCT_STATUSES}
        render={(opts) => <SelectBinder multi options={dataToOptions(opts)} { ...props }/>}
        />;
}
