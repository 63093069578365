import React from 'react';
import { connect } from 'react-redux';
import * as Imm from 'immutable';

import { FormConfig, ModelConfig, FormModel, EventHandler, BoundActionCallback } from './types/formConfigTypes';
import { StoreState } from '../../../types/storeTypes';
import { bindActionCreators, Dispatch } from 'redux';
import FormEventObserver from './FormEventObserver';
import FormPagesRoot from './FormPagesRoot';
import FormModelSync from './FormModelSync';
import { unmountForm } from './formActions';
import { createFormModel as createFormModelSelector, createProvidersSelector } from './formSelectors';
/**
 * Properties passed into the wrapped component
 */
export interface InnerComponentProps {
    formId: string,
    formModel: FormModel,
    config: FormConfig
}

interface OwnProps {
    formId: string,
    component: React.ComponentType<InnerComponentProps>,
    parent?: FormModel,
    config: FormConfig,
    params: any
}

interface Props extends OwnProps {
    unmountForm: typeof unmountForm,
    clearData?: BoundActionCallback,
    fetchData?: BoundActionCallback,
    formModel: FormModel,
    eventHandlers?: { [key: string]: EventHandler }|undefined
}

class FormModelComponent extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handleEvent = this.handleEvent.bind(this);
        this.handleReload = this.handleReload.bind(this);
    }

    componentWillUnmount() {
        if(!this.formConfig.keepInStore)
            this.props.unmountForm(this.props.formId);

        if(this.props.clearData)
            this.props.clearData(this.props.formModel);
    }

    render() {
        const passThruProps = this.getComponentProps();
        return <React.Fragment>
            <FormPagesRoot {...passThruProps}/>

            <FormModelSync
                formId={this.props.formId}
                reloadWhenChanged={this.formConfig.reloadWhenChanged}
                fetchOnMount={!!this.formConfig.fetchOnMount}
                reload={this.handleReload}/>

            { this.props.eventHandlers && <FormEventObserver
                formId={this.props.formId}
                eventKeys={this.getEventKeys(this.props.eventHandlers)}
                onEvent={this.handleEvent}/>
            }

        </React.Fragment>
    }

    handleReload() {
        if(this.props.fetchData)
            this.props.fetchData(this.props.formModel);
    }

    handleEvent(event: any) {
        const type = event.get('eventType');
        const eventHandlers = this.props.eventHandlers;
        const handler = eventHandlers && eventHandlers[type];
        if(handler) {
            handler(event, this.props.formModel)
        }
    }

    getComponentProps(): InnerComponentProps {
        return {
            formId: this.props.formId,
            config: this.props.config,
            formModel: this.props.formModel
        };
    }

    getEventKeys(eventHandlers: { [key: string]: EventHandler }): Imm.Set<string> {
        return Imm.Set(Object.keys(eventHandlers));
    }

    get formConfig() {
        return this.props.config.form;
    }
}

function mapStateToProps(state: StoreState, { config, formId }: OwnProps) {
    const form = config.form;
    const getFormModel = createFormModelSelector(formId, form);

    return (state: StoreState, ownProps: OwnProps) => {
        const parent = ownProps.parent;
        const params = { ...ownProps.params, parent };
        const formModel = getFormModel(state, params);
        return { formModel };
    };
}

function mapDispatchToProps(dispatch: Dispatch<StoreState>, { config }: OwnProps) {
    const form = config.form;

    const boundActions = bindActionCreators({
        unmountForm,
        clearData: form.clearData!,
        fetchData: form.fetchData!
    }, dispatch);

    const eventHandlers = form.eventHandlers
        ? bindActionCreators(form.eventHandlers, dispatch) : undefined;

    return {
        eventHandlers,
        ...boundActions
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormModelComponent);
