import { apiRequest, clearApiData } from '../common/commonActions';
import { RequestId } from '../utils/requests';
import { getApiData, isWorkingInLive } from '../common/commonSelectors';
import { EXTS } from '../utils/powergrid/powergridHelpers';
import { formatters } from '../utils/powergrid/formatters';
import { columns } from './columns';
import { FormConfig, FormModel } from '../common/masterform/types/formConfigTypes';

type FM = FormModel<any, {}, {
    type: string,
    parentId: string,
    fields?: string[],
    snaType?: string,
    plant?: string,
    auditLogType: string
}>;

const formConfig: FormConfig<FM> = {
    form: {
        fetchOnMount: true,
        model: {
            data: (state) => getApiData(state, RequestId.FETCH_AUDIT_LOGS),
            live: (state) => isWorkingInLive(state)
        },
        clearData: () => clearApiData(RequestId.FETCH_AUDIT_LOGS),
        fetchData: (formModel) => {
            const params = formModel.params!;
            const auditLogType = formModel.live ? params.type : params.snaType!;
            const fields = params.fields;
            const plant = params.plant;
            const parentId = params.parentId;

            return apiRequest(RequestId.FETCH_AUDIT_LOGS, {
                auditLogType,
                fields,
                plant,
                parentId
            });
        }
    },
    grid: {
        dataModel: {
            columns,
            getId: (dataItem) => dataItem.get('uniqueIdentifier'),
            rowIdField: 'id'
        },
        extensions: {
            [EXTS.RESIZING]: true,
            [EXTS.FORMATTING]: {
                formatters
            }
        }
    }
}

export default formConfig;
